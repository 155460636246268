const caskets =
[
	{ name: 'Pine Casket', value: 3461 },
	{ name: 'Chestnut Casket', value: 4323 },
	{ name: 'Oak Casket', value: 4919 },
	{ name: 'Stainless Steel Casket', value: 5803 },
	{ name: 'Maple Casket', value: 5935 },
	{ name: 'Cherry Casket', value: 6074 },
	{ name: 'Walnut Casket', value: 6431 },
	{ name: 'Copper Casket', value: 6624 },
	{ name: 'Bronze Casket', value: 8249 },
	{ name: 'Mahogany Casket', value: 8741 },
]

const urns =
[
	{ name: 'Basic Container', note: '(Note: material is usually cardboard)', type: 'scattering', value: 50 },
	{ name: 'Wood Scattering Urn', type: 'scattering', value: 165 },
	{ name: 'Marble Urn', type: 'display', value: 359 },
	{ name: 'Wooden Urn', type: 'display', value: 395 },
	{ name: 'Brass Urn', type: 'display', value: 440 },
	{ name: 'Bronze Urn', type: 'display', value: 853 },
]

export {
	caskets as Caskets,
	urns as Urns,
}
