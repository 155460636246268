import { defineStore } from 'pinia'

export const useFhStore = defineStore('funeralHome', {
	state: () => ({
		id: null,
		isFinalExpense: null,
		name: null,
		addressLine1: null,
		addressLine2: null,
		city: null,
		state: null,
		zip: null,
		phone: null,
		logoUrl: null,
		websiteUrl: null,
	}),

	actions: {
		applyApiPayload(payload) {
			this.$reset()

			if (payload === null) {
				return
			}

			this.$patch({
				id: payload.id,
				isFinalExpense: payload.isFinalExpense,
				name: payload.name,
				addressLine1: payload.addressLine1,
				addressLine2: payload.addressLine2,
				city: payload.city,
				state: payload.state,
				zip: payload.zipcode,
				phone: payload.phone,
			})
		}
	}
})
