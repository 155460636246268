<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet class='mb-8'>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
			<template #subtitle>
				{{ labelActions.getLabel(screenName, 'subTitle') }}
			</template>
		</QuestionSet>

		<div class='flex max-w-sm w-full justify-center mx-auto flex-col p-5'>
			<div class='mt-4 lg:mt-4'>
				<TextInput
					v-model='zipcode'
					img-person
					label='Zip Code'
					name='zip'
					type='text'
				/>
			</div>
		</div>
	</FlowScreen>
</template>

<script>

import { Actions } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'
import TextInput from 'components/TextInput.vue'
import Validators  from '@/validators'

export default {
	name: 'PreNeedZip',
	components:
	{
		FlowScreen,
		QuestionSet,
		TextInput
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {

			/**
			* This page/screen name
			*/
			screenName: 'preNeedZip',

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (!Validators.zip(this.zipcode))
			{
				errors.push('Please enter your zip code.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['zipcode'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "preNeedZipAffirm")
		},
	},
}

</script>
