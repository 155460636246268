<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
			<template #subtitle>
				{{ labelActions.getLabel(screenName, 'subTitle') }}
			</template>
		</QuestionSet>

		<div class='flex max-w-sm w-full justify-center mx-auto flex-col p-5'>
			<div class='mt-4 lg:mt-4'>
				<TextInput
					v-model='entryEmailAddress'
					img-person
					label='Email'
					name='email'
					type='email'
				/>
			</div>

			<div class='mt-4 lg:mt-4'>
				<TextInput
					v-model='entryPhoneNumber'
					class='mt-2'
					img-person
					label='Phone'
					name='phone'
					type='tel'
				/>
			</div>
		</div>
	</FlowScreen>
</template>

<script>

import { Actions } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'
import TextInput from 'components/TextInput.vue'
import Validators from '@/validators'

export default {
	name: 'AtNeedContactInformation',
	components:
	{
		FlowScreen,
		QuestionSet,
		TextInput,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedContactInformation',
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (!Validators.email(this.entryEmailAddress))
			{
				errors.push('Please enter a valid email address.')
			}

			if (!Validators.phone(this.entryPhoneNumber))
			{
				errors.push('Please enter a valid U.S. phone number.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['entryEmailAddress', 'entryPhoneNumber'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "atNeedServices")
		},
	},
}

</script>
