<template>
	<svg
		class='w-auto'
		fill='none'
		height='18'
		viewBox='0 0 16 18'
		width='16'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			:class='classes'
			d='M8.50596 1.18623L9.79819 5.77931C10.0239 6.57748 10.6058 7.20189 11.3493 7.44302L15.6251 8.83371C15.904 8.92429 16.0615 9.24045 15.9774 9.53977C15.9263 9.72119 15.7942 9.86332 15.6251 9.91818L11.3493 11.3089C10.6058 11.55 10.0239 12.1744 9.79819 12.9726L8.50596 17.5657C8.42378 17.8657 8.13089 18.0377 7.8513 17.9494C7.67908 17.8951 7.54463 17.7504 7.49404 17.5657L6.19943 12.9726C5.97543 12.1742 5.39393 11.5495 4.65066 11.3089L0.374906 9.91818C0.0960318 9.8276 -0.0614584 9.51144 0.0226314 9.21213C0.0737029 9.0307 0.205776 8.88857 0.374906 8.83371L4.65066 7.44302C5.39393 7.2024 5.97543 6.57774 6.19943 5.77931L7.49404 1.18623C7.57623 0.886145 7.86912 0.71416 8.1487 0.802449C8.32068 0.856546 8.45537 1.00123 8.50596 1.18623Z'
		/>
	</svg>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
	blue: Boolean,
	gold: Boolean,
})

// fill='#408CB2'
//

const classes = computed(() =>
	({
		'fill-p-gold-400': props.gold,
		'fill-p-blue-400': props.blue,
	}))

</script>
