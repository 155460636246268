Presentational component to visualize progress as a horizontal bar
<template>
	<div>
		<div class='h-2 w-full sm:max-w-[700px] mx-auto sm:rounded-full overflow-hidden bg-p-blue-200 '>
			<div
				class='h-full bg-p-blue transition-all motion-safe:duration-1000 duration-0'
				:style='style'
			></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ProgressBar",
	props: {
		value: {
			type: Number,
			default: 15,
		},
	},
	computed: {
		style() {
			return "width: " + this.value * 100 + "%";
		},
	},
}
</script>
