PNG for the "Helper" icon
<template>
	<div class='flex flex-col items-center justify-center bg-white rounded-lg'>
		<div
			class='inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden
					w-20 h-20 lg:w-28 lg:h-28'
		>
			<img
				alt=''
				class='h-full w-full'
				srcset="@/assets/helper.jpg 1x,
				@/assets/helper@2x.jpg 2x"
				
				src='@/assets/helper.jpg'
			/>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Helper',
	props:
	{
	},
	methods:
	{
	},
}

</script>

<style scoped lang="less">
</style>
