A tiny svg of a person. Used inside inputs.
<template>
	<svg
		class='person-icon'
		:class='getClasses'
		fill='none'
		viewBox='0 0 16 16'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M8 0.5C7.11595 0.5 6.2681 0.851189 5.64298 1.47631C5.01786 2.10143 4.66667 2.94928 4.66667 3.83333C4.66667 4.71739 5.01786 5.56524 5.64298 6.19036C6.2681 6.81548 7.11595 7.16667 8 7.16667C8.88406 7.16667 9.7319 6.81548 10.357 6.19036C10.9821 5.56524 11.3333 4.71739 11.3333 3.83333C11.3333 2.94928 10.9821 2.10143 10.357 1.47631C9.7319 0.851189 8.88406 0.5 8 0.5ZM8 9.66667C5.49667 9.66667 0.5 10.9233 0.5 13.4167V14.6667C0.5 15.1267 0.873333 15.5 1.33333 15.5H14.6667C15.1267 15.5 15.5 15.1267 15.5 14.6667V13.4167C15.5 10.9233 10.5033 9.66667 8 9.66667Z'
		/>
	</svg>
</template>

<script>

export default {
	name: 'PersonIcon',
	props:
	{
		active:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	computed:
	{
		getClasses()
		{
			return {
				'active': this.active,
				'inactive': !this.active,
			}
		},
	},
}

</script>

<style scoped lang="css">
	.person-icon.active path {
		fill: theme(colors.p-gold.400);
	}

	.person-icon.inactive path {
		fill: #7F898E;
	}
</style>
