<template>
	<div class='border-2 rounded-2xl'>
		<div class="p-8">
			<div class="text-3xl font-serif text-gray-900 font-normal pb-2"
				id='adjust-coverage'>Adjust Your Coverage Amount
			</div>
			<p class="text-p-gold text-lg italic">Changing selections will adjust final price.</p>
		</div>

		<hr />

		<div class="p-8">

			<ProductBudgetSlider
				v-model:budget='estimate.selectedDispositionBudget'
				v-model:selected-disposition='estimate.selectedDisposition'
				v-model:selected-urn-view='estimate.selectedUrnView'
				:caskets='Caskets'
				inline
				:urns='Urns'
			/>
		</div>

		<hr />

		<div class="pt-8 pl-8 pr-8">
			<div class="text-2xl font-serif text-gray-900 font-normal pb-1">Celebration Style</div>
			<p>Adjust your selection for what your loved ones will experience.</p>
		</div>
			<div
				class='p-8 grid grid-cols-1 lg:grid-cols-2 gap-3 md:gap-4'
			>
				<CheckableButton
					v-model='estimate.selectedTypeOfCelebration'
					:value='Service.Traditional'
					class="flex-1 basis-[max-content]"
				>
					<span class="whitespace-nowrap tracking-wide">TRADITIONAL/RELIGIOUS</span>
				</CheckableButton>
				<CheckableButton
					v-model='estimate.selectedTypeOfCelebration'
					:value='Service.Celebration'
					class="flex-1 basis-[max-content]"
				>
					<span class="whitespace-nowrap tracking-wide">CELEBRATION OF LIFE</span>
				</CheckableButton>
				<CheckableButton
					v-model='estimate.selectedTypeOfCelebration'
					:value='Service.None'
					class="flex-1 basis-[max-content]"
				>
					<span class="whitespace-nowrap tracking-wide">NO SERVICE</span>
				</CheckableButton>
				<CheckableButton
					v-model='estimate.selectedTypeOfCelebration'
					:value='Service.Undecided'
					class="flex-1 basis-[max-content]"
				>
					<span class="whitespace-nowrap tracking-wide">LET FAMILY DECIDE</span>
				</CheckableButton>
			</div>

		<hr />

		<div class="pt-8 pl-8 pr-8">
			<div class="text-2xl font-serif text-gray-900 font-normal pb-1">Body Presence At Memorialization</div>
			<p>Adjust your selection for a viewing or visitation for loved ones.</p>
		</div>
		<div class='flex flex-col justify-start gap-3 lg:flex-row md:gap-4 pt-4 mt-4 pb-4 mb-4 pl-8 pr-8'>
			<CheckableButton
				v-model='estimate.selectedBodyPresent'
				:value='Choice.Yes'
				class="flex-1 basis-[max-content]"
				:disabled="estimate.selectedTypeOfCelebration === Service.None"
			>
				<template v-slot="{disabled}">
					<span v-if="disabled" class="whitespace-nowrap tracking-wide line-through">YES</span>
					<span v-else class="whitespace-nowrap tracking-wide">YES</span>
				</template>
			</CheckableButton>
			<CheckableButton
				v-model='estimate.selectedBodyPresent'
				:value='Choice.No'
				class="flex-1 basis-[max-content]"
			>
				<span class="whitespace-nowrap tracking-wide">NO BODY PRESENT</span>
			</CheckableButton>
			<CheckableButton
				v-model='estimate.selectedBodyPresent'
				:value='Choice.Undecided'
				class="flex-1 basis-[max-content]"
				:disabled="estimate.selectedTypeOfCelebration === Service.None"
			>
				<template v-slot="{disabled}">
					<span v-if="disabled" class="whitespace-nowrap tracking-wide line-through">LET FAMILY DECIDE</span>
					<span v-else class="whitespace-nowrap tracking-wide">LET FAMILY DECIDE</span>
				</template>
			</CheckableButton>
		</div>
	</div>
</template>

<script setup lang="ts">

import { useEstimateStore } from '../store/estimate'
import { Choice, Service } from '../../../constants'
import { Caskets, Urns } from '../products'
import CheckableButton from 'components/CheckableButton.vue'
import ProductBudgetSlider from 'components/ProductBudgetSlider.vue'

const estimate = useEstimateStore()

</script>
