Privacy policy
<template>
	<main>
		<nav class='flex flex-col flex-col-reverse items-center p-0 sm:flex-row sm:text-left sm:justify-between sm:py-8 sm:px-12 bg-white shadow sm:items-center w-full'>
			<img
				v-if='legacy'
				class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
				src='@/assets/legacy_logo.png'
			/>
			<img
				v-else
				class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[50px] lg:w-[162px]'
				src='@/assets/preplan_today_logo.svg'
			/>
		</nav>
		
	<div class="flex justify-center">
	<div class="prose-lg max-w-7xl p-8">

<h5>Last Updated: [3-6-2023]</h5>
<h1>Privacy Policy</h1>

<h2>INTRODUCTION</h2>
<p>Your privacy is important to Precoa, LLC (“Precoa‎,” “us,” “our,” and/or “we”). We, together with our affiliates, subsidiaries, successors, and assigns, own and operate this website. This Privacy Policy describes our information and privacy practices that apply when you use this website, as well as any of our mobile applications (collectively, the “Website”). We provide preneed marketing and advertising services to funeral providers throughout the United States and assist individuals and families in prearranging selected funeral goods and services (our “Services”). We operate the Website for the benefit of our customers, and to provide information and resources about our Services to the public. We value the trust put in us by our customers, business partners, and others who share their personal information with us, and are committed to complying with applicable privacy and data protection laws. This Privacy Policy applies to information we process through the Website, as a data controller, and also describes how we process data through the provision of our Services on behalf of our customers, as a data processor or “service provider”. </p>
<p>PLEASE READ this Privacy Policy before you use the Website. Your use of the Website signifies to us that you have read and understood all of the terms of this Privacy Policy.</p>
<h2>This Privacy Policy explains:</h2>
	<ul>
		<li>What personal information we collect, and why we collect it;‎</li>
		<li>How we use personal information;‎</li>
		<li>How we protect that information;‎</li>
		<li>How you can control your information, including accessing, updating and deleting what ‎we store; and</li>
		<li>How and with whom we may share the information we collect.‎</li>
	</ul>
<h2>This Privacy Policy contains the following sections:</h2>
	<ul>
		<li>Updates and Changes to this Privacy Policy</li>
		<li>Our Role as a Service Provider</li>
		<li>Types Of Information Collected</li>
		<li>How We Collect Automatically Collected Information</li>
		<li>How We Use The Information We Collect </li>
		<li>Disclosure Of Collected Information To Others</li>
		<li>Do Not Track</li>
		<li>Children’s Privacy</li>
		<li>Transfer and Storage of Information</li>
		<li>Securing Collected Information</li>
		<li>Updating or Correcting Your Personal Information</li>
		<li>Your Rights Regarding Your Personal Information</li>
		<li>Retention and Storage</li>
		<li>Links To Third-Party Websites </li>
		<li>Additional Information</li>
	</ul>
<h2>UPDATES AND CHANGES TO THIS PRIVACY POLICY </h2>
<p>This Privacy Policy is effective as of the date listed at the top of this page. We may update this Privacy Policy from time to time including in relation to changes to our privacy practices or for other operational, legal, or regulatory reasons. If changes are made to this Privacy Policy, we will give you notice by posting the revised statement on our website, and where appropriate, by other means. We will notify you of significant changes to this Privacy Policy by posting a notice on the homepage of the Website, or by some other appropriate manner consistent with the significance of the changes. You can determine whether this Privacy Policy has been revised since your last visit by referring to the "Last updated" date at the top of this page. We will seek your consent prior to processing your personal information if and where we are required to do so by ‎applicable data protection laws, and provided we have current contact information for you.‎</p>
<h2>OUR ROLE AS A SERVICE PROVIDER</h2>
<p>We provide or perform the Services on behalf of our customers, the funeral homes we work with across the United States. As described in this Privacy Policy, the provision or performance of the Services may involve the processing of your personal information. In other words, we operate as a service provider, and, through the provision of our Services, we may collect and process personal information on behalf of and, in some instances, at the direction of our ‎customers. In these circumstances, and for purposes of applicable data privacy laws, Precoa‎ is ‎acting as a “service provider” acting upon the instructions of our customers with regard to any ‎personal information that we collect, or they provide, for processing through use of our Services.‎ </p>
<p>This means, to the extent required by applicable data protection laws, our customers have primary responsibility for compliance with data privacy ‎obligations over this personal information, including maintaining their own privacy program to communicate to individuals how their personal information is being processed and shared‎. As such, where our customers are required to maintain individual ‎organizational Privacy Policies, such customer Privacy Policies will primarily govern the use of personal information that is ‎collected and processed through use of our Services. Precoa‎ is not responsible for our subscribers’ privacy or security practices which may differ from those described in this Privacy Policy.‎</p>
<p>As the service provider, we process personal information when we provide or perform the Services on behalf of our ‎customers, and in accordance with our customers’ written instructions ‎as well as the terms of our contracts with our customers. ‎This also means that, where instructed or authorized by our customers, we may share personal information with third parties to carry out further processing of personal information on our customers’ behalf. Further, as a service provider, we will assist our customers in complying with applicable data privacy laws to the extent any such laws cover the personal information we process on their behalf. As such, we usually do not respond directly to any requests from individuals who desire to exercise their data privacy rights (as describe herein, below) with regard to their personal information for which we are only a service provider. Rather, when applicable, we will refer any such requests to the customer who is the data controller of such personal information.</p>
<p>We may collect, and you may opt to provide, your personal information through one of our websites. We use the personal information you provide us to contact you regarding the services of our customers, or the services of affiliated third parties, that pay us to market and advertise on their behalf. </p>
<h2>TYPES OF INFORMATION COLLECTED</h2>
<h3>Information You Provide to Us</h3>
<p>Certain of the Services or features of the Website may enable or require you to provide personal information. Some common ways you may provide us with personal information include when you request a Market Analysis, request a How-To Guide, request help finding a funeral home, register for a Precoa Escapes trip, or complete an online form related to preplanning a funeral. Depending on the nature of your interaction with us, the personal information you provide to us may consist of information including, but not limited to: </p>
<ul>
	<li>First, middle, last, and preferred name;‎</li>
	<li>job title and company name;‎</li>
	<li>email address;‎</li>
	<li>phone number</li>
	<li>mailing address;‎</li>
	 <li>your photo or images of your likeness;</li>
	<li>referral information;</li>
	<li>airline preferences;</li>
	<li>birthdate;</li>
	<li>passport information;</li>
	<li>gender;</li>
	<li>shirt size;</li>
	<li>dietary restrictions;</li>
	<li>emergency contact info;</li>
	<li>and other information necessary for travel accommodations.</li>
</ul>
<h3>Information Provided or Collected About You Via our Services </h3>
<p>As described above, Precoa‎ is a service provider, and, through the provision of our Services, we may collect and process personal information about you on behalf, or at the direction, of our ‎customers. The Services we provide to our customers include direct mail marketing, digital marketing, local seminars and group presentations, and other lead-generation activities designed to assist in the prearranging of funeral goods and services. Through the provision of the Services, we may collect the following types of personal information from or about you:</p>
<ul>
	<li>Information related to your identity (or the identity of someone you represent), ‎such as your name, physical address, e-mail address, phone number, date of birth, gender, and SSN; </li>
	<li>Information related to your use of the Services, such as communication preferences, and funeral goods or services that might interest you; </li>
	<li>Information you provide when you interact with us, such as the responses you provide in direct mail surveys, burial preferences, whether you are currently preplanned, veterans status, spousal status, and responses to life insurance applications (customer service and appointment-setting calls may be recorded for quality, training and research ‎purposes);‎</li>
	<li>Information about a deceased individual for whom you plan or arrange his or her funeral, such as names of relatives, gender, date of birth, date of death, cause of death, and disposition date;</li>
	<li>Information we generate to record our attempts to communicate with you, such as times we have called, and outcomes of those calls.</li>
</ul>

<h4>Information Collected from Third Parties</h4>
<p>Precoa may obtain personal information from other third-party sources, including our customers and business partners, as well as publicly and commercially ‎available sources. We may combine the information we receive from these third-‎party sources with information that we collect from you or your device, as described above, in order to perform the Services for our customers.‎</p>
<p>Information That We Collect Automatically </p>
<p>We use certain analytics tools on the Website that help us analyze usage and performance of the Website when you visit and use the Website. The information we collect automatically does not include any individuals’ names or contact information. Rather, when you visit, use, or navigate to the Website, these analytics tools automatically collect and store certain information from your computer or device about your visit, such as your IP address, browser and device characteristics, the date and time of visit, duration of visit, operating system, referring URLs, device name, and certain location information such as the country or region you are browsing from. We may also note whether and how you use the Website by recording site traffic patterns and “clickstreams.” Some of the usage data is collected by Google Analytics and provided to us, as explained in greater detail in the Automatically Collected Information Provided to Us by Google Analytics section below. </p>
<p>This Automatically Collected Information allows us to better understand the visitors to our Website, where they come from, and what ‎content on our Website is of interest to them. It also helps us to maintain the security and operation of our Website. We only ever use this Automatically Collected Information for our internal analytics purposes, to diagnose problems with our ‎servers, and to improve the quality, performance, and functionality of the Website‎. </p>
<h2>HOW WE COLLECT AUTOMATICALLY COLLECTED INFORMATION</h2>
<h3>Cookies</h3>
<p>A cookie is a text file containing small amounts of information that are downloaded to a device when ‎it visits a website.‎ Standing alone, a cookie does not identify an individual user. Rather, it identifies the computer, mobile device, or browser that a user uses to access the Website. Cookies may be used to remember your personal settings, pre-fill forms for you, and to monitor your use of the Website to improve our services. </p>
<p>Most browsers allow you to refuse to accept cookies and to delete cookies, though the methods for doing so may vary from browser to browser. Information about managing cookies in commonly used browsers is available online from www.allaboutcookies.org. </p>
<h4>Types of Cookies And Why We Use Them</h4>
<p>There are two types of cookies: Session-based and persistent. Session cookies exist only during one session, and they disappear from your computer or device when you close your browser software or otherwise end your session. Persistent cookies remain on your computer or device after you close your browser or otherwise end your session.</p>
<p>The Website uses session cookies to provide us with information about the Website features used and activities conducted while you are using the Website. It also uses session cookies to help process information you input into the Website. The Website uses persistent cookies solely for remembering any preferences or choices you make regarding your use of the website, including your cookie settings.</p>
<p>There are four general categories of cookies. A description of each category of cookie is below, followed by a table describing the categories of cookies used on the Website.</p>
<ul>
	<li>Strictly necessary cookies. These cookies are essential to enable you to move around a website and use its features. Without these cookies, services you have asked for, like logging into a secure area of our Website, cannot be provided.</li>
	<li>Performance / analytical. These cookies collect information about how visitors use a website. The information collected by these cookies is performance and usage data such as that collected by Google Analytics as described throughout this Privacy Policy.</li>
	<li>Functionality cookies. These cookies allow a website to remember choices you make (such as your username or ID, language preference, or the area or region you are in) and provide enhanced, more personal features. They may also be used to provide services you have asked for. The information these cookies collect may be anonymized, and they cannot track your browsing activity on other websites.</li>
	<li>Targeting and advertising cookies. These cookies track browsing habits and are used to deliver targeted (interest-based) advertising. They are also used to limit the number of times you see an ad and to measure the effectiveness of advertising campaigns. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organizations, such as advertisers.</li>
</ul>
<p>We use the following categories of cookies for the reasons described below: </p>
<table class="table-auto">
	<thead>
<tr class="bg-slate-200">
	<th>Category</th>
	<th class="whitespace-nowrap">Do we use?</th>
	<th>Purpose and Description</th>
</tr>
</thead>
<tbody>
	<tr>
<td>Strictly Necessary</td>
<td>Yes</td>
<td>We use these cookies to enable you to navigate the Website and use certain features, including logging in to our applications.</td>
</tr>
<td>Performance</td>
<td>Yes</td>
<td>We use these cookies to improve the performance of our Website and enhance your experience. Google Analytics automatically collects certain usage and performance data from our Website users. The information these cookies collect is aggregated and anonymous information, and we are never provided with your personal information from these cookies. </td>
<tr>
<td>Functionality</td>
<td>No</td>
<td></td>
</tr>
<tr>
<td>Advertising</td>
<td>Yes</td>
<td>We use these cookies with certain social media platforms for marketing and advertising purposes.</td>
</tr>
</tbody>
</table>

<h3>Web Beacons</h3>
<p>We use web beacons alone or in conjunction with cookies to compile Automatically Collected Information about your use of the Website, as well as your interaction with emails from us. Web beacons are clear electronic images embedded in a web page, website feature, or e-mail message, which can recognize certain types of information on your computer or device, such as cookies, when you viewed a particular website associated with the web beacon, and a description of any such website. For example, we may place web beacons in e-mail messages that notify us when you click on a link in the e-mail that directs you to the Website. We use web beacons to operate and improve the Website and electronic communications, and to statistically monitor how many people are using the Website or viewing electronic communications from us, and for what purposes.</p>
<h3>Third Party Cookies and Website Analytics</h3>
<p>We use third party services provided by Google analytics to analyze Website activity. When you visit the Website, Google Analytics automatically collects ‎information from you through the use of Google’s analytics IDs, and Google provides some of this information to us. An analytics ID is a specific string of numbers and letters (often called a “character string”) that is assigned to your computer or device but does not name you. The analytics ID allows Google to track usage data of the Website, such as date and time of visit, duration of visit, Website traffic patterns, “clickstreams,” other similar ‎information about your use of the Website, ‎the type of web browser used, the operating ‎system/platform you are using, your IP address, the ‎websites that referred or linked you to our ‎Website, and your CPU speed.‎ Google Analytics does not share the analytics ID assigned to your computer or device that you use to access and use the Website. Google Analytics provides information about the use of our Website to us in aggregate form (i.e., data about many Website users combined and not just about you). Some of this data might include the regional location of Website users, but again, this data will be in aggregate (and not individual) form. We rely on this aggregate data to inform us how users are using the ‎Website and to help us improve the Website. </p>
<h3>HOW WE USE THE INFORMATION WE COLLECT </h3>
<p>We use information that we collect about you or that you provide to us for the reasons described in this Privacy Policy. In general, we will use the personal information we collect from you only for the purposes ‎described in this Statement, or for the purposes stated at the time we collect your ‎personal information. We may also use your personal information for other purposes ‎that are not incompatible with the purposes we have disclosed to you if and ‎where this is permitted or required by applicable data protection laws.‎</p>
<p>We may use both Automatically Collected Information and your personal information to help diagnose problems with the Website, to analyze performance and usage patterns on the Website, and to improve the Website and the services offered to you through the Website.‎</p>
<h3>Information You Provide to Us</h3>
<p>We use personal information you provide to us to provide you with services or information that you request. Specific uses of your personal information you provide to us include: </p>
	<ul>
		<li>delivering services or carrying out transactions that you have requested;</li>
		<li>account set ‎up and administration;</li>
		<li>responding to questions or inquiries that you make, including customer service requests;‎</li>
		<li>providing information about our customers’ products, services, and developments that may interest you;</li>
		<li>providing you a more consistent ‎or customized experience in interacting with us;</li>
		<li>To send you information about offers, events, commercial ‎incentives, or promotions you may be interested in, as described in the Marketing Communications section, below.</li>
	</ul>
<h3>Information Provided or Collected About You via our Services for Our Customers</h3>
<p>As a service provider, we use the information provided or collected about you through the provision of the Services to our customers for the sole purpose of providing the Services to, or on behalf of, our Customers. Specifically, we use your personal information to distribute mail, set appointments over the phone and internet, and facilitate the selection and purchase of preneed insurance products. We work with our customers, based on their instruction, to process and use the personal information we collect. Depending on the data processing instructions we receive from a customer, we may disclose to the customer all or part of the personal information processed through the provisions of Services on their behalf, or data or information derived therefrom. </p>
<p>Unless instructed by a customer, or required for legal or regulatory purposes, we will never disclose information processed through the provision of the Services to any third party, unless deemed necessary for the purposes contemplated in our business agreement with our customers.</p>
Information We Collect Automatically 
<p>As described in the sections on Automatically Collected Information above, we use Automatically Collected Information in an effort to ensure that our Website will appear and function appropriately on your computer, to help us improve the Website and Services, and to determine which website referred you to our Website. This may include customizing our Website and Services to your preferences or interests, making them more compatible ‎with your ‎technology, or otherwise making them easier to use; maintaining the security of and ‎otherwise protecting them; ‎and developing new Precoa products and ‎services‎. ‎We may also use Automatically Collected Information specifically to track what our Website users are looking at most frequently so we can recognize and deliver more of the features, products, and services that our users desire.</p>
Marketing Communications
<p>If you sign up for our mailing list, complete an online form with your contact information, or otherwise subscribe to receive communications from us or one of our customers, we will contact you to provide information about products or services that may be of interest to you, or to provide other information regarding Precoa or our customers‎, upcoming events and promotions, new products, and other general information about products and/or services we or our customers provide.</p>
<p>If you request that we not contact you for marketing purposes, we reserve the right to retain your contact information and to contact you for non-marketing purposes relating to your use of the Website and any ongoing business interactions with us. We further retain the right to use your contact information to comply with our legal obligations, resolve disputes, and enforce our agreements. We may also retain your contact information on our “Do-Not-Contact List” in order to ensure we do not contact you for marketing purposes following a request to not be contacted by us or our customers.</p>
DISCLOSURE OF COLLECTED INFORMATION TO OTHERS
<p>Except in the limited circumstances stated in this Privacy Policy, we do not share, sell, or otherwise transfer or disclose your personal information to any third party. As part of the Services we provide to our customers, we may share personal information with other service providers that we collaborate with on behalf of our customers. </p>
Third-Party Service Providers
<p>We may provide your personal information and Automatically Collected Information to third parties that provide us with data processing services. We only make such disclosures to the extent they are necessary and appropriate, in our discretion, to perform certain services on our behalf (e.g., improving security, services and features, directing mailings, fulfilling orders, conducting website analytics, ancillary marketing features, lead-nurturing programs, or other data processing as described in this statement or described to you when we collect your information). These third parties are required to keep the information confidential and are not authorized to process your personal information or Automatically Collected Information for any other purpose other than as instructed by us. We will not make third-party disclosures of any personal information we process in our role as a service provider for our customers, unless we are instructed or authorized to do so by our customers. </p>
Merger, Acquisition, or Sale
<p>We may share and transfer any and all personal information and Automatically Collected Information with and to a third party (whether affiliated or not), in the event of a sale or transfer of assets, or other disposition of all or any portion of our business, whether as a going concern or as part of a bankruptcy, liquidation, or similar proceedings in which information of users is among the assets transferred. Note that personal information and Automatically Collected Information will be shared in connection with evaluating any such transaction before it happens, and as part of the transferred assets.</p>
To Meet our Legal Obligations
<p>Notwithstanding anything to the contrary in this Privacy Policy, we may also use and/or disclose your personal information and Automatically Collected Information, to any competent law enforcement body, regulatory or government agency, court, or other third party if (i) we believe we are legally obligated to do so, (ii) we believe disclosure is necessary to protect, establish, or exercise our legal rights or defend against legal claims, or (iii) we believe disclosure is necessary to protect our customers, third parties, or our employees, property, or business.</p>
<p>Disclosures under this section may include, but are not limited to: complying with our obligations to retain certain business records for minimum retention periods; establishing, ‎exercising, or defending legal claims; complying with laws, regulations, court orders, or other legal process; ‎detecting, preventing, and responding to fraud, intellectual property infringement, violation of our contracts or ‎agreements, violations of law, or other misuse of Precoa internet sites, applications, products or services; and ‎protecting Precoa’s rights or property, or yours or others’ health, safety, welfare, rights, or property.‎</p>
Social Media
<p>Certain pages of our Website might allow you to connect and share information or photographs using various social media platforms, including Facebook and LinkedIn. These features might require us to implement cookies, plug-ins, or application programming interfaces (APIs) provided by such social media platforms to facilitate the communications and features. When you provide us with information through these platforms, the information also becomes subject to their Privacy Policies. In addition, by choosing to use any third-party social media platform or choosing to share content or communications with any social media platform, you allow us to share information with the designated social media platform. We cannot control any policies or terms of such third-party platform. As a result, we cannot be responsible for any use of your information or content by a third-party platform, which you disclose at your own risk. We encourage you to review the Privacy Policies of these platforms.</p>
<p>De-identified and Aggregate Automatically Collected Information</p>
<p>Precoa may anonymize or aggregate any of the information we collect so that any identities have been removed to the point such that the information is not linked or reasonably linkable ‎to any individual or household, including via a device, and use it for any purpose, including for ‎research and product-development purposes. Such information will not identify you individually.‎</p>
<h2>DO NOT TRACK</h2>
<p>The Website does not track your online activities over time and across third-party web sites or ‎online services, and so does not respond to browser “Do Not Track” signals. As described below, ‎the Website may contain links to other websites not owned or operated by Precoa‎. These ‎websites may set cookies that obtain information about the fact that a web browser visited the ‎Website from a certain IP address. These websites are not authorized to collect any other ‎personal data from the Website unless you provide it to them directly.‎</p>
<h2>CHILDREN’S PRIVACY</h2>
<p>The Website is not directed at nor intended for use by children under the age of 18, and as a data controller we do not knowingly collect any personal information directly from children under the age of 18. At times, in our role as a service provider, a customer may instruct us to use the Services to verify and authenticate the identity of an individual under the age of 18. In such case the customer is the data controller, and its privacy policies govern the use of such data. Notwithstanding the foregoing, we will never use or disclose any personal information of a child under the age of 18 for marketing or advertising purposes. If you are under age 18, you should not use this Website and you should not send us personal information about yourself. </p>
<h2>TRANSFER AND STORAGE OF INFORMATION</h2>
<p>Your use of the Website may involve your personal information being transferred to, and processed in, ‎countries other than the country in which you reside. These countries may have data protection laws ‎that are different to the laws of your country (and, in some cases, may not be as protective).‎</p>
<p>Specifically, we are headquartered in the United States, and our Website and Services are hosted in the United States. This means that if you use our Website or Services, and if we collect your ‎personal information through the means described in this Statement, your personal information will ‎be processed by us in the United States, which (in the absence of an adequacy decision from ‎the EU Commission or similar body from another country or territory) may not have the same level of ‎data protection as in your country. However, we have taken appropriate safeguards to require that ‎your personal information will remain protected in accordance with this Privacy Policy.‎ </p>
<h2>SECURING COLLECTED INFORMATION</h2>
<p>Precoa uses commercially-reasonable physical, electronic, and administrative security measures to assist in ‎protecting against the loss, misuse, and unauthorized alteration of Personal Information that is under ‎our control. This includes the use of firewalls, encryption of data, voluntary vulnerability scans and ‎penetration testing, workforce training and access limitations, and other security measures.  ‎‎  </p>
<p>Unfortunately, no data storage system, or system of transmitting data over the Internet or wireless network, can be guaranteed to be 100% secure, and no security system can prevent all security breaches. As a result, we do not and cannot guarantee the security of our servers, the means by which personal information is transmitted between your computer or device and our servers, or any personal information provided to us or to any third party to or in connection with the Website or otherwise.</p>
<h2>UPDATING OR CORRECTING YOUR PERSONAL INFORMATION</h2>
<p>If you have voluntarily given us personal information through our Website or by responding to a request or inquiry, you may update or change your personal information by sending an email request to privacy@precoa.com. </p>
<p>If you request that your personal information be changed or deleted from our databases, we will use commercially-reasonable efforts to remove your personal information from our databases, but see below regarding Retention and Storage as to why we might maintain some information. Please note that before carrying out your request, we may ask you to verify your identity and/or provide further details about your request.</p>
<p>Where we hold personal information about you on behalf of our customers in our role as a service provider, we can only update or correct the information we hold in accordance with the instructions or authorization from our customers. As such, you should make any request to update or correct such personal information directly to the customer who is the data controller.</p>
<h2>YOUR RIGHTS REGARDING YOUR PERSONAL INFORMATION</h2>
<p>If you are based in certain jurisdictions, you may also, in some circumstances, have the following rights in relation to the personal information we hold about you. You can request to:</p>
	<ul>
	<li>access a copy of the personal information held about you</li>
	<li>rectify any incorrect or incomplete personal information we hold about you</li>
	<li>delete, restrict or remove the personal information we hold about you</li>
	<li>transfer the personal information we hold about you to another party; or</li>
	<li>object to the sale of your personal information (‎please note that Precoa does not currently sell Personal Information, nor has it sold any Personal ‎Information in the preceding 12 months)‎.</li>
	</ul>
<p>To exercise any data privacy rights you might have, please e-mail us at privacy@precoa.com, or write to us at the address provided in the Contact Us section below. Please note that with regard to all these rights, we reserve the right to:</p>
	<ul>
	<li>refuse your request based on the exemptions set out in the applicable data protection laws;</li>
	<li>refuse your request where we process your data on behalf of our customers in our role as a service provider (in such instances you must should make the request directly to the customer);</li>
	<li>request for proof of your ID to process the request or request further information;</li>
	<li>charge you a reasonable administrative fee for any repetitive, manifestly unfounded, or excessive requests.</li>
	</ul>
<p>If we refuse your request to exercise these rights, we will give reasons for our refusal and allow you to challenge our decision. If we have shared your personal information with others, we will tell them about your request to rectify, erase, restrict or object to the processing where possible. If you ask us, where possible and lawful to do so, we will also tell you with whom we shared your personal information so that you can contact them directly.</p>
<p>If you have any concerns about how we handle your personal information, please contact us. </p>
<h2>Your California Privacy Rights</h2>
<p>Please note, as described above in this Privacy Policy, Precoa does not sell Personal Information, nor ‎does it disclose Personal Information to third parties for their direct marketing purposes. ‎</p>
<p>Notwithstanding the above, if you are a California resident, you have the right to request information from us regarding the ‎manner in which we share certain categories of your personal information with third-parties. ‎Specifically, California law provides you the right to submit a request to us at the designated address in the “Contact Us” section below to ‎receive the following information:‎</p>
	<ul>
	<li>the categories of information we disclosed to third-parties for the third-parties’ direct ‎marketing purposes during the preceding calendar year;‎</li>
	<li>the names and addresses of third-parties that received such information; and</li>
	<li>if the nature of a third-parties’ business cannot be reasonably determined from the third-‎parties’ name, examples of the products or services marketed.‎</li>
	</ul>
<p>You are entitled to receive a copy of this information in a standardized format and the ‎information will not be specific to you individually.‎</p>
<h2>RETENTION & STORAGE</h2>
<p>We may retain all personal information and Automatically Collected Information in our databases for as long as necessary to meet the relevant purposes for which we’ve collected it, including for the purposes of performing our duties in our role as a service provider for our customers, as well as satisfying any applicable legal, accounting, or regulatory requirements. To determine the appropriate length of time for holding your personal information, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purpose for which we process your data and whether we can achieve those purposes through other means, along with the applicable legal requirements. As described above, you may also be able to ask us to remove your information from our databases, unless applicable laws require otherwise.</p>
<p>Note that even after you ask us to remove your information from our databases, it may be impossible to remove some information, such as information tracked in our web server log files and information that may reside in backup files. We may also retain Automatically Collected Information even after you request that we remove your information from our databases, for use in the aggregate and to analyze and improve the Website, our products, and our services. </p>
<p>Further, even after you request that we remove your information from our databases, we may retain personal information and Automatically Collected Information for a limited time to comply with any applicable law, rule, regulation, or guideline, to prevent fraudulent activity, to protect ourselves against liability, and to allow us to enforce our contractual or other rights and to pursue available remedies and limit any damages we might sustain. </p>
<h2>LINKS TO THIRD-PARTY WEBSITES </h2>
<p>This Privacy Policy applies only to the personal information and Automatically Collected Information collected through the Website or otherwise given to us by you through offline contact. The Website contains links to other websites (“Linked Sites”), products, and services that are not owned or controlled by us. We provide Linked Sites to you solely as a convenience, and the inclusion of Linked Sites does not imply endorsement by Precoa‎ of the Linked Sites or the products or services available on the Linked Sites. You access Linked Sites at your own risk, and by accessing them, you leave the Website. We are not responsible for and have not reviewed the privacy practices of the Linked Sites, or the owners or operators of the Linked Sites, the products or services they offer, or the tracking technologies they use. The owners and operators of the Linked Sites, products, or services may have their own policies regarding privacy and security. You should review any Linked Sites, products, or services for their own privacy practices to determine what information about you may be collected and used when you use such Linked Sites, products, or services.</p>
<h2>CONTACT US</h2>
<p>If you have any comments or questions about this Privacy Policy or the use of your personal or Automatically Collected Information, you may submit a request using the following methods:</p>
<p>
	<b>E-mail at:</b> privacy@precoa.com <br>
	<b>Toll-free phone number at:</b> 1-888-227-6153 <br>
	<b>Or by mail to:</b><br>
	Precoa, LLC<br>
	Privacy & Data Protection<br>
	‎13221 SW 68th Pkwy<br>
	Suite 100<br>
	Portland, OR 97223<br>
</p>



	</div>
	</div>

	</main>
	
</template>

<script>
export default
{
	data()
	{
		return {
			// false if default logo, true if Legacy logo
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),
		}
	}
}
</script>

<style lang="css" scoped>
	td, th {
		@apply p-4 text-left;
	}
	ul {
		@apply list-disc;
	}
	ol {
		@apply list-decimal;
	}
	a {
		text-decoration: underline;	
	}
</style>
