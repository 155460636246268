<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
			<template #subtitle>
				{{ labelActions.getLabel(screenName, 'subTitle') }}
			</template>
		</QuestionSet>
		<div class="mt-8">
			<div class='m-0 m-auto grid grid-cols-1 pl-4 pr-4 lg:pl-8 lg:pr-8 lg:gap-4 gap-3 p-4 max-w-screen-lg'>
				<CheckboxCard
					v-model='optInEstateClosing'
					description='Meet with an estate expert who can help take the burden of closing an estate off your shoulders.'
					name='Estate Closing'
				/>
				<!-- <CheckboxCard
					v-model='optInFinancialPlanning'
					description='I need help with unexpected finances.'
					name='Financial Planning'
				/> -->
				<CheckboxCard
					v-model='optInGriefCounseling'
					description="30 days free with a certified grief coach who can guide you through your journey to healing."
					name='Grief Counseling'
				/>
				<CheckboxCard
					v-model='none'
					description="I'm ok. I don't need assistance right now."
					name='None'
					@update:model-value='onNoneUpdated'
				/>
			</div>
		</div>
	</FlowScreen>
</template>

<script>

import { computed, ref } from 'vue'
import { Actions, Errors } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import CheckboxCard from '../../../components/CheckboxCard.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedServices',
	components:
	{
		CheckboxCard,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	setup(){
		const estimate = useEstimateStore()

		// Local data value for whether "none" should be checked.
		// We don't use a computed because it should start out unchecked.
		const none = ref(false)

		const oneOrMoreServicesSelected = computed(() => {
			return estimate.optInEstateClosing ||
				estimate.optInFinancialPlanning ||
				estimate.optInGriefCounseling
		})

		// When the user selects one of the services, uncheck "none"
		estimate.$subscribe(() => {
			if (oneOrMoreServicesSelected.value) {
				none.value = false
			}
		})

		// When the user checks "none", unselect all services
		const onNoneUpdated = (value) =>
		{
			if (value)
			{
				none.value = value

				estimate.$patch({
					optInEstateClosing: false,
					optInFinancialPlanning: false,
					optInGriefCounseling: false,
				})
			}
		}

		return {
			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

			none,
			oneOrMoreServicesSelected,
			onNoneUpdated,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedServices',
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			// User must check one of the boxes
			if (!this.oneOrMoreServicesSelected && !this.none)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore, [
			'optInEstateClosing',
			'optInFinancialPlanning',
			'optInGriefCounseling',])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			if (this.oneOrMoreServicesSelected)
			{
				this.$emit('navigate', "atNeedServicesAffirm")
			} else {
				this.$emit('navigate', "atNeedNoServicesAffirm")
			}


		},
	}
}

</script>
