
import { defineStore } from 'pinia'
import { atNeedAddOns, preneedAddOns } from '@/intel/addOns'
import { computed, ref } from 'vue';
import { useEstimateStore } from './estimate';
import { useBundleStore } from './bundle'
import actuary from '@/intel/actuary.json'
import { DateTime } from 'luxon'

export const useCalculatorStore = defineStore('calculator', () => {
	const estimate = useEstimateStore()
	const bundle = useBundleStore()

	// Data from the API
	const rates = ref({
		// 5: 0.02267,
		// 10: 0.01433,
	})
	const futureRates = ref({
		// 5: 0.02383,
		// 10: 0.01533,
	})

	function applyApiPayload(payload) {
		rates.value = payload.rates
		futureRates.value = payload.futureRates

		// Now that we have some rates from the API, pick a default one
		if (estimate.selectedInsuranceTermYears === null && termsAvailable.value.length > 0) {
			estimate.selectedInsuranceTermYears = termsAvailable.value[0]
		}
	}

	const eligibleAddOns = computed(() => estimate.isPreneed ? preneedAddOns : atNeedAddOns)

	// Total cost of selected add-ons
	const coverageAddOnAmount = computed(() => {
		return (eligibleAddOns.value
			.filter((addOn) => estimate[addOn.key])
			.filter((addOn) => !addOn.excludeFromInsurance)
			.map((addOn) => addOn.price)
			.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
		)
	})

	// Expected years to live for plan holder, rounded to nearest 5 years.
	// Always return at least 5.
	const lifeExpectancy = computed(() => {
		const bday = estimate.planningForBirthdate

		// Default life expectancy of 10 yrs
		if (!bday) {
			return 10
		}

		const age = Math.floor(DateTime.now().diff(bday, 'years').toObject().years)
		const clampedAge = Math.min(actuary.length - 1, Math.max(0, age))
		const expected = actuary[clampedAge];

		// Round to the nearest 5 years
		const rounded = Math.round(expected / 5) * 5

		// Return minimum of 5 years
		return Math.max(5, rounded)
	})

	const inflatedFuneralCost = computed(() => {
		// Use 4% annual inflation
		const inflation = 0.04

		return funeralValue.value * Math.pow(1 + inflation, lifeExpectancy.value)
	})

	// Total estimate of funeral items (not including add-ons)
	const funeralValue = computed(() => estimate.selectedDispositionBudget + bundle.baseCost)

	// Total amount that the policy covers
	const policyAmount = computed(() => funeralValue.value + coverageAddOnAmount.value)

	// Cost to the customer of paying for the insurance in a single payment
	const singlePayPayment = computed(() => policyAmount.value)

	const savingsOverNextBracketPerYear = computed(() =>
		(years = estimate.selectedInsuranceTermYears) =>
			(futureRates.value[years] - rates.value[years]) *
			12 * policyAmount.value
	)

	// Whether the selected pay plan is in monthly payments
	const multiPaySelected = computed(() => estimate.selectedInsuranceTermYears > 0)

	// Multiply this by the policy amount to get the monthly premium
	const monthlyMultiplier = computed(() => (years = estimate.selectedInsuranceTermYears): number => {
		return rates.value[years]
	})

	// Amount to pay each month
	const monthlyPayment = computed(() =>
		(years = estimate.selectedInsuranceTermYears) =>
			monthlyMultiplier.value(years) * policyAmount.value
	)

	// Reset the state
	function $reset() {
		rates.value = {
			5: 0.02267,
			10: 0.01433,
		}
		futureRates.value = {
			5: 0.02383,
			10: 0.01533,
		}
	}

	/**
	 * Plan term lengths to show the user as options
	 * This will be a subset of the product options given to us by the API
	 */
	const termsAvailable = computed(() => {
		// Our favorite term lengths, in order from highest to lowest
		const priorityTerms = [10, 5, 0]
		const termsShown = 2

		// Get term lengths which we can sell. There is no rate for 0 but we can always sell it
		const termsInProduct = Object.keys(rates.value)
		termsInProduct.push('0')

		// Get list of our favored term lengths which are present for the active product
		const termsMatched = priorityTerms.filter(yrs => termsInProduct.includes(yrs.toString()))

		// Return first termsShown items
		return termsMatched.slice(0, termsShown)
	})

	return {
		// Methods
		applyApiPayload,
		$reset,

		// Computed
		funeralValue,
		monthlyPayment,
		singlePayPayment,
		multiPaySelected,
		monthlyMultiplier,
		policyAmount,
		savingsOverNextBracketPerYear,
		termsAvailable,

		lifeExpectancy,
		inflatedFuneralCost,
	}
})
