Logical component to wrap content in a form which will display validation
errors only after the first time the user attempts to submit.
<template>
	<section class='content'>
		<form @submit.prevent='interceptSubmit'>
			<BackButton
				v-if='!noGoingBack'
				class='back-button'
				@click='$emit("back")'
			/>

			<slot></slot>

			<div class='mt-4'>
				<NextButton
					:alert='alert'
					:enabled='isFormValid'
					@blur='dirty = false'
				/>
			</div>
		</form>
	</section>
</template>

<script>
import BackButton from 'components/BackButton.vue'
import NextButton from 'components/NextButton.vue'

export default {
	name: "FlowScreen",
	components:
	{
		BackButton,
		NextButton,
	},
	props: {
		/**
		 * Array of string error messages for the user
		 */
		errors:
		{
			default: () => [],
			type: Array,
		},

		noGoingBack: Boolean,
	},
	emits: ['back', 'submit'],
	data()
	{
		return {
			/**
			 * User attempted to submit the form
			 */
			dirty: false,
		}
	},
	computed:
	{
		/**
		 * String error message to show the user
		 * @return {String}
		 */
		alert()
		{
			// Show first error if the form is dirty
			if (this.dirty && this.errors.length > 0)
			{
				return this.errors[0]
			}
			return ''
		},

		/**
		 * Get whether the form is currently valid
		 * @return {Bool}
		 */
		isFormValid()
		{
			return this.errors.length === 0
		},
	},
	methods:
	{
		/**
		 * Submit the form only if it is valid
		 */
		interceptSubmit()
		{
			this.dirty = true

			if (this.isFormValid)
			{
				this.$emit('submit')
			}
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}

}
</script>
