import Bundle from '../Bundle'

const bundle: Bundle = {
	name: 'Burial with memorial',
	baseCost: 4675,
	includes: [
		'Basic services of licensed funeral director and staff',
		'Professional care of the deceased',
		'Body preparation',
		'{service} or graveside service',
		'Transfer of the body to funeral home',
		'Funeral coach or flower car',
	],
	excludes: [
		'Viewing at a funeral home',
	],
}

export default bundle
