
interface AddOn {
	name: string,

	key: string,

	// Price of zero will say "Free Consultation"
	price: number,

	detail: string,

	// Should the cost not be included in insurance coverage?
	excludeFromInsurance?: boolean,

	// Should the cost not show a monthly rate?
	singlePayOnly?: boolean,

	recommended?: boolean,
}

export const preneedAddOns: Array<AddOn> = [
	{
		name: 'Travel Protection',
		price: 549,
		key: 'purchaseTravelProtection',
		detail: 'When a death occurs away from home (typically a 75-mile radius from the funeral home providing services), transportation costs can quickly add up to thousands of dollars. Our Travel Protection membership covers all the transportation costs and logistics from anywhere in the world whenever a death occurs more than 75 miles away from home.',
		singlePayOnly: true,
		recommended: true,
	},
	{
		name: 'Obituary',
		key: 'purchaseObituary',
		price: 495,
		detail: `The purpose of an obituary is to have a written announcement of someone’s death, relay important details about the service, and most importantly, share memories about the life of the loved one who has just passed. The price of placing an obituary in a newspaper varies. $450 is the average price for a printed obituary bundle.`,
	},
	{
		name: 'Death Certificate',
		key: 'purchaseDeathCertificate',
		price: 137,
		detail: `Death certificates are required to settle estate affairs when contacting Social Security, insurance companies, banks, etc. On average, one death certificate costs $25. The number of death certficates needed depends on the size of the estate. Most families need a minium of 5 death certificates.`,
	},
	{
		name: 'Floral Arrangements',
		key: 'purchaseFlowers',
		price: 660,
		detail: `Floral arrangements are an important part of any memorial service. Standard floral arragements include a flower spray for a casket and two matching arrangements for each side of the casket or urn. These standard floral arrangements on average cost around $600.`,
	},
]

export const atNeedAddOns: Array<AddOn> = [
	{
		name: 'Grief Coaching',
		recommended: true,
		key: 'optInGriefCounseling',
		price: 0,
		detail: `Losing a loved one is a life-changing event, and grief is a healthy and normal response to loss. By signing up for our grief coaching program, you get one-on-one support from a certified grief coach, along with access to hundreds of hours of content to help navigate how you’re feeling.`,
	},
	{
		name: 'Estate Plan Consultation',
		recommended: true,
		key: 'optInEstateClosing',
		price: 0,
		detail: `Navigating the closure or transfer of an estate can be overwhelming and confusing, especially when dealing with grief. Our team of Estate Attorneys have decades of experience guiding families every step of the way. You’ll get an individualized plan after a free consultation.`,
	},
	{
		name: 'Obituary',
		key: 'purchaseObituary',
		price: 495,
		detail: `The purpose of an obituary is to have a written announcement of someone’s death, relay important details about the service, and most importantly, share memories about the life of the loved one who has just passed. The price of placing an obituary in a newspaper varies. $450 is the average price for a printed obituary bundle.`,
	},
	{
		name: 'Death Certificate',
		key: 'purchaseDeathCertificate',
		price: 137,
		detail: `Death certificates are required to settle estate affairs when contacting Social Security, insurance companies, banks, etc. On average, one death certificate costs $25. The number of death certficates needed depends on the size of the estate. Most families need a minium of 5 death certificates.`,
	},
	{
		name: 'Floral Arrangements',
		key: 'purchaseFlowers',
		price: 660,
		detail: `Floral arrangements are an important part of any memorial service. Standard floral arragements include a flower spray for a casket and two matching arrangements for each side of the casket or urn. These standard floral arrangements on average cost around $600.`,
	},
]
