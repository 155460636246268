// Generic choice
export enum Choice {
	Yes,
	No,
	Undecided,
	Blank,
}

// Options the user can choose for body disposition
export enum Disposition {
	Burial,
	Cremation,
	Undecided,
	Blank,
}

// Type of need the user can experience
// The back-end checks for these values
export enum Need {
	Preneed = 'preneed',
	AtNeed = 'atneed',
	Browsing = 'browsing',
	Blank = '',
}

// Type of service/celebration to have
export enum Service {
	Traditional,
	Celebration,
	None,
	Undecided,
	Blank,
}
