Button to navigate back
<template>
	<div class='back-button'>
		<button
			class='btn'
			type='button'
		>
			<div class='flex items-center'>
				<ChevronIcon />
				<p class='back-label'>
					Back
				</p>
			</div>
		</button>
	</div>
</template>

<script>

import ChevronIcon from 'components/icons/ChevronIcon.vue'

export default {
	name: 'BackButton',
	components:
	{
		ChevronIcon,
	},
	props:
	{
	},
	computed:
	{
	},
}

</script>

<style scoped lang="less">

	.back-button {
		.btn {
			font-family: 'Open Sans';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 29px;
			color: #80B2CC;

			.back-label {
				margin-left: 15px;
			}
		}
	}

</style>
