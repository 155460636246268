<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel('atNeedProduct', 'title') }}
			</template>
		</QuestionSet>

		<div class='cards-spacer'>
			<Cards>
				<Card
					:active='selectedDisposition === Disposition.Burial'
					@click='selectDisposition(Disposition.Burial)'
				>
					<template #icon>
						<FlowerBoxIcon
							:active='selectedDisposition === Disposition.Burial'
						/>
					</template>
					<template #title>
						Burial
					</template>
				</Card>

				<Card
					:active='selectedDisposition === Disposition.Cremation'
					@click='selectDisposition(Disposition.Cremation)'
				>
					<template #icon>
						<FlowerVaseIcon
							:active='selectedDisposition === Disposition.Cremation'
						/>
					</template>
					<template #title>
						Cremation
					</template>
				</Card>
			</Cards>
		</div>
	</FlowScreen>
</template>

<script lang="ts">

import { Actions, Main } from '../lang'
import { Disposition } from '../../../constants'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FlowerBoxIcon from 'components/icons/FlowerBoxIcon.vue'
import FlowerVaseIcon from 'components/icons/FlowerVaseIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedProduct',
	components:
	{
		Card,
		Cards,
		FlowerBoxIcon,
		FlowerVaseIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/**
			* This page/screen name
			*/
			screenName: 'atNeedProduct',

			/**
			* Version level constants / string labels
			*/
			constants: Main,

			Disposition,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if ([Disposition.Burial, Disposition.Cremation].indexOf(this.selectedDisposition) === -1)
			{
				errors.push('Please make a selection.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['selectedDisposition', 'selectedDispositionBudget'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "atNeedZip")
		},
		selectDisposition(disposition: Disposition)
		{
			if (disposition === Disposition.Cremation)
			{
				this.selectedDispositionBudget = 395
				gtag('event', 'cremation_disposition_selected')
			}
			else
			{
				this.selectedDispositionBudget = 6431
				gtag('event', 'burial_disposition_selected')
			}
			this.selectedDisposition = disposition
		},
	}
}

</script>
