An svg of a lit birthday
<template>
	<svg
		class='birthday-icon'
		:class='getClasses'
		fill='none'
		preserveAspectRatio='xMidYMid meet'
		viewBox='0 0 24 24'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M4.34389 22.1994C4.19132 22.1106 4.0235 22.0397 3.88892 21.928C3.67751 21.752 3.60341 21.4998 3.60287 21.2311C3.60069 19.8548 3.60178 18.479 3.60232 17.1027C3.60232 17.0782 3.61049 17.0531 3.61867 17.0073C3.68841 17.0433 3.74562 17.0705 3.80065 17.1016C4.80539 17.6704 5.83628 17.72 6.86608 17.2013C7.18592 17.0406 7.46925 16.7916 7.73896 16.5496C8.0637 16.2581 8.35684 15.9312 8.6794 15.6032C8.99869 15.9247 9.29619 16.2271 9.59696 16.5262C10.6235 17.5478 12.0739 17.8192 13.3773 17.1958C13.7058 17.0389 14.0011 16.7937 14.2774 16.5496C14.6048 16.2609 14.8953 15.9296 15.2167 15.6016C15.5458 15.9323 15.8553 16.2423 16.1637 16.5529C16.6437 17.0378 17.2082 17.3707 17.8839 17.4939C18.6794 17.6388 19.4291 17.5119 20.1271 17.0967C20.1669 17.0727 20.2077 17.0504 20.2491 17.0291C20.2595 17.0237 20.2736 17.0269 20.3134 17.0242C20.3134 17.0956 20.3134 17.1653 20.3134 17.2345C20.3134 18.4845 20.2954 19.7344 20.3216 20.9838C20.3336 21.57 20.1581 21.9918 19.5849 22.1988H4.34389V22.1994Z'
		/>
		<path
			d='M11.0378 10.1324V8.28906H12.8838V10.1187C12.9781 10.1242 13.0544 10.1318 13.1312 10.1318C14.5604 10.1324 15.9896 10.1324 17.4182 10.1324C18.5249 10.1324 19.3787 10.5895 19.954 11.5414C20.2107 11.9664 20.3251 12.4361 20.3278 12.9292C20.3305 13.4669 20.3349 14.0058 20.3044 14.5425C20.2515 15.4737 19.3225 16.2332 18.3925 16.1526C17.895 16.1096 17.4923 15.8987 17.1491 15.5532C16.5214 14.9223 15.8948 14.2902 15.1995 13.5895C15.1538 13.6762 15.1325 13.7546 15.0824 13.8048C14.4966 14.3938 13.9087 14.9811 13.3176 15.5647C12.6817 16.1918 11.829 16.3199 11.0504 15.8938C10.8787 15.8001 10.724 15.6644 10.584 15.5255C9.96117 14.9081 9.34601 14.2826 8.71342 13.6457C8.64804 13.7078 8.59573 13.7557 8.5456 13.8053C7.95224 14.3965 7.36215 14.9904 6.76606 15.5789C6.10732 16.2289 5.26604 16.3526 4.49124 15.8954C3.9807 15.5941 3.66141 15.1337 3.6358 14.5371C3.60638 13.8549 3.56769 13.1613 3.6565 12.4884C3.84285 11.0793 5.01649 10.1394 6.49853 10.134C7.92772 10.1285 9.35691 10.1329 10.7856 10.1329C10.8629 10.1329 10.9403 10.1329 11.0384 10.1329L11.0378 10.1324Z'
		/>
		<path
			d='M11.9677 1.79688C12.0009 1.84646 12.0276 1.88187 12.05 1.92056C12.548 2.782 13.0509 3.64125 13.5424 4.50705C13.9761 5.2715 13.8971 6.07681 13.3408 6.72466C12.8705 7.2728 12.0827 7.47167 11.3406 7.22921C10.6976 7.01943 10.1963 6.36941 10.1282 5.68723C10.0895 5.29656 10.1506 4.93041 10.3424 4.59314C10.8605 3.68157 11.388 2.77546 11.9121 1.86771C11.9241 1.847 11.9421 1.82957 11.9688 1.79688H11.9677Z'
		/>
	</svg>
</template>

<script>

export default {
	name: 'BirthdayIcon',
	props:
	{
		active:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	computed:
	{
		getClasses()
		{
			return {
				'active': this.active,
				'inactive': !this.active,
			}
		},
	},
}

</script>

<style scoped lang="css">
	.birthday-icon.active path {
		fill: theme(colors.p-gold.400);
	}

	.birthday-icon.inactive path {
		fill: #7F898E;
	}
</style>
