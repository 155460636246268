<!-- Card element to draw a rectangle with titles -->
<template>
	<div
		class='card img-btn flex lg:flex-col w-full max-w-2xl lg:w-64 xl:w-96'
		:class='getClasses'
	>
		<div
			class='img-btn-border flex lg:flex-col w-full h-20 lg:h-64 xl:h-72 bg-white rounded-lg border-2 border-slate-400 cursor-pointer
				hover:border-p-green hover:border-4'
		>
			<slot name='icon'></slot>
			<div class='p-2 p lg:p-4 lg:flex-grow-0 flex-1 flex items-center lg:justify-center lg:text-center'>
				<div>
					<h2 class='text-left text-xl font-bold '>
						<slot name='title'></slot>
					</h2>
					<h3 class='text-left text-sm lg:hidden leading-4'>
						<slot name='sub-title'></slot>
					</h3>
				</div>
			</div>
		</div>

		<div class='text-center hidden lg:block lg:mt-5'>
			<span>
				<slot name='sub-title'></slot>
			</span>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Card',
	props:
	{
		active:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	computed:
	{
		getClasses()
		{
			return {
				'active': this.active,
			}
		},
	},
}

</script>

<style scoped lang="less">
	.card {
		&.img-btn.active .img-btn-border {
			@apply border-4 border-p-green shadow-xl shadow-gray-100;
		}
	}
</style>
