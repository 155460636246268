Container to group questions
<template>
	<div class='question-set pl-4 pr-4 flex flex-col items-center justify-center'>
		<h1 class='max-w-screen-2xl text-2xl font-serif lg:text-5xl lg:font-bold text-center'>
			<slot name='question'></slot>
		</h1>
		<h3
			v-if='$slots.subtitle'
			class='hidden max-w-screen-2xl lg:block text-lg lg:text-2xl text-center mt-6'
		>
			<slot name='subtitle'></slot>
		</h3>
	</div>
</template>

<script>

export default {
	name: 'QuestionSet',
	props:
	{
	},
	computed:
	{
	},
}

</script>

<style scoped lang="css">
	.question-set {
	}
</style>
