import { isString } from "@vue/shared"
import { DateTime } from "luxon";

// Source: https://www.emailregex.com/
const email = (value) => {
	if (!isString(value)) {
		return false
	}

	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)
}

/**
 * Validate a USA phone number
 */
const phone = (value) => {
	if (!isString(value)) {
		return false
	}

	const numbersOnly = value.replace(/\D/g, '', )
	return /^1?[2-9][0-9]{9}$/.test(numbersOnly)
}

/**
 * @returns {Boolean} true if the value is in the provided range
 */
const rangeInclusive = (value, min, max) => {
	const parsed = Number(value)
	return !isNaN(parsed) && value >= min && value <= max
}

/**
 * @returns {Boolean} true if the value is a five digit string
 */
const zip = (value) => {
	return isString(value) && /^\d{5}$/.test(value)
}

const birthdate = (y, m, d) => {
	// If totally empty accept
	if (!y && !m && !d) {
		return true
	}

	// Reject non-values
	if (!y || !m || !d) {
		return false
	}

	const date = DateTime.fromObject({day: d, month: m, year: y})
	if (!date.isValid) {
		return false
	}

	// Reject older than 120 yrs
	const timeAgo = date.diffNow('year')
	if (timeAgo.years < -120) {
		return false
	}

	// Reject future date
	if (timeAgo > 0) {
		return false
	}

	// Passed all checks
	return true
}

export default {
	birthdate,
	email,
	phone,
	rangeInclusive,
	zip,
}
