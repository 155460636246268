An svg of a spinning icon to indicate loading
<template>
	<svg
		class='spinner'
		fill='none'
		height='65'
		viewBox='0 0 64 65'
		width='64'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clip-path='url(#clip0_2_3246)'>
			<g opacity='0.998'>
				<path
					:class='getClasses(12)'
					d='M31.9992 11.7945C31.5994 11.8019 31.2023 11.7274 30.8315 11.5755C30.4607 11.4235 30.1238 11.1972 29.8411 10.91C29.5583 10.6228 29.3355 10.2807 29.1859 9.90415C29.0363 9.52755 28.9629 9.12418 28.9702 8.71814V3.07635C28.9702 2.26032 29.2894 1.47771 29.8575 0.900696C30.4257 0.323677 31.1962 -0.000488281 31.9997 -0.000488281C32.8032 -0.000488281 33.5738 0.323677 34.1419 0.900696C34.71 1.47771 35.0292 2.26032 35.0292 3.07635V8.71814C35.0269 9.53347 34.7068 10.3147 34.1391 10.8911C33.5713 11.4676 32.802 11.7923 31.9992 11.7945Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(6)'
					d='M31.9992 65C31.5994 65.0075 31.2023 64.9329 30.8315 64.781C30.4607 64.629 30.1238 64.4027 29.8411 64.1156C29.5583 63.8284 29.3355 63.4863 29.1859 63.1097C29.0363 62.7331 28.9629 62.3297 28.9702 61.9237V56.2829C28.9702 55.4669 29.2894 54.6843 29.8575 54.1072C30.4257 53.5302 31.1962 53.2061 31.9997 53.2061C32.8032 53.2061 33.5738 53.5302 34.1419 54.1072C34.71 54.6843 35.0292 55.4669 35.0292 56.2829V61.9237C35.0366 62.3298 34.9632 62.7333 34.8135 63.1099C34.6638 63.4866 34.4409 63.8287 34.158 64.1159C33.8752 64.4031 33.5382 64.6294 33.1673 64.7813C32.7964 64.9332 32.3991 65.0076 31.9992 65Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(11)'
					d='M20.2582 14.8707C19.7262 14.8697 19.2038 14.7272 18.7431 14.4572C18.2823 14.1872 17.8993 13.7993 17.6322 13.3321L14.9052 8.5119C14.5037 7.80471 14.3953 6.96453 14.6037 6.17615C14.8121 5.38776 15.3203 4.71572 16.0165 4.30784C16.7127 3.89996 17.54 3.78962 18.3163 4.00111C19.0926 4.21259 19.7544 4.72857 20.1562 5.43558L22.8832 10.2557C23.2689 10.9666 23.3686 11.8015 23.1615 12.5853C22.9544 13.3692 22.4564 14.0412 21.7722 14.4604C21.3016 14.7041 20.7857 14.844 20.2582 14.8707Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(5)'
					d='M46.4668 61.102C45.9348 61.101 45.4124 60.9584 44.9517 60.6884C44.4909 60.4185 44.1079 60.0305 43.8408 59.5633L41.1138 54.7431C40.9148 54.393 40.7857 54.0064 40.7338 53.6056C40.682 53.2047 40.7084 52.7975 40.8115 52.407C40.9146 52.0165 41.0925 51.6504 41.3349 51.3298C41.5773 51.0091 41.8795 50.74 42.2243 50.5379C42.569 50.3359 42.9496 50.2048 43.3443 50.1521C43.739 50.0994 44.14 50.1262 44.5245 50.2309C44.909 50.3357 45.2694 50.5163 45.5851 50.7625C45.9009 51.0087 46.1658 51.3156 46.3648 51.6658L49.0918 56.486C49.4775 57.1968 49.5772 58.0317 49.3701 58.8156C49.163 59.5994 48.6651 60.2715 47.9808 60.6907C47.5239 60.9688 46.9994 61.1113 46.4668 61.102Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(10)'
					d='M11.6099 23.6529C11.0774 23.6639 10.5526 23.5232 10.0949 23.2467L5.3489 20.4771C4.65278 20.069 4.14473 19.3969 3.9365 18.6084C3.72827 17.82 3.83691 16.9798 4.23852 16.2727C4.64012 15.5657 5.30182 15.0495 6.07808 14.8378C6.85434 14.6262 7.68159 14.7363 8.3779 15.144L13.1239 17.9136C13.8082 18.3328 14.3061 19.0048 14.5132 19.7887C14.7204 20.5726 14.6206 21.4075 14.2349 22.1183C13.9674 22.5846 13.5843 22.9717 13.1237 23.2409C12.6632 23.5101 12.1413 23.6522 11.6099 23.6529Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(4)'
					d='M57.2297 50.2703C56.6973 50.2813 56.1724 50.1406 55.7147 49.8641L50.8677 47.0945C50.5228 46.8925 50.2205 46.6236 49.978 46.303C49.7354 45.9823 49.5575 45.6163 49.4543 45.2258C49.351 44.8353 49.3246 44.428 49.3763 44.0271C49.4281 43.6263 49.5572 43.2397 49.7561 42.8894C49.955 42.5392 50.2199 42.2322 50.5357 41.986C50.8514 41.7398 51.2118 41.5591 51.5963 41.4543C51.9808 41.3496 52.3819 41.3228 52.7766 41.3755C53.1713 41.4282 53.5519 41.5593 53.8967 41.7614L58.6427 44.531C59.327 44.9502 59.8249 45.6223 60.032 46.4061C60.2392 47.19 60.1394 48.0249 59.7537 48.7357C59.5167 49.2059 59.1545 49.599 58.7082 49.8704C58.262 50.1417 57.7497 50.2802 57.2297 50.2703Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(9)'
					d='M8.58352 35.5774H3.02852C2.22505 35.5774 1.45448 35.2532 0.886343 34.6762C0.318202 34.0992 -0.000976562 33.3166 -0.000976562 32.5006C-0.000976562 31.6845 0.318202 30.9019 0.886343 30.3249C1.45448 29.7479 2.22505 29.4237 3.02852 29.4237H8.58352C8.98331 29.4163 9.38048 29.4908 9.75128 29.6427C10.1221 29.7947 10.4589 30.021 10.7417 30.3082C11.0244 30.5954 11.2472 30.9374 11.3969 31.314C11.5465 31.6906 11.6198 32.094 11.6125 32.5001C11.6104 33.3154 11.2906 34.0967 10.7231 34.6733C10.1555 35.25 9.38631 35.575 8.58352 35.5774Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(3)'
					d='M60.9702 35.5774H55.4162C54.6128 35.5774 53.8422 35.2532 53.274 34.6762C52.7059 34.0992 52.3867 33.3166 52.3867 32.5005C52.3867 31.6845 52.7059 30.9019 53.274 30.3249C53.8422 29.7479 54.6128 29.4237 55.4162 29.4237H60.9702C61.7737 29.4237 62.5443 29.7479 63.1124 30.3249C63.6805 30.9019 63.9997 31.6845 63.9997 32.5005C63.9997 33.3166 63.6805 34.0992 63.1124 34.6762C62.5443 35.2532 61.7737 35.5774 60.9702 35.5774Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(8)'
					d='M6.76585 50.2704C6.23391 50.2694 5.71151 50.1268 5.25075 49.8568C4.78999 49.5869 4.40696 49.1989 4.13985 48.7317C3.75415 48.0209 3.6544 47.186 3.86153 46.4021C4.06865 45.6182 4.56656 44.9462 5.25085 44.527L9.99685 41.7574C10.3416 41.5553 10.7223 41.4242 11.117 41.3715C11.5117 41.3188 11.9127 41.3456 12.2972 41.4503C12.6817 41.5551 13.0421 41.7357 13.3579 41.982C13.6737 42.2282 13.9385 42.5352 14.1375 42.8854C14.3364 43.2356 14.4654 43.6222 14.5172 44.0231C14.569 44.424 14.5425 44.8313 14.4393 45.2218C14.3361 45.6123 14.1581 45.9783 13.9156 46.2989C13.6731 46.6196 13.3707 46.8885 13.0259 47.0905L8.27985 49.8601C7.83352 50.1626 7.30126 50.3068 6.76585 50.2704Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(2)'
					d='M52.387 23.653C51.855 23.652 51.3326 23.5094 50.8718 23.2394C50.4111 22.9694 50.0281 22.5815 49.7609 22.1143C49.3752 21.4035 49.2755 20.5686 49.4826 19.7847C49.6897 19.0008 50.1876 18.3288 50.8719 17.9096L55.6179 15.14C55.9627 14.9379 56.3434 14.8067 56.7381 14.754C57.1328 14.7013 57.5338 14.7281 57.9183 14.8329C58.3028 14.9376 58.6632 15.1183 58.979 15.3645C59.2947 15.6108 59.5596 15.9178 59.7586 16.268C59.9575 16.6182 60.0865 17.0048 60.1383 17.4057C60.1901 17.8066 60.1636 18.2139 60.0604 18.6044C59.9572 18.9949 59.7792 19.3609 59.5367 19.6815C59.2941 20.0021 58.9918 20.2711 58.6469 20.473L53.9009 23.2426C53.4439 23.5204 52.9195 23.6625 52.387 23.653Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(7)'
					d='M17.5325 61.102C17 61.1131 16.4751 60.9723 16.0175 60.6958C15.3332 60.2766 14.8353 59.6046 14.6281 58.8207C14.421 58.0368 14.5207 57.2019 14.9065 56.4911L17.6335 51.6709C18.0352 50.9639 18.697 50.4479 19.4733 50.2365C20.2497 50.025 21.0769 50.1353 21.7731 50.5432C22.4693 50.9511 22.9775 51.6231 23.1859 52.4115C23.3943 53.1999 23.2859 54.04 22.8845 54.7472L20.1575 59.5674C19.8899 60.0337 19.5068 60.4207 19.0463 60.69C18.5858 60.9592 18.0638 61.1013 17.5325 61.102Z'
					opacity='0.998'
				/>
				<path
					:class='getClasses(1)'
					d='M43.7405 14.8708C43.208 14.8818 42.6832 14.7411 42.2255 14.4646C41.5412 14.0454 41.0433 13.3734 40.8361 12.5895C40.629 11.8056 40.7288 10.9707 41.1145 10.2599L43.8414 5.43973C44.2432 4.73273 44.905 4.21674 45.6814 4.00526C46.4577 3.79377 47.2849 3.90411 47.9811 4.31199C48.6773 4.71987 49.1855 5.39191 49.3939 6.1803C49.6023 6.96868 49.4939 7.80886 49.0925 8.51606L46.3655 13.3362C46.0816 13.7874 45.6948 14.1625 45.2378 14.4297C44.7809 14.6968 44.2672 14.8481 43.7405 14.8708Z'
					opacity='0.998'
				/>
			</g>
		</g>
		<defs>
			<clipPath id='clip0_2_3246'>
				<rect
					fill='white'
					height='65'
					width='64'
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script>

export default {
	name: 'Spinner',
	props:
	{
		pauseSpin:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	data()
	{
		return {
			currentIndex: 1,
			maxIndex: 12,
			spinInterval: false,
			spinSpeed: 200,
		}
	},
	watch:
		{
			/**
			* When pause-spin property changes, ensure the spinning is respected
			*/
			pauseSpin: function()
			{
				if (this.pauseSpin)
				{
					this.stopSpinning()
				}
				else
				{
					this.startSpinning()
				}
			},

			/**
			* When spin speed changes we need to stop and restart the spin process
			*/
			spinSpeed:  function()
			{
				this.stopSpinning()
				this.startSpinning()
			},
		},
	mounted() {
		if (!this.pauseSpin)
		{
			this.startSpinning()
		}
	},
	methods:
	{
		getClasses(index)
		{
			let on = false;
			if (index === this.currentIndex)
			{
				on = true;
			}

			return {
				'on': on,
				'off': !on,
			}
		},
		startSpinning()
		{
			this.spinInterval = window.setInterval(() => {
				if (this.currentIndex === this.maxIndex)
				{
					this.currentIndex = 1
				}
				else
				{
					this.currentIndex++
				}
			}, this.spinSpeed);
		},
		stopSpinning()
		{
			window.clearInterval(this.spinInterval)
		},
	}
}

</script>

<style scoped lang="less">
	.spinner {
		flex-grow: 1;
		.on {
			fill: #BFD8E5;
		}
		.off {
			fill: #006699;
		}
	}
</style>
