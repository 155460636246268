<template>
	<div class='relative'>
		<button
			class='w-full h-full border-2 border-slate-200 rounded-lg p-4 text-center text-p-blue-300 font-semibold hover:bg-slate-100'
			:class='[
				isActive && "border-blue-400 bg-slate-100",
				disabled && "border-slate-100 opacity-30 cursor-not-allowed hover:bg-white"
			]'
			@click='onClick'
		>
			<div class="w-full h-full">
				<slot :active='isActive' :disabled='disabled'></slot>
			</div>
		</button>
		<div
			v-if='isActive'
			class='absolute top-[-13px] right-[-13px]'
		>
			<img src="@/assets/checkmark-circle.svg" alt="checkmark" class="w-[26px] h-[26px]" />
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';


const props = defineProps({
	/** Parent can pass in active state directly to override modelValue===value logic */
	active: Boolean,

	disabled: Boolean,

	/** The value of the variable controlled by the button */
	modelValue: {
		type: null,
		default: null,
	},

	/** True if the button should toggle modelValue between true and false instead of using value */
	toggle: Boolean,

	/** The value set by this button. Used if not in toggle mode */
	value: {
		type: null,
		default: undefined,
	},
})

const emit = defineEmits(['update:modelValue'])

const onClick = () =>
{
	if (props.disabled)
	{
		return
	}
	if (props.toggle)
	{
		emit('update:modelValue', !props.modelValue)
	}
	else if (props.value !== undefined)
	{
		emit('update:modelValue', props.value)
	}
}

/**
 * Determine if the button should be active
 */
const isActive = computed(
	// Manual override
	() => props.active ||

	// Variable matches this button's value (radio style)
	(props.value !== undefined && props.value === props.modelValue) ||

	// Checkbox style
	(props.toggle && props.modelValue))

</script>
