
import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useEstimateStore } from './estimate'
import { formatAsCurrency, formatKeys } from '../../../stringUtils'
import { Choice, Disposition, Service } from '../../../constants'
import Bundle from 'bundles/Bundle'

import traditionalBurial from 'bundles/burial/traditional'
import memorialBurial from 'bundles/burial/memorialWithoutVisitation'
import directBurial from 'bundles/burial/direct'

import traditionalCremation from 'bundles/cremation/traditional'
import memorialCremation from 'bundles/cremation/memorial'
import directCremation from 'bundles/cremation/direct'

export const useBundleStore = defineStore('bundle', () => {
	const estimate = useEstimateStore()

	const baseCost = computed(() => currentBundle.value.baseCost)

	const currentBundle = computed((): Bundle => {
		if (estimate.selectedDisposition === Disposition.Burial) {
			// Burial
			if (estimate.selectedTypeOfCelebration === Service.None)
			{
				return directBurial
			}

			if (estimate.selectedBodyPresent === Choice.No)
			{
				return memorialBurial
			}

			return traditionalBurial
		}

		// Cremation. Undecided also defaults to Cremation.
		if (estimate.selectedTypeOfCelebration === Service.None)
		{
			return directCremation
		}

		// Cremation w/ service
		if (estimate.selectedBodyPresent === Choice.Yes)
		{
			return traditionalCremation
		}

		return memorialCremation
	})

	const dispositionContainerName = computed(() => {
		switch (estimate.selectedDisposition) {
			case Disposition.Burial:
				return 'casket'
			case Disposition.Cremation:
				return 'urn'
			default:
				return 'casket or urn'
		}
	})

	/**
	 * Generate list of items included and excluded with the selected preneed plan.
	 * Maybe shouldn't be here?
	 */
	const planItems = computed(() => {
		const celebrationDescriptions = new Map<Service, string|false>()
		celebrationDescriptions.set(Service.Traditional, 'Service')
		celebrationDescriptions.set(Service.Celebration, 'Celebration of life')
		celebrationDescriptions.set(Service.None, false)
		celebrationDescriptions.set(Service.Undecided, 'Celebration of life')

		const params = {
			service: celebrationDescriptions.get(estimate.selectedTypeOfCelebration)
		}

		const bundle = currentBundle.value

		const includes = [
			formatAsCurrency(estimate.selectedDispositionBudget) + ' towards a ' + dispositionContainerName.value,
			...bundle.includes.map((s) => formatKeys(s, params))
		]

		const excludes = bundle.excludes.map((s) => formatKeys(s, params))


		// const stripEmpty = (list: Array<string|false>): Array<string> => list.filter((item) => !!item)

		return {
			includes,
			excludes,
		}
	})

	return {
		// Entire bundle w/ name provided for debugging
		currentBundle,

		baseCost,
		planItems,
	}
})
