<template>
	<main>
		<h1>Components</h1>

		<div class='components-view'>
			<h2>Helper icon:</h2>
			<Helper /> <br />

			<h2>Icons:</h2>
			<div class='icons flex flex-row flex-wrap'>
				<div>
					<ChevronIcon />
				</div>
				<div>
					<ChevronIcon direction='down' />
				</div>
				<div>
					<ChevronIcon direction='right' />
				</div>
			</div>

			<br />

			<h2>Big Card Icons:</h2>
			<div class='icons flex flex-row flex-wrap'>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<PersonAtDeskIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<PersonAtDeskIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<CandleIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<CandleIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<PersonQuestionMarkIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<PersonQuestionMarkIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<FlowerBoxIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<FlowerBoxIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<FlowerVaseIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<FlowerVaseIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<FamilyIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<FamilyIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<BalloonsIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<BalloonsIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<FlowersIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<FlowersIcon :active='true' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Inactive:</h3>
					<PersonVirtualMeetingIcon :active='false' />
				</div>
				<div class='grow basis-1/2'>
					<h3>Active</h3>
					<PersonVirtualMeetingIcon :active='true' />
				</div>
			</div>

			<br />

			<h2>Card Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					<h3>Inactive:</h3>
					<Card :active='false'>
						<template #icon>
							<PersonAtDeskIcon :active='false' />
						</template>
						<template #title>
							This is the Title of the Card inside the rectangle
						</template>
						<template #sub-title>
							A Sub-Title under the rectangle
						</template>
					</Card>
				</div>

				<div class='flex-auto'>
					<h3>Active</h3>
					<Card :active='true'>
						<template #icon>
							<PersonAtDeskIcon :active='true' />
						</template>
						<template #title>
							This is the Title of the Card inside the rectangle
						</template>
						<template #sub-title>
							A Sub-Title under the rectangle
						</template>
					</Card>
				</div>
			</div>

			<br />

			<h2>Next Button Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					<h3>Disabled</h3>
					<NextButton />
				</div>
				<div class='flex-auto'>
					<h3>Enabled</h3>
					<NextButton enabled />
				</div>
			</div>

			<br />

			<h2>Back Button Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					<BackButton />
				</div>
			</div>

			<br />

			<h2>Radio Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					<RadioOption
						name='test'
						label='Myself'
						value='1'
					/>
					<RadioOption
						name='test'
						label='A loved one'
						value='2'
					/>
				</div>
			</div>

			<h2>Pill Select Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					Required:
					<PillSelect
						v-model='pillValue'
						:options='["DISPLAY", "SCATTERING"]'
					/>
					<p>Value: {{ pillValue }}</p>

					Optional:
					<PillSelect
						v-model='pillValue2'
						optional
						:options='["Only one Option"]'
					/>
					<p>Value: {{ pillValue2 }}</p>
				</div>
			</div>

			<h2>Spinner Component:</h2>
			<div class='flex-it'>
				<div class='flex-auto'>
					<Spinner :pause-spin='!spin' />
					<button @click='spin=!spin'>
						Toggle Spin
					</button>
				</div>
				<div class='flex-auto'>
					<Spinner pause-spin />
				</div>
			</div>

			<h2>CheckboxInput Component:</h2>
			<CheckboxInput v-model='checkboxValue' />
			<CheckboxInput
				v-model='checkboxValue'
				large
			/>
			<div>
				The box is <span v-if='!checkboxValue'>not </span>checked.
			</div>

			<h2>CheckboxCard Component:</h2>
			<CheckboxCard
				v-model='checkboxCardValue'
				description='It wants to be picked.'
				name='Pick me'
			/>
			<CheckboxCard
				v-model='checkboxCardValue'
				description='This card is tied to the other.'
				name='Also pick me'
			/>
			<div>
				The card is <span v-if='!checkboxCardValue'>not </span>checked.
			</div>

			<h2>Checkable Button Component:</h2>
			<CheckableButton
				:active='activeCheckableButton === 1'
				class='w-32 h-12'
				@click='activeCheckableButton = 1'
			>
				Active one
			</CheckableButton>
			<CheckableButton
				:active='activeCheckableButton === 2'
				class='w-32 h-12 pt-4'
				@click='activeCheckableButton = 2'
			>
				Inactive one
			</CheckableButton>

			<CheckableButton
				disabled
				class='w-32 h-12 pt-4'
			>
				Disabled one
			</CheckableButton>

			<h2 class='pt-16'>
				Term Select Button Component:
			</h2>
			<div class='columns-3'>
				<TermSelectButton
					v-model='termLength'
					:years='10'
				/>
				<TermSelectButton
					v-model='termLength'
					:years='5'
				/>
				<TermSelectButton
					v-model='termLength'
					:years='0'
				/>
			</div>

			<h2>Funeral Home Contact Info Component:</h2>
			<FuneralHomeContactInfo
				address-line-1='6300 Forest Lawn Drive'
				address-line-2='Suite #100'
				city='Glendale'
				logo-url='https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Mountain_Dew_logo.svg/1280px-Mountain_Dew_logo.svg.png'
				name='Forest Lawn - Glendale'
				phone='888.888.8888'
				state='CA'
				zip='90068'
			/>

			<h2>LinkButton Component:</h2>
			<br />
			<div class='flex justify-evenly'>
				<LinkButton :to='{name: "home", params: {v: 1}}'>
					Internal Link
				</LinkButton>
				<LinkButton to='https://www.legacy.com'>
					External Link
				</LinkButton>
			</div>

			<h2>AddOnButton Component:</h2>
			<br />
			<div class='flex justify-between gap-4 flex-col sm:flex-row'>
				<AddOnButton
					name="Travel Protection"
					v-model="travelProtection">
					You might want travel protection if you travel a lot
				</AddOnButton>
				<AddOnButton
					name="Floral Arrangements"
					:price="280"
					:monthly-price="1.12"
					v-model="floralArrangements">
					Flowers smell nice and look nice, too
				</AddOnButton>
				<AddOnButton
					name="Grief Counseling"
					recommended
					v-model="griefCounseling">
					This is an option to enroll yourself in our new lead gen thing. We will try to connect you with other services. A price is not listed.
				</AddOnButton>
			</div>

			<h2>ToastInfo Component:</h2>
			<br />
			<ToastInfo title="Why is this my recommendation?">
				<template #trigger>
					Learn More
				</template>
				<template #content>
					Based on your selections, age, & location we recommend a $7,400 Final Expense insurance policy to help cover the cost of this funeral for your loved ones.
					<br />
					Final expense is an insurance policy that covers expenses related to a loss. These funds help your loved ones cover the cost of the funeral at the time of need.
				</template>
			</ToastInfo>
		</div>
	</main>
</template>

<script setup lang="ts">

import AddOnButton from '../versions/1/estimatePage/AddOnButton.vue'
import BackButton from 'components/BackButton.vue'
import BalloonsIcon from 'components/icons/BalloonsIcon.vue'
import CandleIcon from 'components/icons/CandleIcon.vue'
import Card from 'components/Card.vue'
import CheckableButton from 'components/CheckableButton.vue'
import CheckboxInput from 'components/CheckboxInput.vue'
import CheckboxCard from 'components/CheckboxCard.vue'
import ChevronIcon from 'components/icons/ChevronIcon.vue'
import FamilyIcon from 'components/icons/FamilyIcon.vue'
import FlowerBoxIcon from 'components/icons/FlowerBoxIcon.vue'
import FlowersIcon from 'components/icons/FlowersIcon.vue'
import FlowerVaseIcon from 'components/icons/FlowerVaseIcon.vue'
import Helper from 'components/Helper.vue'
import LinkButton from 'components/LinkButton.vue'
import NextButton from 'components/NextButton.vue'
import PersonAtDeskIcon from 'components/icons/PersonAtDeskIcon.vue'
import PersonQuestionMarkIcon from 'components/icons/PersonQuestionMarkIcon.vue'
import PersonVirtualMeetingIcon from 'components/icons/PersonVirtualMeetingIcon.vue'
import PillSelect from 'components/PillSelect.vue'
import RadioOption from 'components/RadioOption.vue'
import Spinner from 'components/Spinner.vue'
import TermSelectButton from '../versions/1/estimatePage/TermSelectButton.vue'
import ToastInfo from 'components/ToastInfo.vue'
import FuneralHomeContactInfo from 'components/FuneralHomeContactInfo.vue'

import { ref } from 'vue'

const activeCheckableButton = ref(1)
const checkboxCardValue = ref(false)
const checkboxValue = ref(false)
const floralArrangements = ref(false)
const griefCounseling = ref(false)
const pillValue = ref('DISPLAY')
const pillValue2 = ref('')
const spin = ref(true)
const termLength = ref(10)
const travelProtection = ref(false)

</script>

<style lang="less">
	body {
		overflow: auto;
	}
</style>

<style scoped lang="less">
	.components-view {
		height: 100%;
		text-align: left;
		width: 100%;

		.icons {
			> div {
				text-align: -webkit-center;
			}
		}

		.flex-it {
			@apply flex;
			> div {
				margin: 15px;
			}
		}

		h2 {
			font-size: 24px;
			font-weight: 700px;
			padding: 50px 0 10px 0;
		}

	}
</style>
