Molecular presentational component for displaying a toggleable choice.
The element is a label so that click events are natively passed to the checkbox.
<template>
	<label
		class='flex gap-4 rounded-lg border-2 p-4 cursor-pointer'
		:class='{ active: modelValue }'
	>
		<CheckboxInput
			class='shrink-0 h-8 w-8'
			:model-value='modelValue'
			@update:model-value='updateValue'
		/>
		<div class="text-left">
			<div class="text-xl font-bold font-sans">
				{{ name }}
			</div>
			<div class="text-sm leading-4">
				{{ description }}
			</div>
		</div>
	</label>
</template>

<script>

import CheckboxInput from './CheckboxInput.vue';

export default {
	name: "CheckboxCard",
	components:
	{
		CheckboxInput,
	},
	props:
	{
		description:
		{
			type: String,
			required: true,
		},
		modelValue: Boolean,
		name:
		{
			type: String,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	methods:
	{
		/**
		 * Emit the update event for the new value
		 */
		updateValue(newValue)
		{
			this.$emit('update:modelValue', newValue)
		},
	}
}
</script>

<style lang="css" scoped>
	.active {
		@apply border-p-green;
	}
</style>
