Displays a button that is actually a link
<template>
	<a
		v-if='isExternalLink'
		class='link'
		v-bind='$attrs'
		:href='to'
		target='_blank'
	>
		<slot></slot>
	</a>
	<RouterLink
		v-else
		v-slot='{ href, navigate }'
		v-bind='$props'
		custom
	>
		<a
			class='link'
			v-bind='$attrs'
			:href='href'
			@click='navigate'
		>
			<slot></slot>
		</a>
	</RouterLink>
</template>

<script setup>
import { computed } from "vue"
import { RouterLink } from 'vue-router'

const props = defineProps(RouterLink.props)

const isExternalLink = computed(() =>
	typeof props.to === 'string' && props.to.startsWith('http')
)

</script>

<style scoped>
	.link {
		@apply
			block text-center
			bg-p-green-400 pt-4 pb-4 pl-8 pr-8 rounded-xl
			text-white text-xl font-sans font-bold uppercase tracking-wide;
	}
</style>
