<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel('preNeedBodyPresent', 'title') }}
			</template>
		</QuestionSet>

		<Cards class='cards-spacer'>
			<Card
				:active='selectedBodyPresent === Choice.Yes'
				@click='selectedBodyPresent = Choice.Yes'
			>
				<template #icon>
					<FlowerBoxIcon :active='selectedBodyPresent === Choice.Yes' />
				</template>
				<template #title>
					Yes
				</template>
				<template #sub-title>
					My loved ones will want to say  their final goodbyes.
				</template>
			</Card>
			<Card
				:active='selectedBodyPresent === Choice.No'
				@click='selectedBodyPresent = Choice.No'
			>
				<template #icon>
					<FlowersIcon :active='selectedBodyPresent === Choice.No' />
				</template>
				<template #title>
					No
				</template>
				<template #sub-title>
					It’s not for us.
				</template>
			</Card>
			<Card
				:active='selectedBodyPresent === Choice.Undecided'
				@click='selectedBodyPresent = Choice.Undecided'
			>
				<template #icon>
					<FamilyIcon :active='selectedBodyPresent === Choice.Undecided' />
				</template>
				<template #title>
					Let loved ones decide
				</template>
				<template #sub-title>
					I'd like to have it in my budget in case my loved ones want this option.
				</template>
			</Card>
		</Cards>
	</FlowScreen>
</template>

<script>

import { Actions, Errors, Main } from '../lang'
import { Choice } from '../../../constants'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FamilyIcon from 'components/icons/FamilyIcon.vue'
import FlowerBoxIcon from 'components/icons/FlowerBoxIcon.vue'
import FlowersIcon from 'components/icons/FlowersIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'PreNeedBodyPresent',
	components:
	{
		Card,
		Cards,
		FamilyIcon,
		FlowerBoxIcon,
		FlowersIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/** Generic choice type */
			Choice,

			/**
			* This page/screen name
			*/
			screenName: 'preNeedBodyPresent',

			/**
			* Version level constants / string labels
			*/
			constants: Main,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.selectedBodyPresent === Choice.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['selectedBodyPresent'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			if (this.selectedBodyPresent === Choice.Yes)
			{
				this.$emit('navigate', "preNeedBodyPresentAffirm")
			}
			else if (this.selectedBodyPresent === Choice.No)
			{
				this.$emit('navigate', "preNeedNoBodyPresentAffirm")
			}
			else
			{
				this.$emit('navigate', "preNeedFamilyDecideBodyAffirm")
			}
		},
	}
}

</script>
