<template>
	<main>
		<!-- NOTE: THERE ARE CURRENTLY 2 NAV LOGOS AND 2 TS&CS ELEMENTS. ANY CHANGES NEED TO BE MADE FOR AND CHECKED ON MOBILE AND DESKTOP SIZED SCREENS -->
		<!-- NEEDS REFACTOR TO ELIMINATE DOUBLE IMAGE AND PRIVACY -->
		<nav class='flex flex-col flex-col-reverse  p-0 sm:flex-row sm:text-left sm:justify-between sm:py-8 sm:px-12 bg-white shadow sm:items-center w-full'>
			<img
				v-if='legacy'
				class='hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
				src='@/assets/legacy_logo.png'
			/>
			<img
				v-else
				class='hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[50px] lg:w-[162px]'
				src='@/assets/preplan_today_logo.svg'
			/>

			<div class="sm:hidden flex ml-6 mr-6 mt-2 items-center justify-between">
				<img
					v-if='legacy'
					class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
					src='@/assets/legacy_logo.png'
				/>
				<img
					v-else
					class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
					src='@/assets/preplan_today_logo.svg'
				/>

				<div class=" whitespace-nowrap text-[10px] text-right  sm:block w-[100px] text-slate-500"><a href="/terms" target="_blank">Terms</a> | <a href="/privacy" target="_blank">Privacy</a></div>
			</div>
			<ProgressBar
				class='sm:pl-4 sm:pr-4 w-full'
				:value='progress'
			/>

			<div class="whitespace-nowrap text-[10px] text-right  sm:text-xs hidden sm:block w-[100px] lg:h-[30px] lg:w-[162px] text-slate-500 lg:pt-2"><a href="/terms" target="_blank">Terms</a> | <a href="/privacy" target="_blank">Privacy</a></div>
		</nav>

		<Helper class="pt-2 pb-4" />

		<component
			:is='version'
			@progress-change='onProgressChange'
		/>
	</main>
</template>

<script setup>


import v1 from '@/versions/1/MainPage.vue'
// import Version2 from '@/versions/2/MainPage.vue'

import Helper from 'components/Helper.vue'
import ProgressBar from '../components/ProgressBar.vue';

</script>

<script>

export default
{
	name: 'HomeView',
	components:
	{
		Helper,
		ProgressBar,
		v1,
		// Version2
	},
	props: {
		v: {
			default: 1,
			type: String,
		},
	},
	data()
	{
		return {
			// false if default logo, true if Legacy logo
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),
			progress: 0,
		}
	},
	computed:
	{
		version() {
			const v = ([1].indexOf(this.v) > -1) ? this.v : 1
			return 'v' + v
		}
	},
	methods: {
		onProgressChange(newValue) {
			this.progress = newValue;
		},
	},
}
</script>

<style scoped lang="less">

</style>
