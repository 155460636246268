<template>
	<div>
		<!-- Section needs some style work -->
		<QuestionSet>
			<template #question>
				<div class='max-w-screen-lg'>
					<span v-if='showingFuneralHome'>
						Based on your location, we recommend contacting {{ funeralHome.name }}
					</span>
					<span v-else>
						We estimate your preferred funeral would cost about
						<span class='text-p-gold-400 font-semibold underline underline-offset-3'>{{ currency(calculator.policyAmount) }}</span> (based on regional averages)
					</span>
				</div>
			</template>
			<template #subtitle>
				<div class='max-w-screen-lg' v-if='showingFuneralHome'>
					We estimate your preferred funeral would cost about
					<span class='text-p-gold-400 font-semibold underline underline-offset-3'>{{ currency(calculator.policyAmount) }}</span> (based on regional averages)
				</div>
			</template>
		</QuestionSet>

		<div class='sm:flex sm:justify-center mt-10'>
			<div class='grid sm:grid-cols-3 sm:gap-4 w-full max-w-screen-2xl'>
				<div class='sm:col-span-2 order-2 sm:order-1'>

					<div class='grid gap-4 grid-cols-1 text-left '>
						<AdjustCoverage />

						<div class='border-2 p-5 md:p-8 rounded-2xl'>
							<div class="text-2xl font-serif text-gray-900 font-normal pb-1">
								Commonly Purchased With Your Plan
							</div>
							<p>Select any add-ons you'd like to budget for.</p>
							<div class='grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-5 auto-rows-ft mt-8'>
								<AddOnButton
									v-for="addOn in AddOnList"
									v-model='estimate[addOn.key]'
									:key="addOn.key"
									:recommended="addOn.recommended"
									:price="addOn.price"
									:single-pay="addOn.singlePayOnly"
									:name='addOn.name'
								>
									{{ addOn.detail }}
								</AddOnButton>
							</div>
						</div>
					</div>
				</div>

				<aside class='sm:sticky top-4 self-start sm:col-span-1 order-1 sm:order-2'>
					<div class='hidden sm:block bg-p-green-100 border-2 rounded-2xl border-p-green'>
						<div class="p-4 lg:p-8 xl:p-12">
							<div class='text-5xl font-black  lg:text-7xl text-center text-p-green font-medium font-serif mt-4 mb-6'>
								{{ currency(calculator.policyAmount) }}
							</div>
							<p class="text-2xl text-p-gold text-center italic">Estimated Funeral Cost</p>
						</div>
						<div class="p-4 lg:p-8 -mt-4 md:-mt-8">
							<LinkButton v-if="showingFindAFuneralHome" to="https://www.legacy.com/funeral-homes/" class="center">
								Find a funeral home
							</LinkButton>
						</div>
						<img class='' src='@/assets/flowers-footer.png'/>
					</div>
					<FuneralHomeContactInfo
						class="mt-4"
						v-if='showingFuneralHome'
						:address-line-1='funeralHome.addressLine1'
						:address-line-2='funeralHome.addressLine2'
						:city='funeralHome.city'
						:logo-url='funeralHome.logoUrl'
						:name='funeralHome.name'
						:phone='funeralHome.phone'
						:state='funeralHome.state'
						:zip='funeralHome.zip'
					/>
				</aside>

				<footer class='z-50 w-full border-t-2 sm:border-2 p-4 lg:p-8 fixed bottom-0  bg-p-green-100 border-p-green
					flex sm:hidden justify-between flex-col
					sm:static lg:flex-row-reverse sm:rounded-2xl lg:mb-8'>

					<div class='flex mb-4 text-p-green lg:mb-0 justify-center '>
						<div class="text-black pr-6 text-lg self-center">Estimated funeral cost:</div>

						<div class="self-end lg:self-center font-semibold text-4xl lg:text-5xl">
							<span class="font-serif">{{ currency(calculator.policyAmount) }}</span>
						</div>
					</div>

					<LinkButton v-if="showingFindAFuneralHome" to="https://www.legacy.com/funeral-homes/" class="w-full h:w-fit lg:w-96">
						Find a funeral home
					</LinkButton>
				</footer>
			</div>
		</div>
	</div>
</template>

<script lang="ts">

import { Caskets, Urns } from '../products'
import { formatAsCurrency } from '../../../stringUtils'
import { useCalculatorStore } from '../store/calculator'
import { useEstimateStore } from '../store/estimate'
import { useFhStore } from '../store/funeralHome'
import AddOnButton from './AddOnButton.vue'
import { atNeedAddOns } from '@/intel/addOns'
import AdjustCoverage from './AdjustCoverage.vue'
import FuneralHomeContactInfo from 'components/FuneralHomeContactInfo.vue'
import LinkButton from 'components/LinkButton.vue'
import ProductBudgetSlider from 'components/ProductBudgetSlider.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedEstimatePage',
	components:
	{
		AddOnButton,
		AdjustCoverage,
		FuneralHomeContactInfo,
		LinkButton,
		ProductBudgetSlider,
		QuestionSet,
	},
	setup() {
		return {
			calculator: useCalculatorStore(),
			estimate: useEstimateStore(),
			funeralHome: useFhStore(),
		}
	},
	data() {
		return {
			AddOnList: atNeedAddOns,

			/**
			 * Define which casket options there are;
			 * Imported from products.js
			 */
			caskets: Caskets,


			/**
			 * Define which cite we are on based on env file;
			 * false for default, true for Legacy
			 */
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),

			/**
			 * Define which urn options there are;
			 * Imported from products.js
			 */
			urns: Urns,
		}
	},
	computed:
	{
		// Link to legacy's funeral home finder if no funeral home attached and in legacy env
		showingFindAFuneralHome()
		{
			return !this.funeralHome.id && this.legacy
		},
		showingFuneralHome()
		{
			return this.estimate.visibleFh
		},
	},
	methods:
	{
		currency: formatAsCurrency,
	},
}

</script>
