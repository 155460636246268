Pill Selector for single or multiple selections
<template>
	<div class='pill-select'>
		<div
			v-for='(label, index) in options'
			:key='index'
		>
			<div
				class='option'
				:class='getClasses(index, label)'
				@click='handleInput(label)'
			>
				<p>{{ label }}</p>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'PillSelect',
	props:
	{
		modelValue:
		{
			default: '',
			required: false,
			type: String
		},
		options:
		{
			default: [],
			required: true,
			type: Array
		},
		optional:
		{
			type: Boolean,
			default: false,
			required: false,
		},
	},
	data () {
		return {
			content: ''
		}
	},
	computed:
	{

	},
	methods: {
		handleInput (value) {
			if (this.optional === true && this.isSelected(value) === true) {
				this.$emit('update:modelValue', '')
			}
			else
			{
				this.$emit('update:modelValue', value)
			}
		},
		isSelected (option)
		{
			return option === this.modelValue
		},
		getClasses(index, label)
		{
			return {
				'active': this.isSelected(label),
				'only-child': this.options.length === 1,
				'first-child': index === 0,
				'last-child': index + 1 === this.options.length,
			}
		}
	},
}

</script>

<style scoped lang="less">

	.pill-select {

		display: flex;

		.option {
			padding: 8px;
			background: #EEEEEE;
			border: 0.74812px solid #006699;
			color: #404F57;
			min-width: 50px;
			text-align: center;
			user-select: none;

			&.first-child {
				border-radius: 25.4361px 0 0 25.4361px;
			}

			&.last-child {
				border-radius: 0 25.4361px 25.4361px 0;
			}

			&.only-child {
				border-radius: 25.4361px 25.4361px 25.4361px 25.4361px;
			}

			&.active {
				background: #006699;
				color: #FFFFFF;
				margin-left: -1px;
			}
		}
	}

</style>
