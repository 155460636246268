<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<div class='flex max-w-xl w-full justify-center mx-auto flex-col p-5'>
			<div class='mt-4 lg:mt-8'>
				<QuestionSet>
					<template #question>
						{{ labelActions.getLabel(screenName, 'planningForLabel') }}
					</template>
				</QuestionSet>

				<div class='mt-8 grid grid-cols-2 gap-4'>
					<TextInput
						v-model='planningForFirstName'
						autocomplete='off'
						img-person
						label='First Name'
						name='firstname'
						type='text'
					/>
					<TextInput
						v-model='planningForLastName'
						autocomplete='off'
						label='Last Name'
						name='lastname'
						type='text'
					/>
				</div>

				<QuestionSet>
					<template #question>
						{{ labelActions.getLabel(screenName, 'entryNameLabel') }}
					</template>
				</QuestionSet>

				<div class='mt-8 grid grid-cols-2 gap-4'>
					<TextInput
						v-model='informantFirstName'
						img-person
						label='First Name'
						name='firstname'
						type='text'
					/>
					<TextInput
						v-model='informantLastName'
						label='Last Name'
						name='lastname'
						type='text'
					/>
				</div>
			</div>
		</div>
	</FlowScreen>
</template>

<script>

import { Actions } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import QuestionSet from 'components/QuestionSet.vue'
import TextInput from 'components/TextInput.vue'
import FlowScreen from '../FlowScreen.vue'

export default {
	name: 'AtNeedWho',
	components:
	{
		FlowScreen,
		QuestionSet,
		TextInput
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedWho',
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.planningForFirstName == '')
			{
				errors.push('Please enter the first name of the person who is being planned for.')
			}
			if (this.planningForLastName == '')
			{
				errors.push('Please enter the last name of the person who is being planned for.')
			}
			if (this.informantFirstName == '')
			{
				errors.push('Please enter your first name.')
			}
			if (this.informantLastName == '')
			{
				errors.push('Please enter your last name.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, [
			'informantFirstName',
			'informantLastName',
			'planningForFirstName',
			'planningForLastName',
		])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "atNeedWhoAffirm")
		},
	},
}

</script>
