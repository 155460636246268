Presentational component to show a notification that pops up from the bottom of the screen.
Hard-coded to come in above the fixed footer on the estimate page.
<template>
	<div @click="visible = true">
		<slot name="trigger"></slot>
	</div>
	<Transition>
		<div
			v-if="visible"
			@click="visible = false"
			class='fixed top-0 left-0 right-0 bottom-0 z-20 backdrop-blur-sm bg-white/20'>
		</div>
	</Transition>
	<Transition>
		<div
		v-if="visible"
		@click="visible = false"
		class='toast-info fixed left-0 right-0 pl-6 pr-6 pb-6 pt-11 bg-white rounded-xl rounded-b-none z-30 drop-shadow-[0_-4px_8px_rgb(0,0,0,0.12)]'>
			<img class='absolute top-4 right-4' src='@/assets/caret.svg'/>
			<h4 class='font-serif text-xl text-gray-900'>{{ title }}</h4>
			<slot name="content"></slot>
		</div>
	</Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: "ToastInfo",
	props: {
		title: String,
	},
	data() {
		return {
			visible: false,
		}
	},
})
</script>

<style lang="less" scoped>

.toast-info {
	bottom: 128px;
}

.toast-info.v-enter-active {
	transition: bottom 0.5s ease-out;
}

.toast-info.v-leave-active {
	transition: bottom 0.5s ease-in;
}

.toast-info.v-leave-to, .v-enter-from {
	bottom: -325px;
}
</style>
