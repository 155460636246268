Atomic component for a rounded square checkbox
<template>
	<input
		:checked='modelValue'
		class='appearance-none
		p-px w-7 h-7 border border-p-gold rounded cursor-pointer
		checked:bg-p-gold checked:bg-checkmark checked:bg-[length:50%_50%]
		bg-no-repeat bg-center bg-clip-content bg-origin-content'
		type='checkbox'
		@change="$emit('update:modelValue', $event.target.checked)"
	/>
</template>

<script>

export default {
	name: "CheckboxInput",
	props: {
		modelValue: Boolean,
	},
	emits: ['update:modelValue'],
}
</script>
