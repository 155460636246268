<template>
	<div class='bg-p-green-100 rounded-2xl border-2 border-p-green p-4 lg:p-8 xl:p-10'>
		<img
			:src='logoUrl'
			width='172'
		/>
		<h1 class='text-3xl font-serif pb-2'>
			{{ name }}
		</h1>

		<ul class="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
			<li v-if="phone">
				<a
					class='link'
					data-testid="phone-link"
					:href='phoneHref'
				>
					<img src="@/assets/phone.svg" class="mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" />
					{{ phone }}
				</a>
			</li>
			<li>
				<a
					class='link'
					target="_blank"
					data-testid="map-link"
					:href='mapsHref'
				>
					<img src="@/assets/location-pin.svg" class="mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" />
					{{ addressLine1 }} <br />
					{{ addressLine2 }} <br v-if='addressLine2' />
					{{ addressBottomLine }}
				</a>
			</li>
			<li v-if="websiteUrl">
				<a
					class='link'
					:href='websiteUrl'
				>
					<img src="@/assets/web.svg" class="mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0" />
					Visit website
				</a>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
	addressLine1: {
		type: String,
		default: null,
	},
	addressLine2: {
		type: String,
		default: null,
	},
	city: {
		type: String,
		default: null,
	},
	logoUrl: {
		type: String,
		default: null,
	},
	name: {
		type: String,
		required: true,
	},
	phone: {
		type: String,
		default: null,
	},
	state: {
		type: String,
		default: null,
	},
	zip: {
		type: String,
		default: null,
	},
	websiteUrl: {
		type: String,
		default: null,
	},
})

const phoneHref = computed(() => 'tel:' + props.phone)
const addressBottomLine = computed(() => {
	return props.city + ', ' + props.state + ' ' + props.zip
})

// URL to open the FH in Google Maps
const mapsHref = computed(() => {
	const address = [
		props.name,
		props.addressLine1,
		props.addressLine2,
		props.city,
		props.state,
		props.zip,
	].filter((part) => !!part).join(' ')

	return 'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address)
})

</script>

<style scoped>
	.link {
		@apply text-lg flex items-start text-p-gold-400 block hover:underline cursor-pointer pt-4;
	}

	.link img {
		@apply mt-0.5;
	}
</style>
