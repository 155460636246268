<template>
	<div
		class='main-page'
		:class="{'transition-reverse': navigatingBack}"
		v-if="ready"
	>
		<!-- Pre Plan -->
		<Transition>
			<aside
				v-if="(activeScreen==='preNeedAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedWho'
					name='preNeedAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen==='preNeedWhoAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedProduct'
					name='preNeedWhoAffirm'
					:title-params='[store.informantFirstName]'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedZipAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedProductBudget'
					name='preNeedZipAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedProductBudgetAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedService'
					name='preNeedProductBudgetAffirm'
					:title-params='[dispositionName]'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedServiceAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedBodyPresent'
					name='preNeedServiceAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedNoServiceAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedContactInformation'
					name='preNeedNoServiceAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedFamilyDecideServiceAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedContactInformation'
					name='preNeedFamilyDecideServiceAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedBodyPresentAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedContactInformation'
					name='preNeedBodyPresentAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedNoBodyPresentAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedContactInformation'
					name='preNeedNoBodyPresentAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedFamilyDecideBodyAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='preNeedContactInformation'
					name='preNeedFamilyDecideBodyAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'preNeedFinishAffirm')"
				class='content'
			>
				<AffirmPage
					:job='createEstimate'
					name='preNeedFinishAffirm'
					:title-params='[store.informantFirstName]'
				/>
			</aside>
		</Transition>

		<!-- At Need -->
		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedWho'
					name='atNeedAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedWhoAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedProduct'
					name='atNeedWhoAffirm'
					:title-params='[store.informantFirstName]'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedZipAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedProductBudget'
					name='atNeedZipAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedProductBudgetAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedService'
					name='atNeedProductBudgetAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedServiceAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedBodyPresent'
					name='atNeedServiceAffirm'
					:title-params='[store.planningForFirstName]'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedNoServiceAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedContactInformation'
					name='atNeedNoServiceAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedBodyPresentAffirm')"
				class='content'
			>
				<AffirmPage
					auto-next='atNeedContactInformation'
					name='atNeedBodyPresentAffirm'
					@navigate='navigate($event, false)'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedServicesAffirm')"
				class='content'
			>
				<AffirmPage
					:job='createEstimate'
					name='atNeedServicesAffirm'
				/>
			</aside>
		</Transition>

		<Transition>
			<aside
				v-if="(activeScreen === 'atNeedNoServicesAffirm')"
				class='content'
			>
				<AffirmPage
					:job='createEstimate'
					name='atNeedNoServicesAffirm'
				/>
			</aside>
		</Transition>

		<Transition>
			<component
				:is='activeComponent'
				@back='back()'
				@navigate='navigate($event)'
			/>
		</Transition>

		<!-- End At Need -->
	</div>
</template>

<script>

const startPage = 'typeOfNeed'

import { useEstimateStore } from './store/estimate'
import { mapState } from 'pinia'

import AffirmPage from './AffirmPage.vue'
import { DispositionProductNames, StepsRemaining } from './lang'
import { createEstimate } from './apiService'
import { startsWith } from '../../stringUtils'

import TypeOfNeed from './Sections/TypeOfNeed.vue'
import PreNeedWho from './Sections/PreNeedWho.vue'
import PreNeedProduct from './Sections/PreNeedProduct.vue'
import PreNeedZip from './Sections/PreNeedZip.vue'
import PreNeedProductBudget from './Sections/PreNeedProductBudget.vue'
import PreNeedService from './Sections/PreNeedService.vue'
import PreNeedBodyPresent from './Sections/PreNeedBodyPresent.vue'
import PreNeedContactInformation from './Sections/PreNeedContactInformation.vue'
import AtNeedWho from './Sections/AtNeedWho.vue'
import AtNeedProduct from './Sections/AtNeedProduct.vue'
import AtNeedZip from './Sections/AtNeedZip.vue'
import AtNeedProductBudget from './Sections/AtNeedProductBudget.vue'
import AtNeedService from './Sections/AtNeedService.vue'
import AtNeedBodyPresent from './Sections/AtNeedBodyPresent.vue'
import AtNeedContactInformation from './Sections/AtNeedContactInformation.vue'
import AtNeedServices from './Sections/AtNeedServices.vue'
import { useFhStore } from './store/funeralHome'
import { useCalculatorStore } from './store/calculator'

export default
{
	name: 'MainPage',
	components: {
		AffirmPage,
		AtNeedBodyPresent,
		AtNeedContactInformation,
		AtNeedProduct,
		AtNeedProductBudget,
		AtNeedService,
		AtNeedServices,
		AtNeedWho,
		AtNeedZip,
		PreNeedBodyPresent,
		PreNeedContactInformation,
		PreNeedProduct,
		PreNeedProductBudget,
		PreNeedService,
		PreNeedWho,
		PreNeedZip,
		TypeOfNeed,
	},
	emits: ['progress-change'],
	data() {
		return {
			/**
			 * True if the page changing animation should be played backwards
			 */
			navigatingBack: false,

			/**
			 * Put a screen here if it's OK to go to.
			 * We will cancel any nav that isn't approved via this field
			 */
			navigatingTo: startPage,

			/**
			 * Total number of steps in our current flow
			 */
			numStepsInFlow: 9,

			/**
			 * List of pages/screens we want to go back to
			 * Does not include activeScreen or auto-nexts
			 * Used for counting pages in the progress bar
			 */
			previousScreens: [],

			/**
			 * True if our data is ready and we can render the DOM
			 */
			 ready: false,

			/**
			 * These components all require the exact same signature in terms of
			 * params and events, so they can be dynamically swapped out for each other.
			 */
			swappableSections: [
				'AtNeedBodyPresent',
				'AtNeedContactInformation',
				'AtNeedProduct',
				'AtNeedProductBudget',
				'AtNeedService',
				'AtNeedServices',
				'AtNeedWho',
				'AtNeedZip',
				'PreNeedBodyPresent',
				'PreNeedContactInformation',
				'PreNeedProduct',
				'PreNeedProductBudget',
				'PreNeedService',
				'PreNeedWho',
				'PreNeedZip',
				'TypeOfNeed',
			],
		}
	},
	computed: {
		/**
		 * The nme of the current screen in the flow
		 */
		activeScreen() {
			return this.$route.params.screen
		},

		/**
		 * Name of the type of disposition product (urn/casket) that is selected
		 */
		dispositionName() {
			return DispositionProductNames.get(this.store.selectedDisposition)
		},

		/**
		 * Get the name of the active component we want to render, if it is swappable
		 */
		activeComponent() {
			if (!this.activeScreen) {
				return null
			}

			// Translate activeScreen to PascalCase
			const name = this.activeScreen.substring(0, 1).toLocaleUpperCase() + this.activeScreen.substring(1)
			return this.swappableSections.includes(name) ? name : null
		},

		/**
		 * Read-only values for some of the store
		 */
		...mapState(useEstimateStore, {
			store: ({informantFirstName, planningForFirstName, selectedDisposition}) => ({
				informantFirstName,
				planningForFirstName,
				selectedDisposition,
			})
		}),
	},
	async beforeMount() {
		const estimate = useEstimateStore()

		// Start over
		estimate.$reset()
		useFhStore().$reset()
		useCalculatorStore().$reset()

		// Capture Legacy URL params that we need for actual behavior changes
		estimate.legacyFhId = parseInt(this.$route.query.legacy_fh_id)

		estimate.frontEndVersion = this.$route.params.v

		// Add logic to router to handle back/forward buttons
		this.$router.beforeEach((to, from, next) => {
			const requested = to?.params?.screen || startPage

			if (this.previousScreens.includes(requested))
			{
				this.navigatingBack = true
				next()
			}
			else
			{
				this.navigatingBack = false

				if (to.name === "home" && to.params?.screen !== this.navigatingTo) {
					// User clicked the forward button or typed in the name
					// of some other screen. Let's just ignore it
				}
				else
				{
					// Going to a different route or site, or we approved this.
					next()
				}
			}
		})

		// After navigation, clean up our approved nav and update history
		this.$router.afterEach((to, from) => {
			this.navigatingTo = null

			const index = this.previousScreens.indexOf(to.params.screen)
			if (index > -1) {
				this.previousScreens = this.previousScreens.slice(0, index)
			}

			this.updateProgress(to.params.screen)
		})

		// Initialize the progress bar
		this.updateProgress(this.activeScreen)

		this.disableTransition = false

		// If we are just loading the app, go to the first screen
		if (this.$route.params.screen !== startPage)
		{
			this.$router.replace({
				route: {name: 'home'},
				params: {screen: startPage},
				query: this.$route.query,
			})

			await this.$router.isReady()
		}

		// Draw once we are finished with updating the state
		setTimeout(() => {
			this.ready = true
		}, 0)
	},
	methods: {
		back()
		{
			this.$router.go(-1)
		},

		/**
		 * Save the estimate to the DB and
		 * navigate to the estimate page
		 */
		async createEstimate()
		{
			const store = useEstimateStore()

			try {
				await Promise.all([createEstimate(this.$route.query), this.delay(2000)])
			}
			catch (err) {
				console.error(err)
				if (window.confirm("Sorry, we were unable to process your estimate. Press OK to retry, or Cancel to start over.")) {
					// Recursive call: try this as many times as the user wants.
					return await this.createEstimate()
				} else {
					// Start from scratch
					window.location.reload()
				}

			}

			if (startsWith('//', store.url) ||
				startsWith('http', store.url))
				{
					throw new Error('Estimate URL does not look safe: ' + store.url)
				}

			// Assume the URL starts at the root of the website
			let url = store.url

			if (!startsWith('/', url))
			{
				url = '/' + url
			}

			window.location.assign(url)
		},

		delay(ms)
		{
			return new Promise(resolve => setTimeout(resolve, ms))
		},

		// Request navigation to a new screen
		// This is triggered by app logic that we trust
		navigate(to, canGoBack = true, goingBackward = false)
		{
			// Signal our transitions to play in reverse
			this.navigatingBack = goingBackward

			const targetUrl = this.$route.path + '/' + encodeURIComponent(to)

			// Mark that it's ok to go where we want to go
			this.navigatingTo = to

			// only log previous screens that we want to go back to
			if (canGoBack)
			{
				this.previousScreens.push(this.activeScreen)

				this.$router.push({
					route: {name: 'home'},
					params: {screen: to},
					query: this.$route.query,
				})
			}
			else
			{
				this.$router.replace({
					route: {name: 'home'},
					params: {screen: to},
					query: this.$route.query,
				})
			}
		},

		/**
		 * Calculate the user's progress through the current flow and emit 'progress-change'
		 */
		updateProgress(nextStep) {
			const stepsCompleted = this.previousScreens.length + 1

			if (StepsRemaining[nextStep]) {
				this.numStepsInFlow = stepsCompleted + StepsRemaining[nextStep]
			}

			this.$emit('progress-change', stepsCompleted / this.numStepsInFlow)
		},
	}
}

</script>


<style lang="less">
.main-page {
	width: 100vw;
	max-width: 100vw;

	.question-set {
		@apply lg:mt-4;
	}

	.content {
		position: absolute;
		width: 100%;
		text-align: center;

		&.v-leave-active, &.v-enter-active {
			transition-property: transform;
			transition-timing-function: ease-in-out;

			/* Remove dramatic page turning effect if user doesn't want animations */
			@media (prefers-reduced-motion) {
				transition-property: none !important;
			}
		}

		// screen positions to control animation flow
		/* Default transition has pages scrolling right to left */
		&.v-enter-from {
			transform: translate3d(100%, 0, 0);
		}

		&.v-leave-to {
			transform: translate3d(-100%, 0, 0);
		}
	}

	/* In reverse, pages scroll left to right */
	&.transition-reverse .content {
		&.v-enter-from {
			transform: translate3d(-100%, 0, 0);
		}

		&.v-leave-to {
			transform: translate3d(100%, 0, 0);
		}
	}
}

</style>

<style scoped lang="css">
	/* Set page turn duration based on screen width */
	.main-page .content {
		/* mobile */
		transition-duration: 0.98s;
	}

	@screen sm {
		.main-page .content {
			transition-duration: 1.1s;
		}
	}

	@screen md {
		.main-page .content {
			transition-duration: 1.3s;
		}
	}


	@screen lg {
		.main-page .content {
			transition-duration: 1.4s;
		}
	}
</style>
