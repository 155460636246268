A chevron svg
<template>
	<svg
		class='chevron-icon'
		:class='"direction-" + direction'
		fill='none'
		:height='height'
		viewBox='0 0 8 14'
		:width='width'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7 1L1 7L7 13'
			stroke-linecap='round'
			stroke-linejoin='round'
			stroke-width='2'
		/>
	</svg>
</template>

<script>

export default {
	name: 'ChevronIcon',
	props:
	{
		direction:
		{
			default: 'left',
			type: String,
		},
		height:
		{
			default: '14',
			required: false,
			type: String,
		},
		inactive: Boolean,
		width:
		{
			default: '18',
			required: false,
			type: String,
		},
	},
	methods:
	{
	},

}

</script>

<style scoped lang="less">

	.chevron-icon {
		&.direction-down {
			transform: rotate(-90deg);
		}

		&.direction-right {
			transform: rotate(180deg);
		}

		path {
			stroke: #80B2CC;
		}
	}
</style>
