<template>
	<main>
		<nav
			class="flex flex-col flex-col-reverse items-center p-0 sm:flex-row sm:text-left sm:justify-between sm:py-8 sm:px-12 bg-white shadow sm:items-center w-full"
		>
			<img
				v-if='legacy'
				class="mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]"
				src="@/assets/legacy_logo.png"
			/>
			<img
				v-else
				class="mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[50px] lg:w-[162px]"
				src='@/assets/preplan_today_logo.svg'
			/>
		</nav>
		<div class="flex justify-center">
			<div class="prose-lg max-w-7xl p-8">
				<h5><strong>Last Updated Date</strong> : [3-6-2023]</h5>
				<h1>TERMS OF USE</h1>

				<p>
					Welcome to our website (the "Website"). The Website is owned and
					operated by Precoa, LLC, together with its affiliates, subsidiaries,
					successors, and assigns (though affiliates and subsidiaries are
					sometimes referred to separately, Precoa, LLC, and its affiliates,
					subsidiaries, successors, and assigns are collectively referred to as
					"Company," "Precoa", "us," "our," and "we"). You may
				</p>
				<p>
					<strong
						>Please read these Terms of Use ("Terms") carefully before you use
						the Website.</strong
					>
				</p>
				<p>
					<strong
						>By using this Website, you, on behalf of yourself and any entity
						you represent, if applicable, acknowledge that you have read and
						understand these Terms, you agree to be bound by these Terms, and
						these Terms form a legally binding agreement between you and
						Company. When taking certain actions on the Website, you may also be
						required to click-to-agree to these Terms. If acting on behalf of an
						entity, you must have, and you represent and warrant that you have,
						full authority to bind your entity to these Terms. These Terms apply
						to your use of the Website, and, unless otherwise expressly stated,
						these Terms do not apply to any of our offline transactions with
						you. Further, by using the Website, you indicate to us that you have
						read and understand the information practices disclosed in our **
						Privacy Policy</strong
					>. You acknowledge and agree that these Terms are supported by
					reasonable and valuable consideration (which consideration includes
					your use of the Website), and you acknowledge your receipt of and the
					sufficiency of the consideration. Do not use this Website if you do
					not agree with each term and condition in these Terms.**
				</p>
				<p>
					We reserve the right, without notice and for any reason, to remove any
					content from the Website; to modify, suspend, or discontinue the
					Website; and/or to deny access of any user to all or any part of the
					Website.
				</p>
				<ol>
					<li>
						<h3>Modification of Terms.</h3>
						<p>
							We reserve the right to revise these Terms at any time. We will
							post any new or revised Terms here, and you should review these
							Terms regularly to make sure you are aware of any changes. You can
							determine if these Terms have been revised since your last visit
							to the Website by referring to the last updated date at the top of
							these Terms. If changes are significant, Company may also notify
							you of such changes by posting a notice on the home page of the
							Website and/or via contact information you have provided to us, if
							any. Your use of the Website following such revision to these
							Terms constitutes your acceptance of and agreement to these Terms,
							as revised, and the revisions will apply to your use of the
							Website on and after the date on which we post the revised Terms.
							We will keep prior versions of these Terms in an archive for your
							review upon your request.
						</p>
					</li>
					<li>
						<h3>Ability to Agree to Terms; Intended Users.</h3>
						<p>
							The Website is intended for adults only. Subject to our
							information practices related to Children's Privacy as described
							in our Privacy Policy, by using the Website, you affirm that you
							(i) are eighteen (18) years old or older, or that you are between
							the ages of thirteen (13) and seventeen (17) and possess legal
							parental or guardian consent to use the Website and provide
							information through the Website (however, as described in our
							Privacy Policy, you should not provide Personal Information about
							yourself through the Website if you are under age 18, or under the
							age of majority in jurisdictions where such age is greater than
							18); (ii) are a resident of the United States or Canada; and (iii)
							have authority to agree to these Terms and are competent and
							capable of understanding and complying with these Terms. Our
							Website, products, and services are all directed to people who are
							at least thirteen (13) years old, and children under age 13 are
							prohibited from using the Website. Company does not and will not
							knowingly (i) collect or permit submission of
							personally-identifiable information of anyone under age 18, or
							under the age of majority in jurisdictions where such age is
							greater than 18through the Website; (ii) contact such individuals
							for marketing purposes; or (iii) sell products or services to such
							individuals.
						</p>
					</li>
					<li>
						<h3>Jurisdiction.</h3>
						<p>
							The Website is controlled and operated from the United States and
							is not intended to (and shall not be deemed to) subject us to
							non-U.S. jurisdiction or laws. If you access the Website, you do
							so at your own risk, and you agree to comply with all applicable
							local, provincial, state, and federal laws, rules, and regulations
							in connection with your use of the Website. We may limit the
							Website's availability, in whole or in part, to any person,
							geographic area, or jurisdiction we choose, at any time and in our
							sole discretion.
						</p>
					</li>
					<li>
						<h3>Electronic Communications.</h3>
						<p>
							When you visit the Website, complete an online form, or send
							e-mails to us, you are communicating with us electronically. And,
							subject to our information practices set forth in our Privacy
							Policy, you consent to receive responsive communications from us
							by any means, including telephone, text, e-mail, or other
							electronic communication. Unless otherwise set forth in these
							Terms, notices to you may be made via postings to the Website, by
							email, or by regular mail, in our discretion, and you agree that
							all agreements, notices, disclosures, and other communications
							that we provide to you electronically, or otherwise, satisfy any
							legal requirement that such communications be in writing. You
							agree that a printed version of these Terms and of any notice
							given in electronic form shall be admissible in judicial or
							administrative proceedings, based upon or relating to these Terms
							and/or your use of the Website, to the same extent and subject to
							the same conditions as other business documents and records
							originally generated and maintained in printed form.
						</p>
					</li>
					<li>
						<h3>User Permissions and Obligations.</h3>
					</li>
				</ol>
				<p>
					a. Right to Use the Website and Precoa Content. Company grants you a
					non-transferable, non-assignable, non-sublicensable, non-exclusive,
					revocable, limited license to access and use the Website and to
					download and print limited copies of Precoa Content (defined as all
					text, graphics, logos, images, audio clips, digital downloads, and any
					other material or information on, comprising, or used in connection
					with the Website); provided that you comply with these Terms. Any
					other copying, reproduction, use, distribution, republication,
					display, rental, sale, other transfer, modification, or translation of
					the Website and/or Precoa Content (or creation of derivative works
					based on the Website or Precoa Content) is strictly prohibited without
					the express prior written permission of Company.
				</p>
				<p>
					b. Technical Requirements. You are responsible for all software,
					hardware, services (such as Internet service), and equipment necessary
					to access and use the Website, including all related expenses, if any.
				</p>
				<p>
					c. Indemnification by You. YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD
					HARMLESS COMPANY, ITS AFFILIATES, SUBSIDIARIES, SUCCESSORS, AND
					ASSIGNS, AND EACH OF OUR AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
					MEMBERS, EMPLOYEES, AND AGENTS FOR, FROM, AND AGAINST ANY AND ALL
					CLAIMS, DAMAGES, LIABILITIES, LOSSES, JUDGMENTS, DEMANDS, AWARDS, AND
					COSTS AND EXPENSES (including reasonable attorneys' fees and costs)
					DUE TO OR ARISING OUT OF (i) YOUR USE OF OR ACTIVITIES IN CONNECTION
					WITH THE WEBSITE, PRECOA CONTENT, USER CONTENT (DEFINED AND DISCUSSED
					BELOW), OR OTHER INFORMATION OR MATERIALS OBTAINED THROUGH THE
					WEBSITE; (ii) YOUR USER CONTENT, INCLUDING WITHOUT LIMITATION THE
					DISPLAY OF YOUR USER CONTENT ON OUR WEBSITE AND/OR OUR USE OF YOUR
					USER CONTENT AS AUTHORIZED IN THESE TERMS; (iii) YOUR INFRINGEMENT,
					MISAPPROPRIATION, OR OTHER VIOLATION OF ANY THIRD-PARTY RIGHTS,
					INCLUDING WITHOUT LIMITATION, THE RIGHTS OF ANOTHER WEBSITE USER;
					AND/OR (iv)YOUR VIOLATION OF THESE TERMS, OR ANY LAW, RULE, OR
					REGULATION, AND/OR ANY BREACH OF YOUR REPRESENTATIONS OR WARRANTIES
					MADE IN THESE TERMS.
				</p>
				<p>d. Conduct on the Site. You agree NOT to:</p>
				<ul>
					<li>
						Use the Website or Precoa Content for any fraudulent or unlawful
						purpose or any purpose prohibited by these Terms.
					</li>
					<li>
						Use the Website or Precoa Content in any manner that may adversely
						affect Company's resources or the availability of the Website to
						others, or in any way that disrupts the operation of the Website or
						the servers or networks that make the Website available;
					</li>
					<li>
						Delete or revise any Precoa Content on the Website, or modify,
						adapt, translate, reverse engineer, decompile, disassemble, or
						otherwise tamper with any portion of the Website or Precoa Content;
					</li>
					<li>
						Remove or modify any copyright, trademark, or other proprietary
						rights notice from the Website or any Precoa Content;
					</li>
					<li>
						Use the Website to transmit, disclose, collect, or store personal
						information about others;
					</li>
					<li>
						Use the Website or information obtained through the Website to
						contact anyone who has asked not to be contacted;
					</li>
					<li>
						Submit or post any material or information that is illegal, obscene,
						threatening, harassing, slanderous, defamatory, abusive, invasive of
						privacy; or that infringes, misappropriates, or otherwise violates
						the proprietary or legal rights of any person or entity; or that
						contains software viruses, corrupted data, or cancel bots;
					</li>
					<li>
						Impersonate any person or entity, create a false identity, falsely
						state or otherwise misrepresent your affiliation with any person or
						entity, express or imply that we endorse any statement you make or
						have made, or otherwise mislead as to the origin of any material or
						information you submit;
					</li>
					<li>
						Violate the rights of any third party or breach any contract with or
						duty owed to any third party;
					</li>
					<li>
						Create a database by downloading and saving Precoa Content or any
						other material or information from the Website;
					</li>
					<li>
						Download, copy, or use any Precoa Content or any other information
						or content on or comprising the Website in order to compete with
						Company in any way;
					</li>
					<li>
						Use the Website to design, develop, operate, support, market,
						distribute, or otherwise make available any program, application, or
						service (including without limitation any device, technology,
						product, computer program, mobile device application, website, or
						mechanical or personal service) that enables or provides access to,
						use of, operation of, or interoperation with the Website;
					</li>
					<li>
						Frame or mirror any Precoa Content or other portions of the Website.
						If you link to the Website, Company reserves the right to block any
						such links and/or demand you remove any such link at any time;
					</li>
					<li>
						Attempt to gain unauthorized access to the Website. You may not
						attempt to defeat any security measures that we take to protect the
						Website; or
					</li>
					<li>
						Use any manual or automatic website search/retrieval computer
						hardware or application to retrieve or in any way gather Precoa
						Content or other information or materials on or comprising the
						Website, or reproduce or circumvent the structure or presentation of
						the Website without our prior written consent. General purpose
						Internet search engines will be entitled to access the Website and
						use limited content from the Website if, in all cases: (i) They
						provide a direct hyperlink to the relevant web page from the
						Website; (ii) they link to and use the content solely in connection
						with their activities as an Internet search engine; and (iii) they
						access the Website from a stable IP address using an
						easily-identifiable agent. We reserve the right to revoke this
						permission in whole or in part.
					</li>
				</ul>
				<ol start="6">
					<li>
						<h3>Content You Submit Using the Website.</h3>
						<p>
							a. Ownership of and Right to Use User Content. If you choose to
							post any information, content, or comments (collectively, "User
							Content") to our blog or any other portion of the Website where it
							is readily apparent that the User Content will be publicly
							available, you consent to our posting and collection of such User
							Content, and you grant us permission to use (and to allow third
							parties to use) such User Content in connection with the operation
							of the Website and our business generally, including without
							limitation, for our advertising and marketing purposes. You grant
							us the unrestricted, perpetual, worldwide, transferable,
							sublicensable, royalty-free, and fully-paid up non-exclusive right
							and license to copy, distribute, transmit, publicly display,
							publicly perform, reproduce, edit, translate, reformat, and create
							derivative works based on your User Content; and to publish your
							name and city of residence in connection with your submitted User
							Content (though we are under no obligation to use your name or
							city of residence or to give you any attribution or credit for
							such User Content). You acknowledge and agree that we may use your
							User Content in any media, now known or hereafter developed. You
							will not be paid for our use of your User Content. We are under no
							obligation to post or use any User Content you provide, and we may
							remove any User Content at any time in our discretion. We do not
							claim ownership of your User Content, unless otherwise expressly
							provided on the Website. You acknowledge and agree that we may
							have something similar already under consideration or development,
							and we will own all right, title, and interest in and to what we
							create or develop without use of your User Content.
						</p>
						<p>
							YOU ACKNOWLEDGE AND AGREE THAT WE SHALL HAVE NO RESPONSIBILITY OR
							LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY USE OR MISUSE OF YOUR
							USER CONTENT BY ANY OTHER WEBSITE USER.
						</p>
						<p>
							b. <em>Conduct Relating to Your User Content.</em> If you post
							User Content to our site or other areas of the Website where it is
							readily apparent that the User Content will be publicly available:
						</p>
						<ul>
							<li>
								You are solely responsible for the User Content you post. You
								can edit or remove the User Content you post at any time. Upon
								your request, we may, but have no obligation to, remove User
								Content that you post, but copies of User Content that you
								posted may remain in our storage media. We retain the right to
								make archival and back-up copies of and to store User Content
								you post (and drafts of User Content that you never actually
								post), indefinitely. You agree, however, that we have no
								responsibility or liability for the deletion of or failure to
								store or transmit this User Content.
							</li>
							<li>
								You must not post User Content for unlawful purposes or to
								promote illegal activities. If you do, your access to the
								Website may be suspended or terminated, and we may notify law
								enforcement authorities.
							</li>
							<li>You may not post User Content using any automated means.</li>
							<li>
								You must not create multiple accounts for yourself with the
								intent of exploiting our Website.
							</li>
							<li>
								We may or may not pre-screen your User Content before it is
								posted, and we have the right, but not the obligation, in our
								discretion and without notice to you, to refuse to post your
								User Content.
							</li>
							<li>
								We are not obligated to monitor the User Content posted to the
								Website. We reserve the right, however, to decide what to post
								and whether to remove any posts, without notice or liability to
								the user who posted the User Content or any other user.
							</li>
							<li>
								All comments must relate in some way to the post on which you
								are commenting.
							</li>
							<li>
								By posting, you represent and warrant that you have the right to
								share your User Content, and that our use of the User Content as
								allowed in these Terms and inclusion of the User Content on the
								Website, does not and will not infringe, misappropriate, or
								otherwise violate any third-party copyright, trademark, patent,
								trade secret, and any other proprietary right, privacy right,
								right of publicity, or other third-party right. You shall be
								solely responsible and liable for any damages or harm resulting
								from your posting of User Content to the Website, and you agree
								to indemnify us according to Section 5(c) above.
							</li>
							<li>
								User Content posted to the Website is the responsibility of the
								posting user. User Content may be offensive, harmful,
								inaccurate, deceptive, or otherwise inappropriate. The views and
								opinions expressed by users on the Website are not necessarily
								our views. We are not responsible for, we make no
								representations or warranties regarding, and we disclaim any and
								all responsibility and liability for the User Content. You
								acknowledge and agree that your reliance on User Content will be
								at your own risk.
							</li>
							<li>
								In the event that we refuse to post your User Content or remove
								your User Content for any reason, we reserve the right to
								disclose such User Content as necessary to satisfy any
								applicable law, regulation, or governmental request.
							</li>
							<li>
								Because blogs are informal in nature, we may not identify our
								trademarks as such in all posts and comments; however, we
								continue to reserve all rights, titles, and interests in such
								trademarks, regardless of how they are used on the Website.
							</li>
						</ul>
					</li>
				</ol>

				<ol start="7">
					<li>
						<h3>Infringement Policy.</h3>
						<p>
							We will remove User Content posted to the Website if we believe in
							good faith that the User Content is infringing a copyrighted work.
							If you believe your copyright has been infringed by User Content
							or if you believe User Content you posted has been wrongly removed
							from the Website, please refer to our Copyright Infringement
							Policy, located here.
						</p>
					</li>
					<li>
						<h3>Accuracy of Precoa Content.</h3>
						<p>
							We attempt to provide Precoa Content on the Website that is
							complete, accurate, and current (excluding User Content, for which
							we are not responsible). Despite our efforts, the Precoa Content
							may occasionally be inaccurate, incomplete, or out of date. We
							make no representation as to the completeness, accuracy, or
							timeliness of any Precoa Content.
						</p>
					</li>
					<li>
						<h3>Feedback.</h3>
						<p>
							By submitting ideas, suggestions, enhancement requests, documents,
							and/or proposals ("Contributions") to Company through the Website
							or otherwise, you acknowledge and agree that: (i) Your
							Contributions do not contain the confidential or proprietary
							information of you or any third party; (ii) we are not under any
							obligation of confidentiality, express or implied, with respect to
							the Contributions; (iii) we are entitled, but not obligated, to
							use or disclose (without notice or credit given to you) such
							Contributions for any purpose, in any manner, and in any media,
							now known or hereafter developed; (iv) we may have something
							similar to the Contributions already under consideration or
							development, and we will own all right, title, and interest in and
							to what we create or develop without use of your Contributions;
							(v) you hereby assign to Company all rights, titles, and interests
							in and to your Contributions (and all copyright, trademark, trade
							secret, and other intellectual property rights therein); and (vi)
							you are not entitled to compensation of any kind for your
							Contributions.
						</p>
					</li>
					<li>
						<h3>Privacy.</h3>
						<p>
							For information on how we use and protect the personal information
							and automatically-collected information collected through your use
							of the Website, view our Privacy Policy
							<a href="/privacy">here</a>. Your use of the Website indicates to
							us that you have read and understand our Privacy Policy.
						</p>
					</li>
					<li>
						<h3>Filtering.</h3>
						<p>
							Pursuant to 47 U.S.C. Section 230(d), as amended, we hereby notify
							you that parental control protections (such as computer hardware,
							software, or filtering services) are commercially available that
							may assist you in limiting access to material that is harmful to
							minors. Information identifying some current providers of such
							protections is available at:
							<a href="http://getnetwise.org/">http://getnetwise.org</a> and
							<a href="https://onguardonline.gov/">http://onguardonline.gov</a>.
							We do not endorse any of the products or services listed at these
							sites. Further, this notice shall not constitute an admission by
							us that any content on our Website is harmful to minors.
						</p>
					</li>
					<li>
						<h3>No Warranty; Limitation of Liability.</h3>
						<p>
							You use the Website at your own risk. We provide the website,
							Precoa content, access to User Content, and all other information
							and materials obtained on and/or comprising the Website "as is"
							and "as available," without any express or implied representations
							or warranties of any kind. We disclaim, to the maximum extent
							permitted by applicable law, all express, implied, and statutory
							representations and warranties with respect to the website, Precoa
							content, User Content, and all other information and materials
							obtained on and/or comprising the Website, including without
							limitation, warranties of merchantability, fitness for a
							particular purpose, satisfactory quality, suitability, timeliness,
							reliability, security, non-infringement, and title. We do not
							guarantee or make any representation or warranty that the Website,
							Precoa content, User Content, and/or other information or
							materials obtained on and/or comprising the Website will be safe
							or secure, accurate, complete, reliable, current, error-free,
							uninterrupted, timely, or free of viruses or other harmful
							components, nor that any particular software or hardware will be
							compatible with the Website. Company is not responsible for any
							loss or damage arising directly or indirectly from your use of the
							Website, or the interception or loss of any data transmitted to or
							from the Website. We make no representation or warranty that
							communications through the Website will be secure and not
							intercepted by a third party. Company shall have no responsibility
							or liability whatsoever for failure of or damage to electronic or
							mechanical equipment or communication, telephone or other
							connection problems, computer viruses, unauthorized access or
							interception of data, theft, or errors. If User Content you post
							is confidential, defamatory, or otherwise violates the rights of
							third parties, you may be legally responsible for damages, and
							company shall have no responsibility or liability for the same.
						</p>
					</li>
				</ol>
				<p>
					Without limiting the generality of the foregoing paragraph or any
					other disclaimers or limitation of liability in these Terms, to the
					maximum extent permitted by applicable law, company will not be liable
					for any direct, consequential, special, multiplied, exemplary,
					punitive, indirect, or incidental damages (including without
					limitation damages for loss of profits; loss of use; loss of data;
					loss of security of your information; or unauthorized interception of
					any such information by third parties) arising out of or in connection
					with your use of or inability to use, or any other matter relating to,
					the Website, Precoa content, User Content, or other information or
					materials on and/or comprising the Website, even if we have been
					advised of the possibility of such damages or losses. Your sole and
					exclusive remedy for dissatisfaction with the Website is to stop using
					the same.
				</p>
				<p>
					The disclaimers and limitations of liability contained in these terms
					are a material part of our agreement. It is possible that applicable
					law may not allow for limitations on certain implied warranties or
					exclusions or limitations of certain types of damages. Solely to the
					extent that such law applies to you, some of the above disclaimers,
					exclusions, or limitations may not apply to you, and you may have
					additional rights. However, in all circumstances, our maximum
					aggregate liability for all damages, losses, and claims, whether in
					contract, tort (including without limitation negligence), or otherwise
					shall be USD 100.00. Notwithstanding the foregoing sentence, if
					applicable law prohibits the limitation or exclusion of a party's
					liability with respect to death or personal injury caused by such
					party's negligence, fraud, or any other matter, then such party's
					liability will not be limited or excluded to the extent of such
					prohibition under such applicable law.
				</p>
				<p>
					No statements, whether oral or written, made by any director, officer,
					member, employee, or agent of company may be deemed as a
					representation or warranty on behalf of Company in contradiction to
					this section 12 or any other provisions of these terms.
				</p>
				<p>
					In agreeing to the limitation of liability contained in these terms,
					you expressly waive any protections (whether statutory or otherwise)
					that would otherwise limit the coverage of the limitations of
					liability to include only those claims that you may know or suspect to
					exist in your favor at the time of agreeing to the limitations of
					liability. Without limiting the generality of the foregoing, you
					expressly waive California Civil Code § 1542 (and any similar laws in
					other jurisdictions), which says: "A general release does not extend
					to claims which the creditor does not know or suspect to exist in his
					or her favor at the time of executing the release, which if known by
					him or her must have materially affected his or her settlement with
					the debtor."
				</p>
				<ol start="13">
					<li>
						<h3>Security.</h3>
						<p>
							While we try to maintain the security of the Website, we do not
							guarantee that the Website will be secure. Additionally, third
							parties may make unauthorized alterations to the Website. If you
							become aware of any unauthorized third-party alterations to or use
							of the Website or any other breach of security, please contact us
							immediately. Additional information regarding the security of your
							information is included in our Privacy Policy, located
							<a href="/privacy">here</a>.
						</p>
					</li>
					<li>
						<h3>Company's Intellectual Property Rights.</h3>
						<p>
							PRECOA, and other trademarks, service marks, and names appearing
							on the Website are registered or unregistered trademarks and
							service marks of Company in the United States and other countries.
							Company exclusively owns all rights, titles, and interests in and
							to these trademarks, service marks, and names. The display of
							these marks and of notices associated with these marks is not
							intended to be a comprehensive compilation of all of our trademark
							rights, and we may own other trademark rights. All rights not
							expressly granted are reserved. Your use of our trademarks,
							service marks, and names without our prior written consent is
							strictly prohibited. Without limiting the generality of the
							foregoing, you may not use Company's trademarks, service marks, or
							names in any meta-tags or other hidden text.
						</p>
					</li>
				</ol>
				<p>
					Product, service, and company names that appear on the Website that
					are not owned by us are subject to trademark and/or other rights of
					other parties.
				</p>
				<p>
					Except for User Content, all Precoa Content; the selection,
					arrangement, and presentation of all Precoa Content (including
					information in the public domain); and the overall design, "look and
					feel," color combinations, and other graphical elements of the
					Website; and all rights, titles, and interests therein (including
					without limitation all copyright, trademark, and other intellectual
					property and proprietary rights therein) are the exclusive property of
					Company or its licensors or suppliers.
				</p>
				<ol start="15">
					<li>
						<h3>Links.</h3>
						<p>
							The Website may contain links to other websites and online
							resources that we do not own, operate, or control. We are not
							responsible for and do not endorse such external sites or their
							content. If you click on the links, you will leave the Website and
							be subject to the terms of use and privacy policies of the linked
							sites. YOUR USE OF THIRD-PARTY SITES IS AT YOUR OWN RISK. Company
							makes no guarantees and disclaims any representations or
							warranties, express, implied, or otherwise about the accuracy,
							relevance, timeliness, completeness, or appropriateness of these
							sites, the information contained in them and/or the products or
							services they provide. Company shall not be liable, directly or
							indirectly, for any damage or loss incurred by you in connection
							with websites, products, or services accessed through links
							contained on the Website.
						</p>
					</li>
					<li>
						<h3>Audit Rights and Enforcement.</h3>
						<p>
							We have the right, but not the obligation, at any time and with
							any frequency in our discretion, to audit your use of the Website
							to determine your compliance with these Terms. We have the right
							to enforce these Terms, for any reason and in any manner or by any
							means that we, in our discretion, deem necessary or appropriate.
							We may cooperate with any legal process relating to your use of
							the Website and/or any third-party claim that your use of the
							Website is unlawful or infringes, misappropriates, or otherwise
							violates any third party's rights. We may, in our discretion and
							without additional notice to you, start, stop, or modify any
							regulation or enforcement measures at any time.
						</p>
					</li>
					<li>
						<h3>Discontinuance and Modification; Termination.</h3>
						<p>
							There is no guarantee that we will continue to offer or provide
							the Website, and we may simply suspend or discontinue the Website
							(or any part thereof) at any time for any reason and without
							notice to you. We have the right to remove any content from the
							Website or to modify the Website. We may, in addition to all other
							rights and remedies, terminate your access to and use of the
							Website if you breach any provision of these Terms or if you
							access or use the Website in a manner that is not expressly
							authorized by Company or that may harm Company. Regardless of the
							cause or basis for termination, you agree that we shall not be
							liable for any such termination, and you are not entitled to
							compensation or damages of any kind as a result of the
							termination. Accessing the Website after such termination will
							constitute an act of trespass, among other potential claims.
						</p>
					</li>
					<li>
						<h3>Your Discontinued Use.</h3>
						<p>
							You may discontinue use of the Website at any time. These Terms
							will continue to apply to all past use of the Website, even if you
							are no longer using the Website, whether voluntarily or because we
							terminated your access thereto.
						</p>
					</li>
					<li>
						<h3>Governing Law and Disputes.</h3>
						<p>
							These Terms, and any dispute that may arise between you and
							Company regarding the Website or any Precoa Content or other
							information or materials on the Website, will be governed by the
							laws of the State of Oregon, USA, without regard to
							conflict-of-laws principles that might result in the application
							of the laws of any other jurisdiction. You hereby consent to the
							exclusive jurisdiction and venue of the federal, state, and local
							courts located in Multnomah County, Oregon, and you waive any
							jurisdictional, venue, or inconvenient forum objections thereto.
							You agree that any dispute arising between you and Company under
							these Terms will be conducted only on an individual basis and not
							in a class, consolidated, or representative action. You further
							agree to waive your right to a jury trial in connection with any
							action or litigation arising out of or related to these Terms. You
							further agree that, regardless of any statute or law to the
							contrary, you must file any claim or cause of action against
							Company arising out of or relating to these Terms or your use of
							the Website within one (1) year after such claim or cause of
							action arose, or forever be barred. It is possible that applicable
							law may not allow for the limitation or waiver of a statute of
							limitations for certain matters. Solely to the extent that such
							law applies to you, the above limitation to when a claim or cause
							of action against Company arising out or related to these terms of
							use must be filed may not apply to you.
						</p>
					</li>
				</ol>
				<p>
					To the extent you have in any manner violated or threatened to violate
					Company's intellectual property rights; disclosed or threatened to
					disclose any Company confidential or proprietary information; violated
					or threatened to violate the security of any person, data, Company
					servers or networks, and/or the Website; and/or otherwise breached or
					threatened to breach these Terms, you acknowledge and agree that such
					violation or breach will cause immediate and irreparable harm to
					Company, and Company shall be entitled to injunctive or other
					appropriate relief, including without limitation specific performance
					(without the posting of a bond or other security and without proving
					damages), and you agree that Company may seek such relief in any court
					of competent jurisdiction.
				</p>
				<ol start="20">
					<li>
						<h3>General.</h3>
						<p>
							These Terms do not create any partnership, joint venture,
							employer-employee, broker-dealer, agency, or franchise
							relationship between you and us. If any provision of the Terms is
							determined to be invalid or unenforceable pursuant to applicable
							law, then the invalid or unenforceable provision will be deemed
							superseded by a valid and enforceable provision that most closely
							matches the intent of the original provision, and the remainder of
							these Terms shall continue in full force and effect. No waiver or
							failure to assert any provision of the Terms shall be valid unless
							in writing and signed by an officer of Company. No waiver of any
							breach or default under these Terms will be deemed to be a waiver
							of any preceding or subsequent breach or default. You may not
							assign, transfer, or sublicense any or all of your rights or
							obligations under these Terms without our express prior written
							consent. Company may assign its rights and duties under these
							Terms to any party, at any time, and without notice to you, unless
							otherwise expressly stated in these Terms or required by law.
							Unless otherwise expressly stated herein, these Terms (and all
							policies incorporated into these Terms) constitute the entire
							agreement between you and us relating to the subject matter herein
							and supersedes any and all prior or contemporaneous written or
							oral agreements or understandings between you and us relating to
							such subject matter. We will not be responsible for failure to
							fulfill any obligation due to causes beyond our control. Nothing
							contained in these Terms is in derogation of our right to comply
							with governmental, court, and law enforcement requests or
							requirements relating to your use of the Website or information
							provided to or gathered by us with respect to such use. Company
							reserves all rights not expressly granted in these Terms.
						</p>
					</li>
					<li>
						<h3>California Consumer Rights Notice.</h3>
						<p>
							Under California Civil Code Section 1789.3, Website users who are
							California residents are entitled to the following consumer rights
							notice: The provider of this Website and associated services is
							Precoa, LLC, located at 13221 SW 68th Pkwy, Tigard, OR 97223. As
							of the Effective Date of these Terms, no direct charges are
							imposed upon Website users for use of the Website. If you are a
							California resident, you may report complaints with the services
							provided through this Website to or request information regarding
							the services from the Complaint Assistance Unit of the Division of
							Consumer Services of the California Department of Consumer Affairs
							by contacting them in writing at 1625 North Market Blvd., Suite N
							112, Sacramento, CA 95834, or by telephone at (800) 952-5210. We
							request that you first report complaints directly to us and try to
							resolve them with us before contacting the Complaint Assistance
							Unit.
						</p>
					</li>
					<li>
						<h3>Contact Us.</h3>
						<p>
							If you have any comments or questions about these Terms or the
							Website, please contact us at:
						</p>
					</li>
				</ol>
				<p>
					By mail to: <br />
					Precoa, LLC Privacy &amp; Data Protection <br />
					13221 SW 68th Pkwy <br />
					Suite 100 <br /><br />
					Or by e-mail or phone at: <br />
					E-mail at: <a href="mailto:privacy@precoa.com">privacy@precoa.com</a>
					<br />
					Toll-free phone number at: 1-888-227-6153 <br />
				</p>
			</div>
		</div>
	</main>
</template>

<script>
export default
{
	data()
	{
		return {
			// false if default logo, true if Legacy logo
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),
		}
	}
}
</script>

<style lang="css" scoped>
td,
th {
	@apply p-4 text-left;
}
ul {
	@apply list-disc;
}
ol {
	@apply list-decimal;
}
a {
	text-decoration: underline;
}
</style>
