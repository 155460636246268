<template>
	<div>
		<!-- Section needs some style work -->
		<QuestionSet>
			<template #question>
				<div class='max-w-screen-lg'>
					<span class="hidden lg:inline">Our Preplanning Recommendation</span>

					<span class="lg:hidden">We recommend a <span class='text-p-gold-400 font-semibold underline underline-offset-3'>{{ currency(calculator.funeralValue) }} {{ isFinalExpense ? 'funeral insurance' : 'preneed insurance' }}</span> policy to help cover the cost of this funeral for your loved ones</span>
				</div>
			</template>
			<template #subtitle>
				<div class='max-w-screen-lg'>
					We recommend a <span class='text-p-gold-400 font-semibold underline underline-offset-3'>{{ currency(calculator.funeralValue) }} {{ isFinalExpense ? 'funeral insurance' : 'preneed insurance' }}</span> policy to help cover the cost of this funeral for your loved ones
				</div>
			</template>
		</QuestionSet>

		<div class='sm:flex sm:justify-center mt-10'>
			<div class='sm:grid sm:grid-cols-3 sm:p-2 md:p-8 lg:p-12 xl:p-4 sm:gap-4 w-full max-w-screen-2xl'>
				<div class='sm:col-span-2'>
					<div class='grid gap-4 grid-cols-1 text-left'>
						<div class='border-2 p-8 rounded-2xl'>
							<div v-if="!isFinalExpense">
								<h3>
									Preneed Coverage Options
								</h3>
								<div>
									A preneed insurance policy covers expenses related to a loss. These funds help your loved ones cover the cost of the funeral at the time of need.
								</div>
							</div>
							<div v-if="isFinalExpense">
								<h3>
									Funeral Coverage Options
								</h3>
								<div>
									Funeral insurance is designed to cover expenses related to a loss. These funds help your loved ones cover the cost of the funeral at the time of need.

								</div>
							</div>
							<div class="text-p-gold font-semibold uppercase text-sm tracking-loose mt-6 mb-2 lg:mt-8 lg:mb-4">
								<FourPointStar
									class='inline pr-2 -mt-1'
									gold
								/>Most Popular
							</div>
							<!-- <div class='flex flex-wrap items-stretch space-x-4'> -->
							<div class='grid grid-cols-1 lg:grid-cols-2 gap-5'>
								<TermSelectButton
									v-for="years in calculator.termsAvailable"
									v-model='estimate.selectedInsuranceTermYears'
									:years='years'
								/>
							</div>

							<div class="pl-4 pb-4 mt-4 text-sm text-slate-700">
								Not quite right? Adjust your
								<a
									class='text-p-gold-400 underline font-semibold'
									href='#adjust-coverage'
								>
									funeral preferences
								</a>
							</div>

							<div class="pt-4 pb-8 pl-2 pr-6 flex flex-start gap-4">
								<img src="@/assets/checkmark-circle.svg" alt="checkmark" class="w-[26px] h-[26px]" />
								<div>
									<p class="text-lg mb-1">Inflation Protection (included in all plans)</p>
									<p class="text-sm text-slate-700">Based on your selections, this funeral would cost {{ currency(calculator.funeralValue) }}. In {{ calculator.lifeExpectancy }} years this same funeral would cost {{ currency(calculator.inflatedFuneralCost) }}. Inflation protection helps offset rising costs for your loved ones.</p>
								</div>
							</div>

							<div class="p-4 rounded-xl bg-p-grey-200">
								<h5 class="!pt-0 mb-4">This Plan includes:</h5>
								<ul class='md:columns-2 mb-4'>
									<PlanItem
										v-for='item in bundle.planItems.includes'
										:key='item'
										included
									>
										{{ item }}
									</PlanItem>
									<PlanItem
										v-for='item in bundle.planItems.excludes'
										:key='item'
									>
										{{ item }}
									</PlanItem>
								</ul>

								<h5 v-if='isFinalExpense'>
									Why Final Expense?
								</h5>
								<h5 v-else>
									Why Preneed?
								</h5>

								<div class="grid md:grid-cols-3 gap-8 md:gap-12 mt-4 text-md">
									<div>
										<img src="@/assets/thumbs-up.svg" alt="" class="mb-4">
										Peace of mind that your wishes will be honored
									</div>
									<div>
										<img src="@/assets/heart.svg" alt="" class="mb-4">
										No guess work so your loved ones can focus on healing
									</div>
									<div>
										<img src="@/assets/piggy-bank.svg" alt="" class="mb-4">
										Shield your loved ones from financial hardship
									</div>
								</div>

							</div>
						</div>

						<AdjustCoverage />

						<div class='border-2 p-5 md:p-8 rounded-2xl'>
							<div class="text-2xl font-serif text-gray-900 font-normal pb-1">
								Commonly Purchased With Your Plan
							</div>
							<p>Select any add-ons you'd like to budget for.</p>
							<div class='grid grid-cols-1 lg:grid-cols-2 gap-8 md:gap-5 auto-rows-ft mt-8'>
								<AddOnButton
									v-for="addOn in AddOnList"
									v-model='estimate[addOn.key]'
									:key="addOn.key"
									:recommended="addOn.recommended"
									:price="addOn.price"
									:monthly-price="calculator.monthlyMultiplier() * addOn.price"
									:single-pay="addOn.singlePayOnly"
									:name='addOn.name'
								>
									{{ addOn.detail }}
								</AddOnButton>
							</div>
						</div>

						<div class='h-36 sm:hidden'></div>

						<footer v-if="estimate.expectContact" class='z-50 w-full border-t-2 sm:border-2 p-4 lg:p-8 fixed bottom-0  bg-p-green-100 border-p-green
										flex justify-between flex-col
										sm:static lg:flex-row-reverse sm:rounded-2xl lg:mb-8'>

								<div class='flex mb-4 text-p-green lg:mb-0 justify-center '>
									<div class="text-black pr-6 text-lg self-center">Your plan:</div>

									<div v-if="calculator.multiPaySelected" class="self-end lg:self-center font-semibold text-4xl lg:text-5xl">
										<span class="font-serif">{{ monthlyPaymentDollars }}</span><!--
										--><span class='font-serif lg:bottom-5'>.{{ monthlyPaymentCents }}<span class='font-serif font-medium lg:text-5xl'>/</span></span><!--
										--><span class='font-serif font-medium text-xl lg:text-2xl'>mo</span>
									</div>
									<div v-else class="self-end lg:self-center font-semibold text-4xl lg:text-5xl">
										<span class="font-serif">{{ singlePaymentFormatted }}</span>
									</div>
								</div>


							<LinkButton :to='{name: "contact", params: $route.params}' class="w-full h:w-fit lg:w-96">
								Contact an Agent
							</LinkButton>
						</footer>
					</div>
				</div>

				<!-- Right Column -->
				<aside class='sticky top-4 self-start sm:col-span-1 hidden sm:block'>
					<div class='bg-p-green-100 border-2 rounded-2xl border-p-green'>
						<div class="p-4 lg:p-8 xl:p-12">
							<p class="text-xl font-light text-center">Get coverage for your funeral today!</p>
							<div v-if="calculator.multiPaySelected" class='text-5xl lg:text-7xl text-center text-p-green font-medium font-serif mt-4 mb-6'>
								<span class="font-black tracking-tight">{{ monthlyPaymentDollars }}</span><span class='relative ml-2 bottom-1 text-xl lg:text-3xl align-super'>.{{ monthlyPaymentCents }}</span><!--
								--><span class='lg:text-5xl'>/</span><span class='text-lg lg:text-3xl'>mo</span>
							</div>
							<div v-else class='font-black tracking-tight text-5xl lg:text-7xl text-center text-p-green font-serif mt-4 mb-6'>
								{{ singlePaymentFormatted }}
							</div>
							<LinkButton v-if="estimate.expectContact" :to='{name: "contact", params: $route.params}'>
								<div class="pt-1 pb-1 text-md lg:text-2xl">Contact an Agent</div>
							</LinkButton>
							<!-- TODO: Add dynamic savings -->
							<!-- <div class="text-center text-p-green mt-4">You could save $49/year</div> -->
						</div>
						<img class='' src='@/assets/flowers-footer.png'/>
					</div>

					<!-- Commented but functional. Decided to not show on preneed exit pages. -->
					<!-- <FuneralHomeContactInfo
						class='mt-4'
						v-if='showingFuneralHome && !isFinalExpense'
						:address-line-1='funeralHome.addressLine1'
						:address-line-2='funeralHome.addressLine2'
						:city='funeralHome.city'
						:logo-url='funeralHome.logoUrl'
						:name='funeralHome.name'
						:phone='funeralHome.phone'
						:state='funeralHome.state'
						:zip='funeralHome.zip'
					/> -->
				</aside>
			</div>
		</div>
	</div>
</template>

<script lang='ts'>

import { computed, ref } from 'vue'
import { formatAsCurrency } from '../../../stringUtils'
import { useCalculatorStore } from '../store/calculator'
import { useEstimateStore } from '../store/estimate'
import { useBundleStore } from '../store/bundle'
import { useFhStore } from '../store/funeralHome'
import AddOnButton from './AddOnButton.vue'
import { preneedAddOns } from '@/intel/addOns'
import AdjustCoverage from './AdjustCoverage.vue'
import CheckboxInput from 'components/CheckboxInput.vue'
import FourPointStar from 'components/icons/FourPointStar.vue'
import FuneralHomeContactInfo from 'components/FuneralHomeContactInfo.vue'
import LinkButton from 'components/LinkButton.vue'
import QuestionSet from 'components/QuestionSet.vue'
import TermSelectButton from './TermSelectButton.vue'
import PlanItem from 'components/PlanItem.vue'

export default {
	name: 'PreneedEstimatePage',
	components:
	{
		AddOnButton,
		AdjustCoverage,
		CheckboxInput,
		FourPointStar,
		FuneralHomeContactInfo,
		LinkButton,
		PlanItem,
		QuestionSet,
		TermSelectButton,
	},
	emits: [
	],
	async setup() {
		const funeralHome = useFhStore()

		const isFinalExpense = computed(() => funeralHome.isFinalExpense)

		// Data value to track the inflation protection checkbox (final expense only)
		const inflationProtectionSelected = ref(isFinalExpense.value)

		return {
			bundle: useBundleStore(),
			calculator: useCalculatorStore(),
			estimate: useEstimateStore(),
			funeralHome: useFhStore(),
			inflationProtectionSelected,
			isFinalExpense,
		}
	},
	data() {
		return {
			AddOnList: preneedAddOns,
		}
	},
	computed:
	{
		/** Have we matched this estimate to a funeral home? */
		showingFuneralHome()
		{
			return this.estimate.visibleFh
		},

		/** Cents portion of the selected monthly payment amount */
		monthlyPaymentCents()
		{
			return this.currency(this.calculator.monthlyPayment(), true).split('.')[1]
		},

		/** Whole dollars portion of the selected monthly payment amount */
		monthlyPaymentDollars()
		{
			return this.currency(this.calculator.monthlyPayment(), true).split('.')[0]
		},

		/** Amount of the single payment, in whole dollars, formatted to string */
		singlePaymentFormatted(): string
		{
			return this.currency(this.calculator.singlePayPayment, false)
		},
	},
	methods:
	{
		currency: formatAsCurrency
	},
}

</script>

<style scoped>
	h3 {
		@apply text-3xl font-serif text-gray-900 font-normal
			pb-2;
	}

	h4 {
		@apply text-2xl font-serif text-gray-900 font-normal
			pb-1;
	}

	h5 {
		@apply text-xl font-serif text-gray-900 font-normal
			pt-3 pb-3;
	}
</style>
