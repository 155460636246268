import Version1 from './1/MainPage.vue'
// import Version2 from '2/MainPage'

// All supported versions for accessing saved estimates
const estimateVersions = [1]

// All supported versions for creating a new estimate
const newFlowVersions = [1]

// Version to use for a new estimate if no valid version was given
const defaultVersion = 1

export {
	Version1,
	// Version2
	defaultVersion,
	estimateVersions,
	newFlowVersions,
}
