Draws the Casket and Urn budget sliders
<template>
	<div>
		<div
			v-if='inline'
			class='flex justify-between'
		>
			<div class='grow-1'>
				<h4 class='text-2xl font-serif'>
					{{ productName }} Budget
				</h4><br />
			</div>
			<div class='grow-0'>
				<span class="text-2xl font-serif" data-testid='dispositionValue'>${{ selectedDispositionValue }}</span>
			</div>
		</div>

		<div
			v-else
			class='text-center mt-4 mb-1 lg:mt-12 lg:mb-12 text-4xl lg:text-5xl font-bold text-p-blue-400'
		>
			<span data-testid='dispositionValue'>${{ selectedDispositionValue }}</span>
		</div>

		<div class='flex flex-col items-center justify-center mb-4 lg:mt-8 lg:mb-8'>
			<div
				:class='getSliderClasses()'
			>
				<input
					:max='maxValue'
					:min='minValue'
					step='1'
					type='range'
					:value='budget'
					@input='$emit("update:budget", Number($event.target.value))'
				/>
				<div class='flex place-content-between mt-3'>
					<span class='text-left text-xs lg:text-md text-slate-600 lg:-translate-x-2/4'>
						Basic
					</span>
					<span class='text-center text-xs lg:text-md font-bold text-p-blue-400 lg:pl-40'>
						Average Cost
					</span>
					<span class='text-right text-xs lg:text-md text-slate-600 lg:translate-x-2/4'>
						Premium
					</span>
				</div>
			</div>

			<div
				v-if='!inline'
				class='
						w-full
						mt-6 p-2 lg:p-4
						rounded-sm
						bg-p-blue-200
					'
				:class="[visibleProducts.length > 4 ? 'xl:w-3/5' : 'lg:w-2/5']"
			>
				<div class='flex justify-center mt-2 mb-4'>
					<div
						v-if="selectedDisposition === Disposition.Cremation"
						class='
								flex flex-wrap
								justify-center
								text-center
								bg-p-blue-200
								rounded-full
								w-64
								cursor-pointer
								border-2
								border-p-blue-400
							'
					>
						<div
							class='p-1 rounded-full w-1/2'
							:class="[selectedUrnView == 'display' ? 'bg-p-blue-400 text-white' : '']"
							@click='$emit("update:selectedUrnView", "display")'
						>
							Display
						</div>
						<div
							class='p-1 rounded-full w-1/2'
							:class="[selectedUrnView == 'scattering' ? 'bg-p-blue-400 text-white' : '']
							"
							@click='$emit("update:selectedUrnView", "scattering")'
						>
							Scattering
						</div>
					</div>
				</div>

				<div class='mb-4 text-md text-left font-bold'>
					With this budget, you could afford the following {{ productName.toLowerCase() }} types:
				</div>
				<ul
					class='grid grid-col-1 grid-flow-col xl:grid-flow-row gap-2 w-full overflow-x-auto overflow-y-hidden'
					:class="[visibleProducts.length > 12 ? 'xl:grid-cols-6' : visibleProducts.length > 4 ? 'xl:grid-cols-4' : 'lg:grid-cols-2']"
				>
					<template
						v-for='product in visibleProducts'
						:key='product.name'
					>
						<li
							class='text-left pl-6 bg-no-repeat bg-[left_top_2px] cursor-pointer leading-5 min-w-32'
							:class="[ product.value <= budget ? 'bg-checkmark-circle' : 'bg-crossout text-slate-500' ]"
							@click='$emit("update:budget", Number(product.value))'
						>
							<span class='text-md'>{{ product.name }}</span> <br />
							<div class='text-md font-bold'>
								${{ Number(product.value).toLocaleString() }}
							</div>
							<div
								v-if='product.note'
								class='text-sm italic'
							>
								{{ product.note }}
							</div>
						</li>
					</template>
				</ul>
			</div>
		</div>
	</div>
</template>

<script lang="ts">

import { Disposition } from '../constants'

export default {
	name: 'ProductBudgetSlider',
	props:
	{
		budget:
		{
			default: 0,
			required: false,
			type: Number,
		},
		caskets:
		{
			type: Array,
			required: true,
		},
		inline: Boolean,
		selectedDisposition:
		{
			required: true,
			type: Number,
		},
		selectedUrnView:
		{
			default: '',
			type: String,
		},
		urns:
		{
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			Disposition,
		}
	},
	emits: [
		"update:selectedUrnView",
		"update:selectedDisposition",
		"update:budget",
	],
	computed:
	{
		/** Get the readable name of the product we are budgeting */
		productName()
		{
			switch (this.selectedDisposition)
			{
				case Disposition.Burial:
					return 'Casket'
				case Disposition.Cremation:
					return 'Urn'
				default:
					return 'Disposition'
			}
		},

		/** High end of the budget range */
		maxValue()
		{
			return Math.max(...this.products.map(o => o.value))
		},

		/** Low end of the budget range */
		minValue()
		{
			return Math.min(...this.products.map(o => o.value))
		},

		products()
		{
			if (this.selectedDisposition === Disposition.Cremation)
			{
				return this.urns
			}
			else if (this.selectedDisposition === Disposition.Burial)
			{
				return this.caskets
			}
			else if (this.selectedDisposition === Disposition.Undecided)
			{
				return [].concat(this.urns).concat(this.caskets)
			}
			else
			{
				return []
			}
		},

		selectedDispositionValue()
		{
			return Number(this.budget).toLocaleString()
		},

		visibleProducts()
		{
			if (this.selectedDisposition === Disposition.Cremation)
			{
				return this.products.filter(urn => urn.type === this.selectedUrnView)
			}

			return this.products
		},
	},
	methods:
	{
		getSliderClasses()
		{
			if (this.inline)
			{
				return 'w-full'
			}
			return 'w-full p-4 lg:w-8/12'
		},
	},
}

</script>

<style scoped lang="less">

	@track-color: #E6F0F5;
	@thumb-color: #fff;

	@thumb-radius: 20px;
	@thumb-height: 40px;
	@thumb-width: 40px;
	@thumb-shadow-size: 1px;
	@thumb-shadow-blur: 1px;
	@thumb-shadow-color: #111;
	@thumb-border-width: 1px;
	@thumb-border-color: white;

	@track-width: 100%;
	@track-height: 40px;
	@track-shadow-size: 2px;
	@track-shadow-blur: 2px;
	@track-shadow-color: #222;
	@track-border-width: 1px;
	@track-border-color: #E6F0F5;

	@track-radius: 20px;
	@contrast: 5%;

	.shadow(@shadow-size, @shadow-blur, @shadow-color) {
		box-shadow: @shadow-size @shadow-size @shadow-blur @shadow-color, 0px 0px @shadow-size lighten(@shadow-color, 5%);
	}

	.track() {
		width: @track-width;
		height: @track-height;
		cursor: pointer;
	}

	.thumb() {
		.shadow(@thumb-shadow-size, @thumb-shadow-blur, @thumb-shadow-color);
		border: @thumb-border-width solid @thumb-border-color;
		height: @thumb-height;
		width: @thumb-width;
		border-radius: @thumb-radius;
		background: @thumb-color;
		cursor: grab;
		cursor: ew-resize;
	}

	input[type=range] {
		-webkit-appearance: none;
		margin: @thumb-height/2 0;
		width: @track-width;
		border-radius: @track-radius;
		overflow: hidden;

		&:focus {
			outline: none;
		}

		&::-webkit-slider-runnable-track {
			.track();
			// .shadow(@track-shadow-size,@track-shadow-blur,@track-shadow-color);
			background: @track-color;
			border-radius: @track-radius;
			border: @track-border-width solid @track-border-color;
			// overflow: hidden;
		}

		&::-webkit-slider-thumb {
			.thumb();
			-webkit-appearance: none;
			margin-top: ((-@track-border-width * 2 + @track-height) / 2) - (@thumb-height / 2);
			box-shadow: 2px 0px 3px 0px rgba(0, 0, 0, 0.36), -5020px 0px 0px 5000px #006699;

		}

		&:focus::-webkit-slider-runnable-track {
			background: lighten(@track-color, @contrast);
		}

		&::-moz-range-track {
			.track();
			.shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
			background: @track-color;
			border-radius: @track-radius;
			border: @track-border-width solid @track-border-color;
		}

		&::-moz-range-thumb {
			.thumb();
		}

		&::-ms-track {
			.track();
			background: transparent;
			border-color: transparent;
			border-width: @thumb-width 0;
			color: transparent;
		}

		&::-ms-fill-lower {
			background: darken(@track-color, @contrast);
			border: @track-border-width solid @track-border-color;
			border-radius: @track-radius*2;
			.shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
		}

		&::-ms-fill-upper {
			background: @track-color;
			border: @track-border-width solid @track-border-color;
			border-radius: @track-radius*2;
			.shadow(@track-shadow-size, @track-shadow-blur, @track-shadow-color);
		}

		&::-ms-thumb {
			.thumb();
		}

		&:focus::-ms-fill-lower {
			background: @track-color;
		}

		&:focus::-ms-fill-upper {
			background: lighten(@track-color, @contrast);
		}
	}

</style>
