/**
 * String.format() to provide parameter based templating like sprintf
 * @returns String
 */
export const format = function(s: string, ...args): string
{
	let i = arguments.length;

	while (i--) {
		s = s.replace(new RegExp('\\{' + (i) + '\\}', 'gm'), args[i]);
	}
	return s;
}

export const formatKeys = function(s: string, args): string
{
	for (const key in args) {
		s = s.replace(new RegExp('\\{' + (key) + '\\}', 'gm'), args[key]);
	}

	return s;
}

/**
 * String.titleCase() to convert a lower case string to
 * have first character of each word upper case
 * @param {String} str
 * @returns String
 */
export const titleCase = function(str: string)
{
	return str.toLowerCase().split(' ').map(function(word) {
		return (word.charAt(0).toUpperCase() + word.slice(1));
	}).join(' ');
}



/**
 * String.formatAsCurrency() converts a number
 * to a rounded value with a dollar sign
 * @param {String} value
 * @returns String
 */
export const formatAsCurrency = function(value: number, showCents = false)
{
	const digits = showCents ? 2 : 0

	// return a dash for 0 or NaN values
	if (isNaN(value) || value === 0)
	{
		return '-'
	}
	value = Math.round(value * 100) / 100
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: digits,
		maximumFractionDigits: digits,
	})
	return formatter.format(value)
}

export const startsWith = function(needle, haystack)
{
	return haystack.substring(0, needle.length) === needle
}
