// Cremation with memorial service, no viewing
import Bundle from '../Bundle'

const bundle: Bundle = {
	name: 'Cremation with memorial service',
	baseCost: 4361,
	includes: [
		'Cremation services',
		'Basic services of licensed funeral director and staff',
		'Professional care of the deceased',
		'Refrigeration',
		'{service} at funeral home or other location',
		'Transfer of the body to funeral home',
		'Funeral coach or flower car',
	],
	excludes: [
		'Viewing at a funeral home',
	],
}

export default bundle
