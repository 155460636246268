<template>
	<div
		class='affirm-page'
	>
		<QuestionSet>
			<template #question>
				{{ title }}
			</template>
		</QuestionSet>
		<Spinner class='mt-16' />
	</div>
</template>

<script>

import {Pages} from './lang'
import QuestionSet from 'components/QuestionSet.vue'
import Spinner from 'components/Spinner.vue'
import { format } from '../../stringUtils'

/**
 * @returns {Promise} Promise that resolves after given ms delay
 */
function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

export default
{
	name: 'AffirmPage',
	components:
		{
			QuestionSet,
			Spinner
		},
	props:
		{
			autoNext:
			{
				default: null,
				type: String,
			},
			/**
			 * Function to execute.
			 * Page will wait for Promise to resolve before navigating.
			 */
			job:
			{
				default: () => null,
				type: Function,
			},
			name:
			{
				default: null,
				required: true,
				type: String,
			},
			titleParams:
			{
				default: () => [],
				type: Array
			},
		},
	emits: ['navigate'],
	data()
	{
		return {
			transitionTime: import.meta.env.VITE_AFFIRM_DELAY || 3000
		}
	},
	computed:
		{
			title()
			{
				if (
					Pages[this.name] &&
					Object.prototype.hasOwnProperty.call(Pages[this.name], 'title')
				){
					return format(Pages[this.name].title, ...this.titleParams)
				}
				return ''
			},
		},

	async mounted() {
		this.hide = false

		window.scrollTo(0, 0)

		const jobPromise = this.job ? this.job() : null

		// wait 3 seconds; then auto navigate to the next page
		await Promise.all([delay(this.transitionTime), jobPromise])

		if (this.autoNext)
		{
			this.$emit('navigate', this.autoNext)
		}
	},
}

</script>


<style lang="less" scoped>
	.affirm-page {
		text-align: -webkit-center;

		&.hide {
			display: none;
		}
	}
</style>
