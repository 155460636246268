<template>
  <div>
    <main>
      <!-- NOTE: THERE ARE CURRENTLY 2 NAV LOGOS AND 2 TS&CS ELEMENTS. ANY CHANGES NEED TO BE MADE FOR AND CHECKED ON MOBILE AND DESKTOP SIZED SCREENS -->
      <!-- NEEDS REFACTOR TO ELIMINATE DOUBLE IMAGE AND PRIVACY -->
      <nav
        class="flex flex-col flex-col-reverse p-0 sm:flex-row sm:text-left sm:justify-between sm:py-8 sm:px-12 bg-white shadow sm:items-center w-full"
      >
        <img
          v-if='legacy'
          class="hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]"
          src="@/assets/legacy_logo.png"
        />
        <img
          v-else
          class="hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[50px] lg:w-[162px]"
          src='@/assets/preplan_today_logo.svg'
        />

        <div class="sm:hidden flex ml-6 mr-6 mt-2 items-center justify-between">
          <img
            v-if='legacy'
            class="mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]"
            src="@/assets/legacy_logo.png"
          />
          <img
            v-else
            class="mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]"
            src='@/assets/preplan_today_logo.svg'
          />

          <div
            class="whitespace-nowrap text-[10px] text-right sm:block w-[100px] text-slate-500"
          >
            <a href="/terms" target="_blank">Terms</a> |
            <a href="/privacy" target="_blank">Privacy</a>
          </div>
        </div>

        <div
          class="whitespace-nowrap text-[10px] text-right sm:text-xs hidden sm:block w-[100px] lg:h-[30px] lg:w-[162px] text-slate-500 lg:pt-2"
        >
          <a href="/terms" target="_blank">Terms</a> |
          <a href="/privacy" target="_blank">Privacy</a>
        </div>
      </nav>

      <Helper class="pt-2 pb-4" />
    </main>

    <div class="sm:flex sm:justify-center mt-10">
      <div class="max-w-screen-xl">
        <QuestionSet>
          <template #question>
            Wonderful! An agent will be in touch soon.
          </template>
        </QuestionSet>

        <h3
          class="max-w-screen-2xl lg:block text-lg lg:text-2xl text-center mt-6"
        >
          You can expect a call in 24–48 hours. Our agents will help finalize
          your coverage and make sure your budget is perfect for your needs.
        </h3>

        <div class="mt-8 md:mt-16 pl-4 pr-4">
          <div
            class="p-8 md:p-24 rounded-xl border-2 border-p-green bg-p-grey-200"
          >
            <h4 class="text-xl md:text-3xl text-center">
              What to expect from your call:
            </h4>
            <div
              class="mt-8 md:mt-12 grid md:grid-cols-3 gap-8 md:gap-24 mt-4 text-md"
            >
              <div>
                <img src="@/assets/thumbs-up.svg" alt="" class="mb-4" />
                Personalized advice on your specific funeral plan
              </div>
              <div>
                <img src="@/assets/heart.svg" alt="" class="mb-4" />
                Get your questions answered so you can make an informed decision
              </div>
              <div>
                <img src="@/assets/piggy-bank.svg" alt="" class="mb-4" />
                Assistance finding finalizing and funding your funeral plan
              </div>
            </div>
          </div>
        </div>

        <div
          v-if='legacy'
          class="mt-8 mb-8 flex justify-center pl-8 pr-8"
        >
          <LinkButton
            to="https://www.legacy.com"
            class="w-full h:w-fit lg:w-96"
          >
            Back to Legacy.com
          </LinkButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LinkButton from "components/LinkButton.vue";
import QuestionSet from "components/QuestionSet.vue";
import Helper from "components/Helper.vue";
</script>

<script>
export default
{
	data()
	{
		return {
			// false if default logo, true if Legacy logo
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),
		}
	}
}
</script>
