Component to accept input values from user
<template>
	<div
		class='text-input relative h-16'
		:class='{ empty: isEmpty }'
	>
		<input
			:autocomplete='autocomplete'
			class='h-full w-full pr-2 transition-all border-2 border-p-gold-400 rounded-md'
			:class="{'pl-12': src, 'pl-4': !src}"
			:maxlength='maxlength'
			:name='name'
			:type='type'
			:value='modelValue'
			@input='updateValue'
			:id="id"
		/>
		<label
			class='pointer-events-none absolute transition-all bg-white px-1'
			:class="{'left-12': src, 'left-4': !src}"
			:for='id'
		>
			{{ label }}
		</label>
		<component
			:is='src'
			v-if='src'
			:active='!isEmpty'
			class='pointer-events-none h-8 w-8 absolute top-3 left-3'
		/>
	</div>
</template>

<script>

import BirthdayIcon from '@/components/icons/BirthdayIcon.vue'
import PersonIcon from '@/components/icons/PersonIcon.vue'

export default {
	name: 'TextInput',
	components: {
		BirthdayIcon,
		PersonIcon,
	},
	props: {
		autocomplete: {
			default: '',
			type: String,
		},
		imgBirthday: Boolean,
		imgPerson: Boolean,
		label: {
			default: '',
			type: String,
		},
		maxlength: {
			default: '',
			type: String,
		},
		modelValue: {
			default: '',
			type: String
		},
		name: {
			default: 'text',
			type: String,
		},
		type: {
			default: 'text',
			type: String,
		},
	},
	emits: ['update:modelValue'],
	computed: {
		/** @return {String|null} URL of image we want to embed in input */
		src() {
			if (this.imgBirthday) {
				return 'BirthdayIcon'
			}
			if (this.imgPerson) {
				return 'PersonIcon'
			}
			return null
		},

		/** id so label can be tied to input */
		id() {
			return 'input-' + this.name
		},

		isEmpty() {
			return this.modelValue == "";
		},
	},
	methods: {
		updateValue(event) {
			this.$emit('update:modelValue', event.target.value.trim())
		}
	},
}
</script>

<style scoped lang="css">
	label {
		top: 0%;
		transform: translateY(-50%);
		font-size: 11px;
		color: rgba(37, 99, 235, 1);
		/* letter-spacing: .4px; */
	}
	.empty input:not(:focus) + label {
		top: 50%;
		transform: translateY(-50%);
		font-size: 16px;
	}
	input:not(:focus) + label {
		color: rgba(70, 70, 70, 1);
	}

	input:focus {
		outline: none;
		border-color: rgba(37, 99, 235, 1);
	}

	/* Remove input arrows from Chrome, Safari, Edge, Opera */
	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Remove input arrows from Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}

	.empty input:not(:focus) {
		border-color: theme(colors.gray.300);
	}

</style>
