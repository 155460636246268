<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
		</QuestionSet>

		<div class='flex max-w-xl w-full justify-center mx-auto flex-col p-5'>
			<div class='mt-4 lg:mt-8'>
				<div class='flex space-x-12 justify-center '>
					<RadioOption
						v-model='planningForSelf'
						name='planningForSelf'
						label='Myself'
						:value='true'
					/>
					<RadioOption
						v-model='planningForSelf'
						name='planningForSelf'
						label='A loved one'
						:value='false'
					/>
				</div>

				<div v-if='planningForSelf !== false'>
					<div class='mt-8 grid grid-cols-2 gap-4'>
						<TextInput
							v-model='informantFirstName'
							img-person
							label='First Name'
							name='firstname'
							type='text'
						/>
						<TextInput
							v-model='informantLastName'
							label='Last Name'
							name='lastname'
							type='text'
						/>
					</div>

					<div class='text-sm text-left mt-4 ml-2 mb-1 birthdate'>
						Birthday
					</div>
				</div>
				<div v-else>
					<div class='text-sm text-left mt-4 ml-2 mb-1'>
						Loved one's Name
					</div>
					<div class='mt-2 grid grid-cols-2 gap-4'>
						<TextInput
							v-model='planningForFirstName'
							img-person
							label='First Name'
							name='firstname'
							type='text'
						/>
						<TextInput
							v-model='planningForLastName'
							label='Last Name'
							name='lastname'
							type='text'
						/>
					</div>

					<div class='text-sm text-left mt-4 ml-2 mb-1'>
						Your Name
					</div>
					<div class='mt-2 grid grid-cols-2 gap-4'>
						<TextInput
							v-model='informantFirstName'
							img-person
							label='First Name'
							name='firstname'
							type='text'
						/>
						<TextInput
							v-model='informantLastName'
							label='Last Name'
							name='lastname'
							type='text'
						/>
					</div>

					<div class='text-sm text-left mt-4 ml-2 mb-1 birthdate'>
						Loved one's Birthday
					</div>
				</div>

				<div class='birthdate'>
					<div class='grid grid-flow-col gap-4'>
						<TextInput
							v-model='birthMonth'
							img-birthday
							label='MM'
							name='month'
							type='number'
						/>
						<TextInput
							v-model='birthDay'
							label='DD'
							name='day'
							type='number'
						/>
						<TextInput
							v-model='birthYear'
							label='YYYY'
							name='year'
							type='number'
						/>
					</div>
				</div>
				<div
					class="
						text-left
						italic
						mt-4
						pl-5
						pr-4
						bg-[url('@/assets/info.svg')]
						bg-no-repeat
						bg-[left_top_.4rem]
						birthdate
					"
				>
					{{ labelActions.getLabel(screenName, 'info') }}
				</div>
			</div>
		</div>
	</flowscreen>
</template>

<script>

import { Actions } from '../lang'
import { DateTime } from 'luxon'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'
import RadioOption from 'components/RadioOption.vue'
import TextInput from 'components/TextInput.vue'
import Validators from '@/validators'

export default {
	name: 'PreNeedWho',
	components:
	{
		FlowScreen,
		QuestionSet,
		RadioOption,
		TextInput
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {

			birthDay: '',
			birthMonth: '',
			birthYear: '',

			/**
				* This page/screen name
				*/
			screenName: 'preNeedWho',

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.planningForSelf === null)
			{
				errors.push('Please select who is being planned for.')
			}

			if (this.planningForSelf === false)
			{
				if (this.planningForFirstName === '')
				{
					errors.push('Please enter the first name of the person who is being planned for.')
				}

				if (this.planningForLastName === '')
				{
					errors.push('Please enter the last name of the person who is being planned for.')
				}
			}

			if (this.informantFirstName === '')
			{
				errors.push('Please enter your first name.')
			}
			if (this.informantLastName === '')
			{
				errors.push('Please enter your last name.')
			}

			if (!Validators.birthdate(this.birthYear, this.birthMonth, this.birthDay))
			{
				errors.push('Please enter a valid birth date for the person who is being planned for.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, [
			'planningForSelf',
			'informantFirstName',
			'informantLastName',
			'planningForFirstName',
			'planningForLastName',
			'planningForBirthdate',
		]),
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "preNeedWhoAffirm")
		},

		// Create a luxon date object for birthdate and save to store
		constructBirthdate()
		{
			const date = DateTime.fromISO(this.birthYear.padStart(4, '0') + '-' +
				this.birthMonth.padStart(2, '0') + '-' + this.birthDay.padStart(2, '0'))

			if (Validators.birthdate(this.birthYear, this.birthMonth, this.birthDay))
			{
				this.planningForBirthdate = date
			}
		}
	},
	mounted()
	{
		// If we already have a birthdate, deconstruct it into the inputs
		if (this.planningForBirthdate && this.planningForBirthdate.isValid) {
			this.birthDay = this.planningForBirthdate.day.toString()
			this.birthMonth = this.planningForBirthdate.month.toString()
			this.birthYear = this.planningForBirthdate.year.toString()
		}
	},
	watch:
	{
		// When birth day, month, or year are updated, save to the store
		birthDay() {
			this.constructBirthdate()
		},
		birthMonth() {
			this.constructBirthdate()
		},
		birthYear() {
			this.constructBirthdate()
		},
	}
}

</script>
