<template>
	<FlowScreen
		:errors='errors'
		no-going-back
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title', constants.SYSTEM_NAME) }}
			</template>
			<template #subtitle>
				{{ labelActions.getLabel(screenName, 'subTitle') }}
			</template>
		</QuestionSet>

		<div class='cards-spacer'>
			<Cards>
				<Card
					:active='selectedTypeOfNeed === Need.Preneed'
					@click='selectedTypeOfNeed = Need.Preneed'
				>
					<template #icon>
						<PersonAtDeskIcon :active='selectedTypeOfNeed === Need.Preneed' />
					</template>
					<template #title>
						Planning ahead
					</template>
					<template #sub-title>
						I want to prepare for the future
					</template>
				</Card>
				<Card
					:active='selectedTypeOfNeed === Need.AtNeed'
					@click='selectedTypeOfNeed = Need.AtNeed'
				>
					<template #icon>
						<CandleIcon :active='selectedTypeOfNeed === Need.AtNeed' />
					</template>
					<template #title>
						Immediate need
					</template>
					<template #sub-title>
						Someone recently passed away or will pass soon
					</template>
				</Card>
				<Card
					:active='selectedTypeOfNeed === Need.Browsing'
					@click='selectedTypeOfNeed = Need.Browsing'
				>
					<template #icon>
						<PersonQuestionMarkIcon :active='selectedTypeOfNeed === Need.Browsing' />
					</template>
					<template #title>
						Just browsing
					</template>
					<template #sub-title>
						Not sure yet, just browsing at the moment.
					</template>
				</Card>
			</Cards>
		</div>
	</FlowScreen>
</template>

<script>

import { Actions, Errors, Main } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'
import { Need } from '../../../constants'

import CandleIcon from 'components/icons/CandleIcon.vue'
import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FlowScreen from '../FlowScreen.vue'
import PersonAtDeskIcon from 'components/icons/PersonAtDeskIcon.vue'
import PersonQuestionMarkIcon from 'components/icons/PersonQuestionMarkIcon.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'TypeOfNeed',
	components:
	{
		CandleIcon,
		Card,
		Cards,
		FlowScreen,
		PersonAtDeskIcon,
		PersonQuestionMarkIcon,
		QuestionSet,
	},
	props:
	{
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/** Enum for type of user's need */
			Need,

			/**
			* Version level constants / string labels
			*/
			constants: Main,

			/**
			* This page/screen name
			*/
			screenName: 'typeOfNeed',

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		...mapWritableState(useEstimateStore, ['selectedTypeOfNeed', 'planningForSelf']),
		errors()
		{
			let errors = []

			if (this.selectedTypeOfNeed === Need.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
	},
	methods:
	{
		navigateNext()
		{
			if ([Need.Preneed, Need.Browsing].includes(this.selectedTypeOfNeed))
			{
				this.$emit('navigate', "preNeedAffirm")
			}
			else
			{
				this.planningForSelf = false
				this.$emit('navigate', "atNeedAffirm")
			}
		},
	}
}

</script>
