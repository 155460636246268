import Bundle from '../Bundle'

const traditional: Bundle = {
	name: 'Full traditional cremation',
	baseCost: 6161,
	includes: [
		'Cremation services',
		'Basic services of licensed funeral director and staff',
		'Professional care of the deceased',
		'Embalming, dressing, casketing, other preparations of the remains for viewing',
		'Viewing at a funeral home',
		'{service} at a funeral home or other location',
		'Transfer of the body to funeral home',
		'Funeral coach or flower car',
	],
	excludes: [],
}

export default traditional
