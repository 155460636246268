import { createRouter, createWebHistory } from 'vue-router'
import ComponentsView from '../views/ComponentsView.vue'
import ContactedView from '../views/ContactedView.vue'
import EstimateView from '../views/EstimateView.vue'
import HomeView from '../views/HomeView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'

import {estimateVersions, newFlowVersions, defaultVersion} from '../versions/versions'

const newFlowRegex = '(' + newFlowVersions.join('|') + ')'
const estimateRegex = '(' + estimateVersions.join('|') + ')'

const redirectToHome = to => ({
	name: 'home',
	params: {v: defaultVersion},
	query: to.query,
})

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			// Home: start of the flow
			path: '/v:v' + newFlowRegex + '/:screen?',
			name: 'home',
			strict: false,
			component: HomeView,
			props: true,
		},
		{
			// Send to home @ default version
			path: '/',
			name: 'root',
			redirect: redirectToHome,
		},
		{
			// Redirect old start URL to new start URL
			path: '/questions/:question?',
			strict: false,
			redirect: redirectToHome,
		},
		{
			// Catch all incorrect versions and send to default version
			path: '/v:v',
			redirect: redirectToHome,
		},
		{
			path: '/v:v' + estimateRegex + '/estimate/:tags([^/]+)*/:estimateId',
			name: 'estimate',
			component: EstimateView,
			props: true,
		},
		{
			// Attempting to access estimate w/ unsupported version
			path: '/v:v/estimate/:tags([^/]+)*/:estimateId',
			redirect: redirectToHome,
		},
		{
			// Estimate "contacted" page
			path: '/v:v' + estimateRegex + '/estimate/:tags([^/]+)*/:estimateId/contact',
			name: 'contact',
			component: ContactedView,
			props: true,
		},
		{
			// Attempting to access estimate contacted w/ unsupported version
			path: '/v:v/estimate/:tags([^/]+)*/:estimateId/contact',
			redirect: redirectToHome,
		},
		{
			path: '/terms',
			name: 'terms',
			component: TermsAndConditions
		},
		{
			path: '/privacy',
			name: 'privacy',
			component: PrivacyPolicy
		},
		{
			// Debug page
			path: '/components',
			name: 'components',
			component: ComponentsView
		},
		{
			// Catch-all
			path: '/:route(.*)',
			strict: false,
			redirect: redirectToHome,
		},
	],
})

export default router
