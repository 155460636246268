Radio Selector for single selections
<template>
	<label
		class='radio'
		:for='label'
	>
		<input
			:id='label'
			:checked='modelValue === value'
			:name='name'
			type='radio'
			:value='value'
			@input='updateValue'
		/>
		{{ label }}
	</label>
</template>

<script>

export default {
	name: 'RadioOption',
	props:
	{
		name:
		{
			required: true,
			type: String,
		},
		label:
		{
			required: true,
			type: String,
		},
		modelValue:
		{
			default: '',
			type: null
		},
		value:
		{
			type: null,
			required: true,
		}
	},
	emits: ['update:modelValue'],
	data () {
		return {
		}
	},
	computed:
	{
	},
	methods: {
		updateValue() {
			return this.$emit('update:modelValue', this.value)
		}
	},
}

</script>

<style scoped lang="css">

	.radio {
		/* font-family: system-ui, sans-serif; */
		font-size: 22px;
		/* font-weight: bold; */
		display: flex;
		line-height: 22px;
		color: black;
		cursor: pointer;
		/* display: grid; */
		/* grid-template-columns: 1em auto; */
		/* gap: 0.5em; */
	}

	.radio:focus-within {
		color: theme(colors.p-gold.400);
	}

	.radio input[type="radio"] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: white;
		/* Not removed via appearance */
		margin: 0;

		font: inherit;
		color: theme(colors.p-gold.400);
		width: 1.4em;
		height: 1.4em;
		border: 0.15em solid currentColor;
		border-radius: 50%;
		transform: translateY(-0.075em);

		display: grid;
		place-content: center;

		margin-right: 8px;
		cursor: pointer;
	}

	.radio input[type="radio"]::before {
		content: "";
		width: 0.85em;
		height: 0.85em;
		border-radius: 50%;
		transform: scale(0);
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em theme(colors.p-gold.400);
		/* Windows High Contrast Mode */
		background-color: CanvasText;
	}

	.radio input[type="radio"]:checked::before {
		transform: scale(1);
	}

</style>
