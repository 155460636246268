import Bundle from '../Bundle'

const bundle: Bundle = {
	name: 'Direct burial',
	baseCost: 3843,
	includes: [
		'Basic services of licensed funeral director and staff',
		'Professional care of the deceased',
		'Body preparation',
		'Transfer of the body to funeral home',
		'Funeral coach or flower car',
	],
	excludes: [
		'Viewing at a funeral home',
		'Service at a funeral home or other location',
	],
}

export default bundle
