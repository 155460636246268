<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title', product) }}
			</template>
		</QuestionSet>

		<ProductBudgetSlider
			v-model:budget='selectedDispositionBudget'
			v-model:selected-disposition='selectedDisposition'
			v-model:selected-urn-view='selectedUrnView'
			:caskets='caskets'
			:urns='urns'
		/>
	</FlowScreen>
</template>

<script lang='ts'>

import { Actions, DispositionProductNames } from '../lang'
import { Caskets, Urns } from '../products'
import { Disposition } from '../../../constants'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import FlowScreen from '../FlowScreen.vue'
import ProductBudgetSlider from 'components/ProductBudgetSlider.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedProductBudget',
	components:
	{
		FlowScreen,
		ProductBudgetSlider,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/**
			 * Define which casket options there are;
			 * Imported from products.js
			 */
			caskets: Caskets,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedProductBudget',

			/**
			 * Define which urn options there are;
			 * Imported from products.js
			 */
			urns: Urns,
		}
	},
	computed:
	{
		/** @returns {string} name of the selected disposition product */
		product()
		{
			return DispositionProductNames.get(this.selectedDisposition)
		},
		errors()
		{
			let errors = []

			if (this.selectedDisposition === Disposition.Blank)
			{
				errors.push('Please select casket or urn.')
			}

			if (this.selectedDisposition === Disposition.Cremation && this.selectedUrnView === '')
			{
				errors.push('Please select display or scattering.')
			}

			if (this.selectedDispositionBudget === '')
			{
				errors.push('Please set a budget.')
			}

			return errors
		},
		...mapWritableState(useEstimateStore, [
			'selectedDisposition',
			'selectedUrnView',
			'selectedDispositionBudget',
		])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "atNeedProductBudgetAffirm")
		},
	},
}

</script>
