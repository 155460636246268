// Direct cremation
import Bundle from '../Bundle'

const bundle: Bundle = {
	name: 'Direct cremation',
	baseCost: 2708,
	includes: [
		'Cremation services',
		'Basic services of licensed funeral director and staff',
		'Professional care of the deceased',
		'Transfer of the body to funeral home',
	],
	excludes: [
		'Viewing at a funeral home',
		'Service at a funeral home or other location',
	],
}

export default bundle
