An svg of balloons
<template>
	<svg
		class='balloons-icon svg-flex-sizing'
		fill='none'
		preserveAspectRatio='xMidYMid meet'
		viewBox='0 0 125 154'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M66.4384 88.903C66.3038 88.8626 66.1848 88.7814 66.0965 88.6698C66.0082 88.5583 65.9547 88.4215 65.9427 88.2768C65.9307 88.1321 65.9608 87.9861 66.0292 87.8574C66.0976 87.7287 66.2011 87.6231 66.3266 87.5541L69.3358 85.9007C75.4437 82.5366 80.1427 76.9416 82.4923 70.2359L83.7949 66.5577C83.8616 66.3764 83.9943 66.228 84.1643 66.1446C84.3343 66.0613 84.5279 66.0497 84.7031 66.1125C84.8783 66.1753 85.0211 66.3073 85.1005 66.4799C85.1799 66.6526 85.1895 66.852 85.1273 67.0349L83.8254 70.7107C81.3537 77.7656 76.41 83.6518 69.984 87.1906L66.9735 88.8436C66.8082 88.9352 66.6159 88.9565 66.4384 88.903Z'
			fill='#222433'
		/>
		<path
			:class='getClasses'
			d='M110.346 56.8867C116.598 45.4848 114.825 32.5092 106.385 27.9048C97.9462 23.3004 86.0368 28.8108 79.785 40.2127C73.5332 51.6145 75.3065 64.5901 83.7457 69.1945C92.185 73.7989 104.094 68.2885 110.346 56.8867Z'
		/>
		<path
			d='M63.2381 93.0822C63.0686 93.0312 62.9088 92.9564 62.7711 92.8634L59.7328 90.8153C55.5811 88.0402 52.5335 84.6309 51.0395 81.0903C50.956 80.8875 50.9882 80.6907 51.1292 80.543C51.2702 80.3953 51.5086 80.3085 51.7924 80.3015C52.0762 80.2946 52.3825 80.368 52.6445 80.5059C52.9066 80.6437 53.1032 80.8348 53.1915 81.0375C54.5479 84.2517 57.3145 87.3466 61.0834 89.8659L64.1239 91.9147C64.3265 92.0513 64.4715 92.2201 64.5353 92.3938C64.5991 92.5674 64.5779 92.7356 64.4752 92.871C64.3725 93.0064 64.1944 93.101 63.9697 93.1395C63.7451 93.1781 63.4871 93.1582 63.2378 93.0831L63.2381 93.0822Z'
			fill='#222433'
		/>
		<path
			d='M63.6811 147.016C63.5486 146.977 63.4294 146.902 63.3364 146.799C63.2434 146.697 63.1801 146.572 63.1533 146.437L61.5566 138.386C58.1982 121.475 59.4353 103.99 65.14 87.741C65.8602 85.6966 66.5804 83.6521 67.2794 81.6783C70.8831 71.4108 70.6596 60.1855 66.6496 50.0466C66.5767 49.8602 66.5809 49.6528 66.6613 49.4699C66.7416 49.287 66.8916 49.1435 67.0783 49.0707C67.265 48.9979 67.4733 49.0018 67.6576 49.0815C67.8418 49.1613 67.9871 49.3104 68.0615 49.4962C72.2054 59.9734 72.4362 71.5733 68.7116 82.1832C68.0127 84.1568 67.293 86.1998 66.5727 88.2442C60.9573 104.239 59.7396 121.45 63.0456 138.097L64.6424 146.148C64.6677 146.276 64.6595 146.408 64.6185 146.531C64.5775 146.655 64.5051 146.765 64.4084 146.853C64.3117 146.94 64.1939 147.001 64.0665 147.029C63.939 147.058 63.8062 147.053 63.681 147.016L63.6811 147.016Z'
			fill='#222433'
		/>
		<path
			d='M65.9391 52.1907C74.1457 49.7287 78.2245 39.2997 75.0495 28.897C71.8745 18.4942 62.6479 12.057 54.4413 14.519C46.2348 16.981 42.1559 27.41 45.3309 37.8127C48.5059 48.2155 57.7325 54.6527 65.9391 52.1907Z'
			fill='white'
		/>
		<path
			d='M53.9445 50.8501C49.7964 48.1939 46.3269 43.6751 44.6042 38.0307C43.0189 32.8387 43.1281 27.4687 44.9101 22.9123C46.7172 18.2924 50.0235 15.0546 54.2199 13.7957C62.8136 11.2177 72.4837 17.894 75.7749 28.6795C79.0661 39.465 74.7528 50.3362 66.1591 52.9142C62.0631 54.1429 57.7222 53.2691 53.9445 50.8501ZM65.6202 17.1187C62.2102 14.9352 58.3233 14.1442 54.6617 15.2428C50.9202 16.3645 47.9598 19.284 46.3243 23.4638C44.6653 27.7068 44.5707 32.7258 46.0562 37.5949C47.6721 42.8897 50.9115 47.1272 54.7585 49.5912C58.1685 51.7747 62.0555 52.5655 65.717 51.4671C73.5099 49.1297 77.3707 39.102 74.3225 29.1147C72.707 23.8205 69.4679 19.5826 65.6202 17.1187Z'
			fill='#222433'
		/>
		<path
			:class='getClasses'
			d='M54.665 58.7188C58.5636 68.0546 56.7439 77.8858 50.7577 82.0336C50.111 82.4835 49.4182 82.8631 48.6909 83.1658C41.0925 86.3462 31.4422 80.5213 27.1263 70.2046C22.8117 59.8662 25.5021 48.9379 33.1004 45.7575C40.7204 42.5785 50.372 48.3817 54.665 58.7188Z'
		/>
		<path
			d='M108.82 39.5643C106.736 37.2329 104.352 39.7134 105.326 41.9244C107.058 45.855 106.32 48.1198 105.882 49.3381C108.021 47.2102 111.018 42.0232 108.82 39.5643Z'
			fill='white'
			stroke='white'
		/>
		<path
			d='M40.5871 50.0978C37.5451 50.0402 37.679 53.3517 39.8882 54.1142C43.8154 55.4698 44.908 57.4768 45.4683 58.5852C45.4166 55.6783 43.7955 50.1586 40.5871 50.0978Z'
			fill='white'
			stroke='white'
		/>
		<path
			d='M65.1378 22.2946C61.8141 21.5008 61.1656 25.1662 63.4064 26.5333C67.3897 28.9638 68.1064 31.4282 68.4549 32.7789C69.0963 29.5772 68.6434 23.1318 65.1378 22.2946Z'
			fill='white'
			stroke='#00141E'
		/>
	</svg>
</template>

<script>

export default {
	name: 'BalloonsIcon',
	props:
	{
		active:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	computed:
	{
		getClasses()
		{
			return {
				'active': this.active,
				'inactive': !this.active,
			}
		},
	},
}

</script>

<style scoped lang="less">
	.balloons-icon {
		.active {
			fill: #82B5A5;
		}
		.inactive {
			fill: #CCD0D2;
		}
	}
</style>
