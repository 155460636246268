<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
		</QuestionSet>

		<Cards class='cards-spacer'>
			<Card
				:active='selectedTypeOfCelebration === Service.Traditional'
				@click='selectedTypeOfCelebration = Service.Traditional'
			>
				<template #icon>
					<CandleIcon :active='selectedTypeOfCelebration === Service.Traditional' />
				</template>
				<template #title>
					Traditional Service
				</template>
				<template #sub-title>
					A formal service for loved ones to cherish what made you unique. Typically held at a funeral home.
				</template>
			</Card>

			<Card
				:active='selectedTypeOfCelebration === Service.Celebration'
				@click='selectedTypeOfCelebration = Service.Celebration'
			>
				<template #icon>
					<BalloonsIcon :active='selectedTypeOfCelebration === Service.Celebration' />
				</template>
				<template #title>
					Celebration of Life
				</template>
				<template #sub-title>
					An informal service for loved ones to cherish what made you unique and held at a chosen location.
				</template>
			</Card>

			<Card
				:active='selectedTypeOfCelebration === Service.None'
				@click='selectedTypeOfCelebration = Service.None'
			>
				<template #icon>
					<FlowersIcon :active='selectedTypeOfCelebration === Service.None' />
				</template>
				<template #title>
					No service
				</template>
				<template #sub-title>
					A choice if it is preferred that no one gathers.
				</template>
			</Card>

			<!-- TEMPORARY FIX TO HIDE LET LOVED ONES DECIDE FOR LARGER BREAKPOINTS SO THE NEXT BUTTON DOESN'T OVERVFLOW
			WE NEED TO ADD ANOTHER CARD SIZING OPTION TO BE FLUID WHEN THERE ARE LONG ROWS OR REVISIT DESIGN -->
			<Card
				:active='selectedTypeOfCelebration === Service.Undecided'
				@click='selectedTypeOfCelebration = Service.Undecided'
				class="lg:hidden [@media(min-width:1700px)]:block"
			>
				<template #icon>
					<FamilyIcon :active='selectedTypeOfCelebration === Service.Undecided' />
				</template>
				<template #title>
					Let loved ones decide
				</template>
				<template #sub-title>
					Leave it to your loved ones to create the right remembrance.
				</template>
			</Card>
		</Cards>
	</FlowScreen>
</template>

<script lang='ts'>

import { Actions, Errors } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'
import { Service } from '../../../constants'

import BalloonsIcon from 'components/icons/BalloonsIcon.vue'
import CandleIcon from 'components/icons/CandleIcon.vue'
import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FamilyIcon from 'components/icons/FamilyIcon.vue'
import FlowersIcon from 'components/icons/FlowersIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'PreNeedService',
	components:
	{
		BalloonsIcon,
		CandleIcon,
		Card,
		Cards,
		FamilyIcon,
		FlowersIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/**
			* This page/screen name
			*/
			screenName: 'preNeedService',

			/** enum for type of service */
			Service,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.selectedTypeOfCelebration === Service.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['selectedTypeOfCelebration'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			if ([Service.Traditional, Service.Celebration].includes(this.selectedTypeOfCelebration))
			{
				this.$emit('navigate', "preNeedServiceAffirm")
			}
			else if (this.selectedTypeOfCelebration === Service.None)
			{
				this.$emit('navigate', "preNeedNoServiceAffirm")
			}
			else
			{
				this.$emit('navigate', "preNeedFamilyDecideServiceAffirm")
			}
		},
	}
}

</script>
