<template>
	<div>
		<CheckableButton
			class='h-full sm:h-28'
			:class='{recommended}'
			:model-value='modelValue'
			@update:model-value="(value) => $emit('update:modelValue', value)"
			toggle
			>
			<div class='flex flex-row text-left justify-between'>
				<div class='flex-initial'>
					<div class='accent font-serif text-lg font-bold sm:text-xl'>{{ name }}</div>
					<ToolTip>
						<div class='hidden sm:block' >More details</div>
						<template #content>
							<slot></slot>
						</template>
					</ToolTip>
					<div v-if="recommended" class='accent font-sans text-xs text-p-gold-400 font-bold'>
						&bigstar; Recommended
					</div>
				</div>

				<div v-if="price" class='flex-initial text-right'>
					<!-- Single Installment -->
					<div v-if="singlePay">
						<div class='accent font-serif text-base font-bold sm-text:sm'>{{ formattedPrice }}</div>
						<div>Single installment</div>
					</div>

					<!-- Total price with monthly price -->
					<div v-else-if="monthlyPrice">
						<div><span class='accent font-serif text-lg font-bold sm-text:xl'>{{ formattedMonthlyPrice }}/</span><span class='accent font-serif text-xs'>mo</span></div>
						<div class='font-sans text-xs'>{{ formattedPrice }}</div>
					</div>

					<!-- Only a flat cost -->
					<div v-else>
						<div class='accent font-serif text-lg font-bold sm-text:xl'>{{ formattedPrice }}</div>
					</div>
				</div>
				<div v-else>
					Free consultation
				</div>
			</div>
		</CheckableButton>
		<div class="block sm:hidden">
			<ToastInfo :title="name">
				<template #trigger><div class="mr-2 text-sm text-right text-p-blue font-sans text-blue-600 underline mb-4">+ Details</div></template>
				<template #content><slot></slot></template>
			</ToastInfo>
		</div>
	</div>
</template>

<script lang="ts">
import ToolTip from 'components/ToolTip.vue'
import { defineComponent } from 'vue'
import CheckableButton from 'components/CheckableButton.vue'
import { formatAsCurrency } from '../../../stringUtils'
import ToastInfo from 'components/ToastInfo.vue'

export default defineComponent({
	name: "AddOnButton",
	components: {
		CheckableButton,
		ToastInfo,
		ToolTip,
	},
	emits: ['update:modelValue'],
	props: {
		recommended: Boolean,
		singlePay: Boolean,
		price: {
			type: Number,
			default: null,
		},
		monthlyPrice: {
			type: Number,
			default: null,
		},
		name: String,
		modelValue: Boolean,
	},
	computed: {
		formattedPrice() {
			return formatAsCurrency(this.price)
		},

		formattedMonthlyPrice() {
			return formatAsCurrency(this.monthlyPrice, true)
		},
	},
})

</script>

<style scoped>
	.accent {
		@apply text-p-blue-300;
	}

	.recommended .accent {
		@apply text-p-gold-400;
	}
</style>
