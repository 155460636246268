<template>
	<div ref='estimatePages'>
		<PreneedEstimatePage v-if='isPreneed' />
		<AtNeedEstimatePage v-else />
	</div>
</template>

<script lang='ts'>

/**
 * @returns {Promise} Promise that resolves after given ms delay
 */
function delay(ms) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

import { updateEstimate } from '../apiService'
import { Choice, Need, Service } from '../../../constants'
import AtNeedEstimatePage from './AtNeedEstimatePage.vue'
import PreneedEstimatePage from './PreneedEstimatePage.vue'
import { getEstimate } from '../apiService'
import { useEstimateStore } from '../store/estimate'
import { onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'

export default {
	name: 'EstimatePage',
	components:
	{
		AtNeedEstimatePage,
		PreneedEstimatePage,
	},
	props:
	{
		estimateId:
		{
			type: String,
			default: null,
		},
	},
	async setup(props) {
		let debounceTimeout = null
		const estimate = useEstimateStore()

		/**
		 * Apply business rules regarding option dependencies.
		 * Sends an estimate update request to the API after a debounce period
		 */
		function onStoreUpdate()
		{
			// Cannot have the body present if there is no service
			if (estimate.selectedTypeOfCelebration === Service.None)
			{
				estimate.selectedBodyPresent = Choice.No
			}

			clearTimeout(debounceTimeout)
			debounceTimeout = setTimeout(updateEstimate, 1000)
		}

		// Get the estimate from the API in no fewer than 750ms
		await Promise.all([getEstimate(props.estimateId), delay(750)])

		// Subscribe to store updates
		const unsubscribe = estimate.$subscribe(onStoreUpdate)

		// Unsubscribe when unmounting
		onBeforeUnmount(unsubscribe)

		return {
			isPreneed: estimate.selectedTypeOfNeed != Need.AtNeed
		}
	},
	// Once mounted, disable 'read-only' urls from being able to change the estimate
	mounted()
	{
		const route = useRoute()

		// Estimate urls sent internally will contain 'read-only=1' query
		if (route.query['read-only'])
		{
			// Find all elements that can change the estimate
			const buttons = this.$refs.estimatePages.getElementsByTagName('button');
			const inputs = this.$refs.estimatePages.getElementsByTagName('input');

			// Disable these elements
			Array.from(buttons).forEach(function(button) {
				button.disabled = true;
			});

			Array.from(inputs).forEach(function(input) {
				input.disabled = true;
			});
		}
	},
}

</script>
