<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel('atNeedService', 'title') }}
			</template>
		</QuestionSet>

		<Cards class='cards-spacer'>
			<Card
				:active='selectedTypeOfCelebration === Service.Traditional'
				@click='selectedTypeOfCelebration = Service.Traditional'
			>
				<template #icon>
					<CandleIcon :active='selectedTypeOfCelebration === Service.Traditional' />
				</template>
				<template #title>
					Traditional Service
				</template>
				<template #sub-title>
					A formal service for people to cherish what made your loved one unique. Typically held at a funeral home.
				</template>
			</Card>

			<Card
				:active='selectedTypeOfCelebration === Service.Celebration'
				@click='selectedTypeOfCelebration = Service.Celebration'
			>
				<template #icon>
					<BalloonsIcon :active='selectedTypeOfCelebration === Service.Celebration' />
				</template>
				<template #title>
					Celebration of Life
				</template>
				<template #sub-title>
					An informal service for people to cherish what made your loved one unique and held at a chosen location.
				</template>
			</Card>

			<Card
				:active='selectedTypeOfCelebration === Service.None'
				@click='selectedTypeOfCelebration = Service.None'
			>
				<template #icon>
					<FlowersIcon :active='selectedTypeOfCelebration === Service.None' />
				</template>
				<template #title>
					No service
				</template>
				<template #sub-title>
					A choice if it is preferred that no one gathers.
				</template>
			</Card>
		</Cards>
	</FlowScreen>
</template>

<script>

import { Actions, Errors, Main } from '../lang'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'
import { Service } from '../../../constants'

import BalloonsIcon from 'components/icons/BalloonsIcon.vue'
import CandleIcon from 'components/icons/CandleIcon.vue'
import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FlowersIcon from 'components/icons/FlowersIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedService',
	components:
	{
		BalloonsIcon,
		CandleIcon,
		Card,
		Cards,
		FlowersIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			Service,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedService',

			/**
			* Version level constants / string labels
			*/
			constants: Main,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.selectedTypeOfCelebration === Service.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['selectedTypeOfCelebration'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			if ([Service.Traditional, Service.Celebration].includes(this.selectedTypeOfCelebration))
			{
				this.$emit('navigate', "atNeedServiceAffirm")
			}
			else
			{
				this.$emit('navigate', "atNeedNoServiceAffirm")
			}
		},
	}
}

</script>
