<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
		</QuestionSet>

		<Cards class='cards-spacer'>
			<Card
				:active='selectedBodyPresent === Choice.Yes'
				@click='selectedBodyPresent = Choice.Yes'
			>
				<template #icon>
					<FlowerBoxIcon :active='selectedBodyPresent === Choice.Yes' />
				</template>
				<template #title>
					Yes
				</template>
				<template #sub-title>
					My loved ones will want to say  their final goodbyes.
				</template>
			</Card>
			<Card
				:active='selectedBodyPresent === Choice.No'
				@click='selectedBodyPresent = Choice.No'
			>
				<template #icon>
					<FlowersIcon :active='selectedBodyPresent === Choice.No' />
				</template>
				<template #title>
					No
				</template>
				<template #sub-title>
					It’s not for us.
				</template>
			</Card>
		</Cards>
	</FlowScreen>
</template>

<script lang='ts'>

import { Actions, Errors } from '../lang'
import { Choice } from '../../../constants'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FlowerBoxIcon from 'components/icons/FlowerBoxIcon.vue'
import FlowersIcon from 'components/icons/FlowersIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'AtNeedBodyPresent',
	components:
	{
		Card,
		Cards,
		FlowerBoxIcon,
		FlowersIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			Choice,

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,

			/**
			* This page/screen name
			*/
			screenName: 'atNeedBodyPresent',
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.selectedBodyPresent === Choice.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore, ['selectedBodyPresent'])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "atNeedBodyPresentAffirm")
		},
	}
}

</script>
