import { defineStore } from 'pinia'
import { DateTime } from 'luxon'
import { Choice, Disposition, Need, Service } from '../../../constants'

export const useEstimateStore = defineStore('estimate', {
	state: () => ({
		id: null,

		legacyFhId: <number> null,
		frontEndVersion: 0,
		url: '',

		entryEmailAddress: '',
		entryPhoneNumber: '',
		estateComfortLevel: 0,
		estimatedCost: 0,
		planningForSelf: true,
		informantFirstName: '',
		informantLastName: '',
		planFhId: null,
		planningForFirstName: '',
		planningForLastName: '',
		planningForBirthdate: null,
		requestedContact: false,
		requestedEstateAndGriefNewsletter: false,
		scheduledEstateMetting: '',
		scheduledGriefMeeting: '',
		selectedBodyPresent: Choice.Blank,
		selectedDisposition: Disposition.Blank,
		selectedDispositionBudget: 6431,
		selectedTypeOfCelebration: Service.Blank,
		selectedTypeOfNeed: Need.Blank,
		selectedUrnView: 'display',
		zipcode: '',

		optInEstateClosing: false,
		optInFinancialPlanning: false,
		optInGriefCounseling: false,

		purchaseDeathCertificate: false,
		purchaseFlowers: false,
		purchaseObituary: false,
		purchaseTravelProtection: false,

		selectedInsuranceTermYears: <number> null,

		// Received from API. Only show "contact an AFP" if true
		expectContact: false,
		visibleFh: false,
	}),

	getters: {

		getLeadBirthdate(state) {
			return state.planningForBirthdate?.toISODate()
		},

		isPreneed(state) {
			return state.selectedTypeOfNeed != Need.AtNeed
		},
	},

	actions: {
		applyApiPayload(payload) {
			const document = JSON.parse(payload.document)

			this.$reset()
			this.$patch({
				...document,

				frontEndVersion: payload.frontEndVersion,
				url: payload.url,
				id: payload.id,
				legacyFhId: payload.legacyFhId,
				entryPhoneNumber: payload.informantPhone,
				zipcode: payload.informantZipcode,
				entryEmailAddress: payload.informantEmail,
				selectedTypeOfNeed: payload.needType,

				informantFirstName: payload.informantFirstName,
				informantLastName: payload.informantLastName,
				planningForFirstName: payload.planningForFirstName,
				planningForLastName: payload.planningForLastName,
				planningForBirthdate: DateTime.fromISO(payload.planningForBirthdate),
				planFhId: payload.planFhId,

				optInEstateClosing: payload.optInEstateClosing,
				optInFinancialPlanning: payload.optInFinancialPlanning,
				optInGriefCounseling: payload.optInGriefCounseling,

				expectContact: payload.expectContact,
				visibleFh: payload.visibleFh,
			})
		},

		getApiPayload() {
			return {
				legacyFhId: this.legacyFhId,
				frontEndVersion: this.frontEndVersion,
				isLegacyBrand: import.meta.env.VITE_LEGACY_BRAND,

				informantFirstName: this.informantFirstName,
				informantLastName: this.informantLastName,
				planningForFirstName: this.planningForFirstName,
				planningForLastName: this.planningForLastName,
				planningForBirthdate: this.planningForBirthdate?.toISODate(),

				informantPhone: this.entryPhoneNumber.replace(/\D/g, '').slice(-10),
				informantZipcode: this.zipcode.replace(/\D/g, ''),
				informantEmail: this.entryEmailAddress,
				needType: this.selectedTypeOfNeed.toLowerCase(),

				optInEstateClosing: this.optInEstateClosing,
				optInFinancialPlanning: this.optInFinancialPlanning,
				optInGriefCounseling: this.optInGriefCounseling,

				document: JSON.stringify({
					estateComfortLevel: this.estateComfortLevel,
					estimatedCost: this.estimatedCost,
					planningForSelf: this.planningForSelf,
					requestedContact: this.requestedContact,
					requestedEstateAndGriefNewsletter: this.requestedEstateAndGriefNewsletter,
					scheduledEstateMetting: this.scheduledEstateMetting,
					scheduledGriefMeeting: this.scheduledGriefMeeting,
					selectedBodyPresent: this.selectedBodyPresent,
					selectedDisposition: this.selectedDisposition,
					selectedDispositionBudget: this.selectedDispositionBudget,
					selectedTypeOfCelebration: this.selectedTypeOfCelebration,
					selectedUrnView: this.selectedUrnView,
					selectedInsuranceTermYears: this.selectedInsuranceTermYears,
					purchaseDeathCertificate: this.purchaseDeathCertificate,
					purchaseFlowers: this.purchaseFlowers,
					purchaseObituary: this.purchaseObituary,
					purchaseTravelProtection: this.purchaseTravelProtection,
				})
			}
		},
	}
})
