import {format} from '../../stringUtils'
import { Disposition } from '../../constants'

const main =
{
	SYSTEM_NAME: 'Audrey',
	NEXT_PAGE: 'Next',
}

const DispositionProductNames = new Map()
DispositionProductNames.set(Disposition.Burial, 'casket')
DispositionProductNames.set(Disposition.Cremation, 'urn')
DispositionProductNames.set(Disposition.Undecided, 'casket or urn')
DispositionProductNames.set(Disposition.Blank, 'casket or urn')

const pages =
{
	"typeOfNeed":
	{
		"title": "Hello there! I’m {0}. What can I help you with today?",
		"subTitle": "Please select one."
	},
	"preNeedAffirm":
	{
		"title": "Great! I can help you preplan and find practical payment options for a funeral."
	},
	"preNeedWho":
	{
		"title": "Tell me more about who you are planning for.",
		"info": "We ask so we can find the most affordable monthly payment options based on age."
	},
	"preNeedWhoAffirm":
	{
		"title": "Nice to meet you, {0}! We’re happy to help you plan today."
	},
	"preNeedProduct":
	{
		"title": "Do you prefer burial or cremation?"
	},
	"preNeedZip":
	{
		"title": "Where is home for you?",
		"subTitle": "Your location will help us find the best rates and create the right budget suggestion."
	},
	"preNeedZipAffirm":
	{
		"title": "Great - we’re calculating the most accurate pricing for that area."
	},
	"preNeedProductBudget":
	{
		"title": "How much should we budget for the {0}?"
	},
	"preNeedProductBudgetAffirm":
	{
		"title": "Great choice! We’ll add that to your plan so you can put it towards the perfect {0} later."
	},
	"preNeedService":
	{
		"title": "How would you like your loved ones to honor you?"
	},
	"preNeedServiceAffirm":
	{
		"title": "Great choice! Your loved ones will appreciate the chance to gather, connect, and reflect."
	},
	"preNeedNoServiceAffirm":
	{
		"title": "No problem - a celebration won't be added to the estimate."
	},
	"preNeedFamilyDecideServiceAffirm":
	{
		"title": "Sounds good–we’ll add a small amount to your estimate, but your family will make the final choice later."
	},
	"preNeedBodyPresent":
	{
		"title": "Will you want the body present at the viewing or service?"
	},
	"preNeedBodyPresentAffirm":
	{
		"title": "Great selections–this sounds like it will be a very meaningful service!"
	},
	"preNeedNoBodyPresentAffirm":
	{
		"title": "Ok, that’s no problem. We know it’s not for everyone."
	},
	"preNeedFamilyDecideBodyAffirm":
	{
		"title": "Sounds good–we’ll add a small amount to your estimate, but your loved ones will make the final choice later."
	},
	"preNeedContactInformation":
	{
		"title": "Last question. What is your contact information?",
		"subTitle": "We'll email your complete plan and follow-up with the most accurate estimate."
	},
	"preNeedFinishAffirm":
	{
		"title": "Great news, {0}–we can help you cover your perfect service today!"
	},
	"finalExpenseEstimate":
	{
		"title": "Our Preplanning Recommendation",
		"subTitle": "We recommend a <span class='text-p-gold-400 font-semibold underline underline-offset-3'>{0} preneed insurance policy</span> to help cover the cost of this funeral for your loved ones"
	},
	"atNeedAffirm":
	{
		"title": "Our condolences. We’re here to help."
	},
	"atNeedWho":
	{
		"planningForLabel": "Who are you are planning for?",
		"entryNameLabel": "What is your name?"
	},
	"atNeedWhoAffirm":
	{
		"title": "Hi, {0}. We’ll help make this as easy as possible."
	},
	"atNeedProduct":
	{
		"title": "Was your loved one’s preference for burial or cremation?"
	},
	"atNeedZip":
	{
		"title": "Where will the funeral take place?",
		"subTitle": "The location will help us find rates so we can provide the best budget suggestion."
	},
	"atNeedZipAffirm":
	{
		"title": "Thank you - we’re calculating the most accurate pricing for that area."
	},
	"atNeedProductBudget":
	{
		"title": "How much should we budget for the {0}?"
	},
	"atNeedProductBudgetAffirm":
	{
		"title": "Ok - we will add that to the estimate."
	},
	"atNeedService":
	{
		"title": "How would your loved one want to be honored?"
	},
	"atNeedServiceAffirm":
	{
		"title": "{0}’s loved ones will appreciate the chance to gather, connect, and reflect."
	},
	"atNeedNoServiceAffirm":
	{
		"title": "No problem - a celebration won't be added to the plan."
	},
	"atNeedBodyPresent":
	{
		"title": "Will you want the body present at the viewing or service?"
	},
	"atNeedBodyPresentAffirm":
	{
		"title": "Good choice–this sounds like it will be a very meaningful service."
	},
	"atNeedContactInformation":
	{
		"title": "What is your contact information?",
		"subTitle": "This will save your plan & estimate."
	},
	"atNeedEstateClosing":
	{
		"title": "Rate your comfort with handling estate matters",
		"subTitle": "This includes filing social security death benefits, closing bank accounts, making life insurance claims, etc..."
	},
	"atNeedScheduleEstateClosing":
	{
		"title": "Great, let’s schedule a time to talk to an Everything After Estate Specialist"
	},
	"atNeedGrief":
	{
		"title": "Last question... would you or your loved ones be interested in 30 days of free grief coaching?"
	},
	"atNeedScheduleGrief":
	{
		"title": "Let’s schedule at time to meet your Everything After Grief Coach"
	},
	"atNeedServices":
	{
		"title": "Would you like assistance with any of the following?",
		"subTitle": "Select as many that apply.",
	},
	"atNeedNoServicesAffirm":
	{
		"title": "Not a problem. We can still offer recommendations based on your answers."
	},
	"atNeedServicesAffirm":
	{
		"title": "Great! An Everything After Specialist will be reaching out to help you shortly."
	},
	"atNeedEstimate":
	{
		"title": "Based on your location, we recommend contacting {0}",
		"subTitle": "Your preferred funeral would cost around <span class='text-p-gold-400 font-semibold underline underline-offset-3'>{0}</span> (based on regional averages)"
	},
	"estimateLoading":
	{
		"title": "Loading your estimate...",
	},
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const graph = {
	"typeOfNeed": {
		"preNeedWho": {
			"preNeedProduct": {
				"preNeedZip": {
					"preNeedProductBudget": {
						"preNeedService": {
							"preNeedBodyPresent": {
								"preNeedContactInformation": {
									"preNeedEstimate": {},
								}
							},
							"preNeedContactInformation": {
								"preNeedEstimate": {},
							}
						},
					}
				}
			}
		},
		"atNeedWho": {
			"atNeedProduct": {
				"atNeedZip": {
					"atNeedProductBudget": {
						"atNeedService": {
							"atNeedBodyPresent": {
								"atNeedContactInformation": {
									"atNeedServices": {
										"atNeedEstimate": {},
									}
								}
							},
							"atNeedContactInformation": {
								"atNeedServices": {
									"atNeedEstimate": {},
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
 * How many steps are left in the flow, including the named step?
 * Provide a value for each page that changes the number,
 * plus any choice page leading to that page (so back button works)
 */
const stepsRemaining = {
	"atNeedContactInformation": 2,
	"atNeedService": 4,
	"preNeedContactInformation": 1,
	"preNeedService": 3,
	"typeOfNeed": 9,
	"preNeedWho": 7,
}

const actions =
{
	getLabel: (screen, label, vars = []) =>
	{
		if (
			pages[screen] &&
			Object.prototype.hasOwnProperty.call(pages[screen], label)
		) {
			return format(pages[screen][label], vars)
		}
		return ''
	}
}

const errors = {
	requiredSelection: 'Please select an option.',
}


export {
	DispositionProductNames,
	errors as Errors,
	main as Main,
	pages as Pages,
	actions as Actions,
	stepsRemaining as StepsRemaining,
}
