import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./index.css";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
const pinia = createPinia();

Sentry.init({
  app,
  environment: import.meta.env.VITE_ENVIRONMENT,
  dsn: import.meta.env.VITE_SENTRY_DSN,
});

app.use(router);
app.use(pinia);

app.mount("#app");

// Catch loading from BFCache and reset app
window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    console.log("Loaded page from BFCache; reloading");
    location.reload();
  }
});
