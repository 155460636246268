<template>
	<FlowScreen
		:errors='errors'
		@back='navigateBack'
		@submit='navigateNext'
	>
		<QuestionSet>
			<template #question>
				{{ labelActions.getLabel(screenName, 'title') }}
			</template>
		</QuestionSet>

		<div class='cards-spacer'>
			<Cards>
				<Card
					:active='selectedDisposition === Disposition.Burial'
					@click='selectDisposition(Disposition.Burial)'
				>
					<template #icon>
						<FlowerBoxIcon
							:active='selectedDisposition === Disposition.Burial'
						/>
					</template>
					<template #title>
						Burial
					</template>
				</Card>

				<Card
					:active='selectedDisposition === Disposition.Cremation'
					@click='selectDisposition(Disposition.Cremation)'
				>
					<template #icon>
						<FlowerVaseIcon
							:active='selectedDisposition === Disposition.Cremation'
						/>
					</template>
					<template #title>
						Cremation
					</template>
				</Card>

				<Card
					:active='selectedDisposition === Disposition.Undecided'
					@click='selectDisposition(Disposition.Undecided)'
				>
					<template #icon>
						<FamilyIcon
							:active='selectedDisposition === Disposition.Undecided'
						/>
					</template>
					<template #title>
						Let my loved ones decide
					</template>
				</Card>
			</Cards>
		</div>
	</FlowScreen>
</template>

<script>

import { Actions, Errors } from '../lang'
import { Disposition } from '../../../constants'
import { useEstimateStore } from '../store/estimate'
import { mapWritableState } from 'pinia'

import Card from 'components/Card.vue'
import Cards from 'components/Cards.vue'
import FamilyIcon from 'components/icons/FamilyIcon.vue'
import FlowerBoxIcon from 'components/icons/FlowerBoxIcon.vue'
import FlowerVaseIcon from 'components/icons/FlowerVaseIcon.vue'
import FlowScreen from '../FlowScreen.vue'
import QuestionSet from 'components/QuestionSet.vue'

export default {
	name: 'PreNeedProduct',
	components:
	{
		Card,
		Cards,
		FamilyIcon,
		FlowerBoxIcon,
		FlowerVaseIcon,
		FlowScreen,
		QuestionSet,
	},
	emits: [
		"back",
		"navigate",
	],
	data() {
		return {
			/** Enum for disposition options */
			Disposition,

			/**
			* This page/screen name
			*/
			screenName: 'preNeedProduct',

			/**
			 * Methods regarding labels and language
			 */
			labelActions: Actions,
		}
	},
	computed:
	{
		errors()
		{
			let errors = []

			if (this.selectedDisposition === Disposition.Blank)
			{
				errors.push(Errors.requiredSelection)
			}

			return errors
		},
		...mapWritableState(useEstimateStore,
			[
				'selectedDisposition',
				'selectedDispositionBudget',
			])
	},
	methods:
	{
		navigateBack()
		{
			this.$emit('back')
		},
		navigateNext()
		{
			this.$emit('navigate', "preNeedZip")
		},
		selectDisposition(disposition)
		{
			this.selectedDisposition = disposition
			switch (disposition)
			{
				case Disposition.Burial:
					gtag('event', 'burial_disposition_selected')
					return this.selectedDispositionBudget = 6431
				case Disposition.Cremation:
					gtag('event', 'cremation_disposition_selected')
					return this.selectedDispositionBudget = 395
				default:
					gtag('event', 'undecided_disposition_selected')
					return this.selectedDispositionBudget = 6431
			}
		}
	}
}

</script>
