Button to indicate next page navigation
<template>
	<button
		class='
			btn-next-btn transition-all btn-gold w-64 lg:w-64 p-4 lg:p-5 rounded
			group-hover:-translate-y-2 group-hover:shadow-xl group-hover:shadow-p-gold'
		:class='getClasses'
		type='submit'
	>
		<div class='relative w-full'>
			<span class='text-xl lg:text-2xl uppercase'>
				NEXT
			</span>

			<div
				class='
						btn-tooltip
						absolute
						left-1/2
						w-full
						p-3
						text-gray-500
						transform
						-translate-x-1/2
						-translate-y-28
						bg-slate-50
						rounded
						drop-shadow
						hidden
					'
			>
				<div>
					<div class='relative flex text-warn items-center justify-center w-full h-full'>
						{{ alert }}
						<!-- alert -->
						<div
							class='
									absolute after bottom-0 w-4 h-4 transform rotate-45 translate-y-5 bg-slate-50'
						></div>
					</div>
				</div>
			</div>
		</div>
	</button>
</template>

<script>

export default {
	name: 'NextButton',
	props:
	{
		enabled:
		{
			default: false,
			required: false,
			type: Boolean,
		},
		alert:
		{
			default: "",
			required: false,
			type: String,
		}
	},
	computed:
	{
		getClasses()
		{
			return {
				'btn-error': this.alert,
				'disabled': !this.enabled,
				'enabled': this.enabled,
			}
		},
	},
}

</script>

<style scoped lang="less">
	.btn-gold.disabled {
		@apply bg-p-gold-disabled text-white group-hover:translate-y-0 group-hover:shadow-none;
	}

	.btn-gold.enabled {
		@apply bg-p-gold-400 transform text-white font-bold;
	}
	.btn-error .btn-tooltip {
		@apply block;
	}

</style>
