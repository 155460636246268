An svg of a lit candle
<template>
	<svg
		class='candle-icon svg-flex-sizing'
		fill='none'
		preserveAspectRatio='xMidYMid meet'
		viewBox='0 0 30 87'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			:class='getCandleClasses'
			d='M1.08398 23.5625H28.9153V85.9125H1.08398V23.5625Z'
		/>
		<path
			d='M28.9157 86.9996H1.08434C0.796753 86.9996 0.520948 86.885 0.317595 86.6811C0.114242 86.4771 0 86.2005 0 85.9121L0 23.5621C0 23.2737 0.114242 22.9971 0.317595 22.7931C0.520948 22.5892 0.796753 22.4746 1.08434 22.4746H28.9157C29.2032 22.4746 29.4791 22.5892 29.6824 22.7931C29.8858 22.9971 30 23.2737 30 23.5621V85.9121C30 86.2005 29.8858 86.4771 29.6824 86.6811C29.4791 86.885 29.2032 86.9996 28.9157 86.9996ZM2.16867 84.8246H27.8313V24.6496H2.16867V84.8246Z'
			fill='#222433'
		/>
		<path
			d='M6.3253 50.7494C2.83771 50.7494 0 48.3102 0 45.3119C0 45.0235 0.114242 44.7469 0.317595 44.5429C0.520948 44.339 0.796753 44.2244 1.08434 44.2244C1.37192 44.2244 1.64773 44.339 1.85108 44.5429C2.05443 44.7469 2.16867 45.0235 2.16867 45.3119C2.16867 47.1106 4.03337 48.5744 6.3253 48.5744C8.61723 48.5744 10.4819 47.1106 10.4819 45.3119V40.2369C10.4819 39.9485 10.5962 39.6719 10.7995 39.4679C11.0029 39.264 11.2787 39.1494 11.5663 39.1494C11.8539 39.1494 12.1297 39.264 12.333 39.4679C12.5364 39.6719 12.6506 39.9485 12.6506 40.2369V45.3119C12.6506 48.3102 9.81325 50.7494 6.3253 50.7494Z'
			fill='#222433'
		/>
		<path
			d='M15.9041 46.3996C14.4667 46.398 13.0886 45.8246 12.0722 44.8052C11.0558 43.7858 10.484 42.4037 10.4824 40.9621C10.4824 40.6737 10.5967 40.3971 10.8 40.1931C11.0034 39.9892 11.2792 39.8746 11.5668 39.8746C11.8543 39.8746 12.1301 39.9892 12.3335 40.1931C12.5369 40.3971 12.6511 40.6737 12.6511 40.9621C12.6511 41.8274 12.9938 42.6572 13.6039 43.269C14.2139 43.8809 15.0414 44.2246 15.9041 44.2246C16.7669 44.2246 17.5943 43.8809 18.2043 43.269C18.8144 42.6572 19.1571 41.8274 19.1571 40.9621V38.0621C19.1571 37.7737 19.2714 37.4971 19.4747 37.2931C19.6781 37.0892 19.9539 36.9746 20.2415 36.9746C20.529 36.9746 20.8048 37.0892 21.0082 37.2931C21.2116 37.4971 21.3258 37.7737 21.3258 38.0621V40.9621C21.3242 42.4037 20.7524 43.7858 19.736 44.8052C18.7196 45.8246 17.3415 46.398 15.9041 46.3996Z'
			fill='#222433'
		/>
		<path
			d='M24.5779 61.9875C23.1405 61.9859 21.7624 61.4125 20.746 60.3931C19.7296 59.3737 19.1579 57.9916 19.1562 56.55V40.9625C19.1562 40.6741 19.2705 40.3975 19.4738 40.1935C19.6772 39.9896 19.953 39.875 20.2406 39.875C20.5282 39.875 20.804 39.9896 21.0073 40.1935C21.2107 40.3975 21.3249 40.6741 21.3249 40.9625V56.55C21.3249 57.4153 21.6677 58.2451 22.2777 58.8569C22.8878 59.4688 23.7152 59.8125 24.5779 59.8125C25.4407 59.8125 26.2681 59.4688 26.8782 58.8569C27.4882 58.2451 27.8309 57.4153 27.8309 56.55V40.9625C27.8309 40.6741 27.9452 40.3975 28.1485 40.1935C28.3519 39.9896 28.6277 39.875 28.9153 39.875C29.2029 39.875 29.4787 39.9896 29.682 40.1935C29.8854 40.3975 29.9996 40.6741 29.9996 40.9625V56.55C29.998 57.9916 29.4263 59.3737 28.4099 60.3931C27.3934 61.4125 26.0154 61.9859 24.5779 61.9875Z'
			fill='#222433'
		/>
		<path
			:class='getFlameClasses'
			d='M15.8357 19.8424C11.7582 19.8799 8.66241 16.0612 7.90733 12.7291C6.81247 8.04929 9.94605 2.50838 15.8357 0C21.1967 2.02168 24.4058 6.96358 23.764 11.606C23.2732 15.3498 20.2151 19.805 15.8357 19.8424Z'
		/>
		<path
			d='M15.8018 17.4625C13.9464 17.5007 12.5639 15.6283 12.2001 14.0233C11.6908 11.7687 13.1096 9.09379 15.8018 7.90918C18.2393 8.90273 19.6945 11.272 19.4035 13.4883C19.1852 15.3226 17.8027 17.4625 15.8018 17.4625Z'
			fill='white'
		/>
	</svg>
</template>

<script>

export default {
	name: 'CandleIcon',
	props:
	{
		active:
		{
			default: false,
			required: false,
			type: Boolean,
		}
	},
	computed:
	{
		getCandleClasses()
		{
			return {
				'candle-active': this.active,
				'candle-inactive': !this.active,
			}
		},
		getFlameClasses()
		{
			return {
				'flame-active': this.active,
				'flame-inactive': !this.active,
			}
		},
	},
}

</script>

<style scoped lang="less">
	.candle-icon{
		.flame-active {
			fill: #82B5A5;
		}
		.flame-inactive {
			fill: #CCD0D2;
		}
		.candle-active {
			fill: #82B5A5;
		}
		.candle-inactive {
			fill: #FFFFFF;
		}
	}
</style>
