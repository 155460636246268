Semi-presentational component to display an optional add-on that the user can select on the Estimate page.
Connects to the versioned calculator in order to compute monthly premiums. This dependency could be
removed by passing in the monthly premium factor.

<template>
	<CheckableButton
		v-slot='{ active }'
		:model-value='modelValue'
		:value='years'
		@update:model-value='onValueUpdated'
	>
		<!-- Payment Amount -->
		<h3 v-if="multipay" class='text-4xl font-bold text-p-blue-300 font-serif'>
			{{ formatAsCurrency(calc.monthlyPayment(years), true) }}<span class="text-2xl">/</span><span class='text-sm'>mo</span>
		</h3>
		<h3 v-else class='text-4xl font-bold text-p-blue-300 font-serif'>
			{{ formatAsCurrency(calc.singlePayPayment) }}
		</h3>

		<!-- Number of Installments -->
		<h6 class='pb-8 text-sm text-slate-600 mt-1'>
			<span v-if="multipay">{{ years * 12 }} installments </span>
			<span v-else>Single installment </span>
			<span class="text-p-gold">({{ formatAsCurrency(calc.policyAmount) }} of coverage)</span>
		</h6>

		<!-- You could save -->
		<div
			v-if="multipay && savings > 0"
			class='save-bar absolute left-0 right-0 bottom-0 text-xs p-2 rounded-br-lg rounded-bl-lg bg-slate-200 border-b-2 border-r-2 border-l-2 text-p-blue font-bold'
			:class='{"save-bar--active": active}'
		>
			You could save
			{{ formatAsCurrency(savings, true) }}/year today!
		</div>
		<div v-else
			class='save-bar single-pay-bar absolute left-0 right-0 bottom-0 text-xs p-2 rounded-br-lg rounded-bl-lg bg-slate-200 border-b-2 border-r-2 border-l-2 text-p-blue font-bold'
			:class='{"save-bar--active": active}'
		></div>

	</CheckableButton>
</template>

<script setup lang="ts">
import CheckableButton from 'components//CheckableButton.vue'
import { useCalculatorStore } from '../store/calculator'
import { formatAsCurrency } from '../../../stringUtils'
import { computed } from 'vue'

const props = defineProps({
	years: {
		type: Number,
		required: true,
	},
	modelValue: {
		type: Number,
		required: true,
	}
})

const emit = defineEmits(['update:modelValue'])

const calc = useCalculatorStore()

const onValueUpdated = (value) => emit('update:modelValue', value)

const multipay = computed(() => props.years > 0)

const savings = computed(() => calc.savingsOverNextBracketPerYear(props.years))

</script>


<style scoped lang="css">
	.active {
		/* @apply border-blue-400 ; */
	}

	.save-bar--active {
		@apply bg-blue-100 border-blue-400 font-bold;
	}

	.single-pay-bar {
		min-height: 34px;
	}
</style>
