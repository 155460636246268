import axios from 'axios'
import { useCalculatorStore } from './store/calculator'
import { useEstimateStore } from './store/estimate'
import { useFhStore } from './store/funeralHome'

const instance = axios.create()
const baseUrl: string = import.meta.env.VITE_API_ROOT

/**
 * Limit the length of a string, or return null if value is not provided
 */
function limit(value: string|null|undefined, length: number): string|null {
	if (!value) {
		return null
	}

	return value.substring(0, length)
}

/**
 * Create a new estimate in the database
 */
export async function createEstimate(queryParams) {
	const estimateStore = useEstimateStore()

	// Send URL params and the estimate to the back-end
	// We don't want to send these on update
	const payload = {
		utmSource: limit(queryParams.utm_source, 100),
		utmMedium: limit(queryParams.utm_medium, 20),
		utmContent: limit(queryParams.utm_content, 20),
		utmCampaign: limit(queryParams.utm_campaign, 20),
		referralUrl: limit(queryParams.referral_url, 1000),

		...estimateStore.getApiPayload()
	}
	const result = await instance.post(baseUrl + '/api/v1/estimates', payload)
	estimateStore.applyApiPayload(result.data)
}

/**
 * Update an existing estimate in the database
 */
export async function updateEstimate() {
	const estimateStore = useEstimateStore()
	const payload = estimateStore.getApiPayload()
	await instance.put(baseUrl + '/api/v1/estimates/' + encodeURIComponent(estimateStore.id), payload)
}

/**
 * Fetch estimate in the database and load it into the store
 */
export async function getEstimate(id: string) {
	const estimateStore = useEstimateStore()

	const result = await instance.get(baseUrl + '/api/v1/estimates/' + encodeURIComponent(id),
		{params: {
			includeFh: 1,
			includeProduct: 1,
		}})

	estimateStore.applyApiPayload(result.data.estimate)
	useFhStore().applyApiPayload(result.data.funeralHome)
	useCalculatorStore().applyApiPayload(result.data.product)
}
