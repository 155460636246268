Container element to organize a group of card elements
<template>
	<div class='cards flex flex-wrap justify-center lg:space-x-8 space-y-3 lg:space-y-0 p-4'>
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'Cards',
	props:
	{
	},
	methods:
	{
	},
}

</script>

<style scoped lang="less">
</style>
