<template>
	<main>
		<!-- NOTE: THERE ARE CURRENTLY 2 NAV LOGOS AND 2 TS&CS ELEMENTS. ANY CHANGES NEED TO BE MADE FOR AND CHECKED ON MOBILE AND DESKTOP SIZED SCREENS -->
		<!-- NEEDS REFACTOR TO ELIMINATE DOUBLE IMAGE AND PRIVACY -->
		<nav class='flex flex-col flex-col-reverse  p-0 sm:flex-row sm:text-left sm:justify-between sm:py-8 sm:px-12 bg-white shadow sm:items-center w-full'>
			<img
				v-if='legacy'
				class='hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
				src='@/assets/legacy_logo.png'
			/>
			<img
				v-else
				class='hidden sm:block mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[50px] lg:w-[162px]'
				src='@/assets/preplan_today_logo.svg'
			/>

			<div class="sm:hidden flex ml-6 mr-6 mt-2 items-center justify-between">
				<img
					v-if='legacy'
					class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
					src='@/assets/legacy_logo.png'
				/>
				<img
					v-else
					class='mt-4 mb-4 sm:mt-0 sm:mb-0 w-[100px] lg:h-[30px] lg:w-[162px]'
					src='@/assets/preplan_today_logo.svg'
				/>

				<div class=" whitespace-nowrap text-[10px] text-right  sm:block w-[100px] text-slate-500"><a href="/terms" target="_blank">Terms</a> | <a href="/privacy" target="_blank">Privacy</a></div>
			</div>


			<div class="whitespace-nowrap text-[10px] text-right sm:text-xs hidden sm:block w-[100px] lg:h-[30px] lg:w-[162px] text-slate-500 lg:pt-2"><a href="/terms" target="_blank">Terms</a> | <a href="/privacy" target="_blank">Privacy</a></div>
		</nav>

		<Helper class="pt-2 pb-4" />

		<div v-if="isError">
			<p class="m-8">Sorry, we have encountered an error while loading your estimate.</p>

			<button class="button" type="button" @click="reload">Retry</button>
			<button class="button" type="button" @click="startOver">Start New Estimate</button>
		</div>
		<Suspense v-else>
			<component
				:is='version'
				:estimate-id='estimateId'
			/>
			<template #fallback>
				<AffirmPage
					name='estimateLoading'
				/>
			</template>
		</Suspense>
	</main>
</template>

<script setup>

import Version1 from '@/versions/1/estimatePage/EstimatePage.vue'
import AffirmPage from '@/versions/1/AffirmPage.vue';
import Helper from 'components/Helper.vue'

// import Version2 from '@/versions/2/EstimatePage.vue'

import { onErrorCaptured, ref } from 'vue'

const isError = ref(false)

onErrorCaptured((err, instance, info) => {
	isError.value = true
})

</script>

<script>

export default
{
	name: 'EstimateView',
	components:
		{
			Version1,
			AffirmPage,
			// Version2
		},
	props: {
		estimateId: {
			type: String,
			default: null,
		}
	},
	data()
	{
		return {
			// false if default logo, true if Legacy logo
			legacy: !!Number(import.meta.env.VITE_LEGACY_BRAND),
			version: 'Version1',
		}
	},
	methods:
	{
		reload() {
			window.location.reload()
		},
		startOver() {
			this.$router.push({name: 'root'})
		},
	}
}
</script>

<style scoped>
	.button {
		@apply
			block text-center
			m-8
			bg-p-green-400 pt-4 pb-4 pl-8 pr-8 rounded-xl
			text-white text-xl font-sans font-bold uppercase tracking-wide;
	}
</style>
